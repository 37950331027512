// adding restricted characters

const rule = {
	name: 'validRoomName',
	method: (value, element, params) => {

		const match = !/[*]/.test(value);
		return match;

	},
	errorMessage: (params, element) => 'Room name cannot contain *'
};

export default rule;
