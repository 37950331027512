import { formatCurrency } from 'utilities/localization';
import { addSaleAttributesToProducts } from 'features/certona/utilities';

function getRelatedItemContent (result) {

	const baseUrl = window.lp.globals.secureSiteURL;

	return result.Products.slice(0, 10)
		.map(addSaleAttributesToProducts)
		.map((p) => {

			window.resx.prodDetailCertonaSkusUsed.push(`${p.ShortSku}|${result.Scheme}`);

			let ratingStr = '';
			const ratings = (Math.round((p?.CertonaProductModel?.TurnToAverageRate + 0.25) * 100.0) / 100.0).toString();
			if (ratings > 2.5) {

				const decimal = parseInt(ratings.substring(2, 3), 10);
				ratingStr = `${ratings.substring(0, 1)}-${decimal >= 5 ? '5' : '0'}`;

			}

			return `
			<div class="pdRelItmsProd pdRelatedItems pdRelItemHover" data-qa-sku-source="${p.ShortSku}">
				<a href="${baseUrl}${p.Url}" data-certonaSku="${p.ShortSku}">
					<img data-sku="${p.ShortSku}"
					data-sku-input-type="${p.SkuInputType}"
					src="${p.ImageBaseUrl}?qlt=55&amp;wid=270&amp;hei=270&amp;op_sharpen=1&amp;fmt=jpeg"
					alt="${p.ProductName}" />
					<div class="productTitle">
						<span>${p.ProductName}</span>
					</div>
					<div class="pdRelItemWidth pdRelItemZero">
						<span class="productPrice ${p.saleClassName}">${formatCurrency(p.FormattedPrice)}${p.clearanceCalloutText}${
	p.saleCalloutText
}</span>
					</div>
					<div class="${ratingStr < 2.5 ? 'hideVisibility' : ''}">
						<div class="productSummaryRating">
							<div class="goldReviewStars goldReviewStars--${ratingStr}">
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
							</div>	
						</div>
					</div>
				</a>	
			</div>
		`;

		})
		.join('');

}

export default getRelatedItemContent;
