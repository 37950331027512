import queueUtagCall from './queueUtagCall';

function utagViewArtificialPageGeneric ({ pageViewData = {}, tagIdFilter = [] }) {

	queueUtagCall(() => {

		window.top.utag.link(pageViewData, () => {}, tagIdFilter);

	});

}

export default utagViewArtificialPageGeneric;
