import { decodeUrlSearchTerm } from 'features/sort';

import addRecentSearch from './addRecentSearch';
import { unvalidatedQueryLocalStorageName, persistentSearchTypes } from './config';
import setPersistentSearch from './setPersistentSearch';

function capturePreviousSearch () {

	const { isOpenBoxSort, isOnSaleSearch } = window.lp.pageData;
	const url = document.location.href;
	const searchPath = document.location.pathname.split('products/s_')[1];
	const searchTerm = searchPath && decodeUrlSearchTerm(searchPath.split('/')[0]);
	const unvalidatedQuery = localStorage.getItem(unvalidatedQueryLocalStorageName);
	const decodedSearchTerm = searchTerm ? decodeURIComponent(searchTerm) : searchTerm;
	const isOnSearchSort = searchTerm && document.querySelector('.sortResultContainer') != null;
	const isOnPdpAfterSearch =		!searchTerm
		&& unvalidatedQuery != null
		&& unvalidatedQuery !== ''
		&& document.querySelector('#pdProdSku') != null;

	// if on search sort with results (either by url visit or search)
	// OR on pdp after search, save results
	if (isOnSearchSort && !isOpenBoxSort && !isOnSaleSearch) {

		addRecentSearch(decodedSearchTerm);
		setPersistentSearch(decodedSearchTerm, persistentSearchTypes.sort);

	} else if (isOnPdpAfterSearch) {

		addRecentSearch(unvalidatedQuery);
		setPersistentSearch(unvalidatedQuery, persistentSearchTypes.pdp, url);

	}

	localStorage.removeItem(unvalidatedQueryLocalStorageName);

}

export default capturePreviousSearch;
