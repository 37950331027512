function addSaleAttributesToProducts (product) {

	const {
		IsDecrementable, IsClearance, IsOnSale, SalePriceSavings
	} = product.CertonaProductModel.SearchCallout;

	const saleClassName = IsDecrementable || IsClearance || IsOnSale ? 'salePrice' : '';
	const clearanceCalloutText = IsClearance ? 'Clearance' : '';
	const saleCalloutText = IsOnSale ? 'Sale' : '';

	return {
		...product,
		saleClassName,
		clearanceCalloutText,
		saleCalloutText,
		salePriceSavings: SalePriceSavings
	};

}

export default addSaleAttributesToProducts;
