import { formatCurrency } from 'utilities/localization';

import { addSaleAttributesToProducts } from '../utilities';

function getRecentlyViewedHomePageContent (result, settings) {

	const {
		imageSize, showTitle, withPrice, maxItemsToDisplay
	} = settings;
	const products = result.Products.map(addSaleAttributesToProducts);
	const maxItems = typeof maxItemsToDisplay === 'number' ? maxItemsToDisplay : Math.min(products.length, 3);
	let productItemsHtml = '';

	for (let i = 0; i < maxItems; i += 1) {

		const product = products[i];
		const {
			Url,
			ShortSku,
			ImageBaseUrl,
			SkuInputType,
			ProductName,
			FormattedPrice,
			salePriceSavings,
			saleClassName,
			clearanceCalloutText,
			saleCalloutText
		} = product;
		const saveAmount =			salePriceSavings > 0 ? ` <span class="saveAmount">Save ${formatCurrency(salePriceSavings)}</span>` : '';

		productItemsHtml += `<div class="certonaWidgetContainer__item">
			<a href="${Url}" class="productLink" data-certonaSku="${ShortSku}">
				<img
					src="${ImageBaseUrl}?qlt=55&amp;wid=${imageSize}&amp;hei=${imageSize}&amp;op_sharpen=1&amp;fmt=jpeg"
					data-sku="${ShortSku}" data-sku-input-type="${SkuInputType}"
					class="productImage" width="${imageSize}" height="${imageSize}" alt="${ProductName}" />
			${showTitle ? `<div class="productName">${ProductName}</div>` : ''}
			${
	withPrice
		? `<div class="productPrice">
				<span class="${saleClassName}">
					${formatCurrency(FormattedPrice)}
					${clearanceCalloutText}${saleCalloutText}
				</span>
				${saveAmount}
		   </div>`
		: ''
}
			</a>
		</div>`;

	}

	return `<div class="certonaWidgetContainer__header">
			<h2 class="certonaWidgetContainer__headerTitle">Recently Viewed</h2>
			${
	maxItems > 1
		? `<span class="certonaWidgetContainer__delimiter">|</span>
				<a href="/recently-viewed/" id="viewAllRecentlyViewedBtn" class="certonaWidgetContainer__viewAllBtn"
					rel="nofollow">View All</a>`
		: ''
}
		</div>
		<div class="certonaWidgetContainer__list ">${productItemsHtml}</div>`;

}

export default getRecentlyViewedHomePageContent;
