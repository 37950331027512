/*
Detects ability to use sessionStorage
*/
function supportsSessionStorage () {

	return 'sessionStorage' in window;

}

function deleteSessionStorage (keyName: string) {

	if (supportsSessionStorage()) {

		sessionStorage.removeItem(keyName);

	}

}

function addSessionStorage (sessionStorageObject: Record<string, string>) {

	if (supportsSessionStorage() && typeof sessionStorageObject === 'object') {

		Object.keys(sessionStorageObject).forEach((key) => sessionStorage.setItem(key, sessionStorageObject[key]));

	}

}

export { supportsSessionStorage, deleteSessionStorage, addSessionStorage };
