import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createInjectorsEnhancer } from 'redux-injectors';

import * as reducers from './reducers';
import tracking from './middleware/tracking';

const middleware = [ ...getDefaultMiddleware(), tracking ];

function createReducer (injectedReducers = {}) {

	const rootReducer = combineReducers({
		...injectedReducers,
		...reducers
	});

	return rootReducer;

}

const store = configureStore({
	middleware,
	reducer: createReducer(),
	enhancers: [
		createInjectorsEnhancer({
			createReducer,
			runSaga: () => {}
		})
	]
});

export default store;
