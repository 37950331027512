function addClassDuringActivity (element: Element, classNameSSV: string, promise: Promise<any>) {

	// Convert space separated classNameSSV to an Array
	const classNames = classNameSSV.split(' ');

	function removeClassName () {

		// Cannot use spread operator due to IE not supporting multiple class names in classList.remove in one statement
		classNames.forEach((className) => element.classList.remove(className));

	}

	// Cannot use spread operator due to IE not supporting multiple class names in classList.add in one statement
	classNames.forEach((className) => element.classList.add(className));

	// Remove classNames when the promise is completed
	promise.then(removeClassName, removeClassName);

}

export default addClassDuringActivity;
