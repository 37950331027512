function hideRelatedItemsTab (checkColorCustomizer = false) {

	const suggestedProductsContainer = document.querySelectorAll('.suggestedProductsContainer');

	if (suggestedProductsContainer !== null) {

		suggestedProductsContainer.forEach((element) => {

			element.classList.add('hidden');

		});

	}

	// if Related Items tab is empty and Color Customizer is available,
	// then wait for the tab to be populated by Color Customizer
	// desktop only
	if (!checkColorCustomizer || !window.lp.pdp.showColorCustomizer) {

		const dataTabLinks = document.querySelectorAll('[data-tabLink]');

		dataTabLinks.forEach((el) => {

			const linkName = el.getAttribute('data-tabLink');
			const tabContentContainer = document.querySelector(`[data-tabContentContainer="${linkName}"]`);
			const tabContent = document.querySelectorAll(`[data-tabContent="${linkName}"] a`);

			if (!tabContent.length) {

				tabContentContainer.classList.add('hidden');
				el.classList.add('hidden');

			}

		});

	}

}

export default hideRelatedItemsTab;
