const schemes = {
	GENERIC_HORIZONTAL: 'generichorizontal',
	NO_SEARCH: 'nosearch',
	NO_SEARCH_SKU: 'nosearchsku',
	HOME: 'home',
	SIMILAR: 'similar',
	RECENTLY_VIEWED_ITEMS: 'recentlyvieweditems',
	RECENTLY_VIEWED_PAGE: 'gf_full',
	PRODUCT: 'product',
	CART: 'cart',
	ORDER_CONFIRMATION: 'orderconfirmation',
	RELATED: 'related',
	SIMILAR_2: 'similar2',
	MORE_LIKE_THIS: 'glo_mlt_sort',
	CATEGORY_LANDING: 'categorylanding',
	ORDER_STATUS: 'orderstatus',
	BRAND_PAGE: 'brandpage',
	SORT_PAGE: 'sortpage',
	WISHLIST: 'wishlist',
	STYLE: 'style',
	TYPE: 'type',
	GF_CATEGORY_LANDING: 'gf_categorylanding',
	GF_HOME: 'gf_homepage',
	GF_NO_SEARCH: 'gf_nosearch',
	GF_SORT_PAGE: 'gf_sortpage',
	GF_DEFAULT: 'gf_default',
	GF_PRODUCT: 'gf_product',
	GF_SEARCH: 'gf_search',
	DAILY_SALES: 'dailysales',
	SHIPPING: 'shipping',
	LAST_CATEGORY: 'lastcategory',
	LAST_CATEGORY_PAGE: 'lastcat_full_rr',
	CATEGORY_LANDING_02: 'categorylanding02',
	SIMILARATC: 'similaratc'
};

Object.freeze(schemes);

export { schemes };
