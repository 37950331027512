/**
 * Redirect to searchUrl (search page result)
 * @param {string} searchUrl
 */
function redirectToSearch (searchUrl) {

	const adjustedSearchUrl = searchUrl.replace('#', '');

	if (adjustedSearchUrl != 's_/') {

		const searchPartialUrl = `/products/${adjustedSearchUrl}?s=1`;
		// redirect to search result page
		window.location.href = searchPartialUrl;

	}

}

export default redirectToSearch;
