/*
	All objects in the events property of the request must have the same
	set of properties, whether or not the particular event uses them.

	This object sets up the default set of properties for an event payload

*/

const propertyNames = [
	'cartItemId',
	'event',
	'eventId',
	'hasAddToCart',
	'isOpenBox',
	'isRecommendedBulb',
	'patternColorCombination',
	'customizedProductCombination',
	'quantity',
	'section',
	'sectionId',
	'sharedItemId',
	'sku',
	'testCompositionId',
	'viewportHeight',
	'viewportWidth',
	'skuInputType'
];

const eventStructure = propertyNames.reduce((memo, name) => {

	memo[name] = null; // eslint-disable-line no-param-reassign
	return memo;

}, {});

export default eventStructure;
