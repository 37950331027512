import assetMap from '../../config/webpackAssetSet';

import prefetchWebpackAssets from './prefetchWebpackAssets';

function prefetchWebpackAssetSet (asset) {

	if (typeof asset === 'string') {

		if (Object.keys(assetMap).includes(asset)) {

			return prefetchWebpackAssets(assetMap[asset]);

		}

		return Promise.reject(new Error('Requested asset does not exist'));

	}

	return Promise.reject(new Error('Must provide string asset name'));

}

export default prefetchWebpackAssetSet;
