// adding international phone validation

const rule = {
	name: 'phoneInt',
	method: (value, element, params) => {

		const phoneNumberDelimiters = '()- ext.';
		const validWorldPhoneChars = `${phoneNumberDelimiters}+`;
		const minDigitsInIPhoneNumber = 10;
		const s = stripCharsInBag(value, validWorldPhoneChars);
		return isInteger(s) && s.length >= minDigitsInIPhoneNumber;

	},
	errorMessage: (params, element) => 'Please specify a valid phone number'
};

// adding valid US & International phone #

function isInteger (s) {

	let i;
	for (i = 0; i < s.length; i++) {

		const c = s.charAt(i);
		if (c < '0' || c > '9') {

			return false;

		}

	}
	return true;

}

function stripCharsInBag (s, bag) {

	let i;
	let returnString = '';

	for (i = 0; i < s.length; i++) {

		const c = s.charAt(i);
		if (bag.indexOf(c) == -1) {

			returnString += c;

		}

	}
	return returnString;

}

export default rule;
