function autoplayOnScroll (options) {

	const defaultOptions = {
		root: null, // Using the viewport
		rootMargin: '0px',
		threshold: 0
	};

	const observer = new IntersectionObserver(
		(entries) => {

			entries.forEach((entry) => {

				const videoEl = entry.target;

				if (entry.isIntersecting) {

					videoEl.play();

				} else {

					videoEl.pause();

				}

			});

		},
		{ ...defaultOptions, options }
	);

	return observer;

}

export default autoplayOnScroll;
