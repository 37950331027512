import { debounce } from 'lodash-es';

import encodePatternColorCombination from 'features/cart/addToCart/encodePatternColorCombination';
import { getViewportSize } from 'utilities/browser';

import azureApiRequest from './azureApiRequest';
import eventStructure from './eventStructure';

const debounceTime = 500;

const EVENT_NAME_SKU_VIEW = 'SkuView';
const EVENT_NAME_ADD_TO_CART = 'AddToCart';
const EVENT_NAME_ORDER_CONFIRMATION = 'OrderConfirmation';

const STATUS_PENDING = 'pending';
const STATUS_SENDING = 'sending';
const STATUS_TRACKED = 'tracked';
const STATUS_FAILED = 'failed';

let uniqueId = -1;
function autoId () {

	uniqueId += 1;
	return uniqueId;

}

class Reporter {

	constructor () {

		this.pageViewId = autoId();
		this.records = {};
		// Store the last recorded ID so consuming code can refer to tracking later
		this.lastRecordId = undefined;

		this.debouncedSend = debounce(() => {

			// Does the browser support requestIdleCallback
			if ('requestIdleCallback' in window) {

				return window.requestIdleCallback(this.send);

			}
			this.send();

		}, debounceTime);

		this.track = this.track.bind(this);
		this.send = this.send.bind(this);
		this.getRecordsByStatus = this.getRecordsByStatus.bind(this);

	}

	registerPageNavigation () {

		this.pageViewId = autoId();

	}

	flushOldPageRecords () {

		const currentPageViewId = this.pageViewId;
		// Avoid deleting pending records from previous page view by calling send
		this.send().then(() => {

			// All records have been sent, safe to cleanup
			const matchCurrentPageViewId = new RegExp(`^${currentPageViewId}:`);
			const oldRecords = Object.keys(this.records).filter((record) => !matchCurrentPageViewId.test(record));
			oldRecords.forEach((recordName) => {

				delete this.records[recordName];

			});

		});

	}

	static getSkuViewData (element) {

		const testCompositionId = element.dataset.testCompositionId || null;
		const { sku } = element.dataset;
		const parentSection = element.closest('[data-capture-section]');

		if (!parentSection) {

			console.error('Data Capture: Parent Section is does not exist.');
			return null;

		}

		const section = parentSection.dataset.captureSection;
		const hasAddToCart = parentSection.dataset.hasAddToCart || '0';
		const isRecommendedBulb = element.dataset.isRecommendedBulb || '0';
		const isOpenBox = element.dataset.isOpenBox || '0';
		const cartItemId = element.dataset.cartItemId || null;
		const sharedItemId = element.dataset.sharedItemId || null;
		const patternColorCombination = element.dataset.patternColorCombination || null;
		const customizedProductCombination = element.dataset.customizedProductCombination || null;
		const skuInputType = element.dataset.skuInputType ? Number(element.dataset.skuInputType) : null;
		const viewportSize = getViewportSize();

		return {
			event: EVENT_NAME_SKU_VIEW,
			cartItemId,
			hasAddToCart,
			isOpenBox,
			isRecommendedBulb,
			patternColorCombination,
			sku,
			section,
			sharedItemId,
			testCompositionId,
			viewportHeight: viewportSize.height,
			viewportWidth: viewportSize.width,
			customizedProductCombination,
			skuInputType
		};

	}

	static getAddToCartData (addToCartData, element) {

		const parentSection = element.closest('[data-capture-section]');

		if (!parentSection) {

			console.error('Data Capture: Parent Section does not exist.');
			return null;

		}

		const section = parentSection.dataset.captureSection;
		const isRecommendedBulb = element.dataset.isRecommendedBulb || '0';
		const cartItemId = element.dataset.cartItemId || null;

		const viewportSize = getViewportSize();

		return {
			event: EVENT_NAME_ADD_TO_CART,
			products: addToCartData,
			section,
			cartItemId,
			isRecommendedBulb,
			viewportHeight: viewportSize.height,
			viewportWidth: viewportSize.width
		};

	}

	static getOrderConfirmationData (orderConfirmationData) {

		return {
			event: EVENT_NAME_ORDER_CONFIRMATION,
			products: orderConfirmationData
		};

	}

	getUniqueId (data, additionalId) {

		switch (data.event) {

			case EVENT_NAME_SKU_VIEW: {

				return [
					this.pageViewId,
					data.event,
					data.sku,
					data.section,
					data.cartItemId,
					data.patternColorCombination,
					data.customizedProductCombination,
					data.sharedItemId,
					data.isRecommendedBulb,
					data.isOpenBox
				].join(':');

			}
			case EVENT_NAME_ADD_TO_CART:
			case EVENT_NAME_ORDER_CONFIRMATION: {

				return [ this.pageViewId, data.event, additionalId ].join(':');

			}
			default: {

				console.error(`Data Capture: Unique ID not implemented for event "${data.event}"`);
				return null;

			}

		}

	}

	isSkuViewRecorded (element) {

		return !!this.getRecordByElement(element);

	}

	isSkuViewTracked (element) {

		const record = this.getRecordByElement(element);
		return Boolean(record && record.status === STATUS_TRACKED);

	}

	isSkuViewDone (element) {

		const record = this.getRecordByElement(element);
		return Boolean(record && (record.status === STATUS_TRACKED || record.status === STATUS_FAILED));

	}

	// Called in the callback when the element qualifies
	track (data, options = { immediate: false }) {

		// Help generate a unique ID for Add to Cart and Order Confirmation events
		const isAutoIncrementEvent = [ EVENT_NAME_ADD_TO_CART, EVENT_NAME_ORDER_CONFIRMATION ].includes(data.event);
		const additionalId = isAutoIncrementEvent ? autoId() : undefined;

		const id = this.getUniqueId(data, additionalId);
		if (id === null) {

			console.error('Data Capture: tracking record id is null.');
			return Promise.reject();

		}

		if (this.records[id]) {

			console.warn(`Data Capture: call ignored for id ${id}; Already fired once.`);
			return Promise.resolve();

		}

		// Deferred promise
		let resolvePromise;
		let rejectPromise;
		const promise = new Promise((resolve, reject) => {

			resolvePromise = resolve;
			rejectPromise = reject;

		});

		this.records[id] = {
			data,
			status: STATUS_PENDING,
			promise,
			resolve: resolvePromise,
			reject: rejectPromise
		};
		this.lastRecordId = id;

		if (options.immediate) {

			this.send();

		} else {

			this.debouncedSend();

		}

		return promise;

	}

	trackAddToCart (addToCartData, element, options = { immediate: true }) {

		const data = Reporter.getAddToCartData(addToCartData, element);
		return this.track(data, options);

	}

	trackOrderConfirmation (orderConfirmationData) {

		const data = Reporter.getOrderConfirmationData(orderConfirmationData);
		return this.track(data, { immediate: true });

	}

	static makeEventPayload (data) {

		switch (data.event) {

			case EVENT_NAME_SKU_VIEW: {

				return [
					{
						...eventStructure,
						...data,
						eventId: Reporter.getEventIdByType(data.event),
						sectionId: Reporter.getPageSectionIdByType(data.section)
					}
				];

			}
			case EVENT_NAME_ADD_TO_CART: {

				const { products } = data;
				return products.map((product) => {

					const patternColorCombination =						product.PatternColorCombination && typeof product.PatternColorCombination === 'object'
						? encodePatternColorCombination(product.PatternColorCombination)
						: null; // null is object too
					const testCompositionId = product.TestCompositionId || null;
					const isRecommendedBulb = data.isRecommendedBulb || '0';
					const isOpenBox = product.IsOpenBox ? '1' : '0';
					const cartItemId = data.cartItemId || null;
					const customizedProductCombination = product.customizedProductCombination || null;
					const skuInputType =						Boolean(product.SkuInputType) || product.SkuInputType === 0
						? Number(product.SkuInputType)
						: null;

					return {
						...eventStructure,
						cartItemId,
						event: data.event,
						eventId: Reporter.getEventIdByType(data.event),
						isRecommendedBulb,
						isOpenBox,
						patternColorCombination,
						quantity: parseInt(product.Quantity, 10),
						section: data.section,
						sectionId: Reporter.getPageSectionIdByType(data.section),
						sku: product.ShortSku,
						testCompositionId,
						viewportHeight: data.viewportHeight,
						viewportWidth: data.viewportWidth,
						customizedProductCombination,
						skuInputType
					};

				});

			}
			case EVENT_NAME_ORDER_CONFIRMATION: {

				const { products } = data;
				return products.map((product) => {

					const patternColorCombination =						product.PatternColorCombination && typeof product.PatternColorCombination === 'object'
						? encodePatternColorCombination(product.PatternColorCombination)
						: null; // null is object too
					const testCompositionId = product.TestCompositionId || null;

					const isRecommendedBulb = product.IsRecommendedBulb || '0';
					const isOpenBox = product.IsOpenBox ? '1' : '0';
					const sharedItemId = product.SharedItemId || null;
					const customizedProductCombination = product.customizedProductCombination || null;
					const skuInputType =						Boolean(product.SkuInputType) || product.SkuInputType === 0
						? Number(product.SkuInputType)
						: null;

					return {
						...eventStructure,
						event: data.event,
						eventId: Reporter.getEventIdByType(data.event),
						isRecommendedBulb,
						isOpenBox,
						patternColorCombination,
						quantity: parseInt(product.Quantity, 10),
						sharedItemId,
						sku: product.ShortSku,
						testCompositionId,
						customizedProductCombination,
						skuInputType
					};

				});

			}
			default: {

				console.error(`Data Capture: Payload not implemented for event "${data.event}"`);
				return [];

			}

		}

	}

	static makeRequestPayload (events) {

		return {
			...window.lp.dataCapture.pageLevelData,
			events
		};

	}

	send () {

		const records = this.getRecordsByStatus(STATUS_PENDING);

		if (records.length === 0) {

			return Promise.resolve({});

		}

		// Get payload data
		const events = records.reduce((memo, record) => memo.concat(Reporter.makeEventPayload(record.data)), []);

		const data = Reporter.makeRequestPayload(events);

		// Update status of records to track as in progress
		Reporter.updateRecordsStatus(records, STATUS_SENDING);

		// Implementation: Batch
		const request = azureApiRequest(data);

		request
			.then(() => {

				// Update status of records to track as tracked
				Reporter.updateRecordsStatus(records, STATUS_TRACKED);

			})
			.catch(() => {

				// TODO: how to deal with failures? just log? do we store in local storage? retry?
				// TODO: what if user leaves the page??

				Reporter.updateRecordsStatus(records, STATUS_FAILED);

			});

		return request;

	}

	getLastRecordId () {

		return this.lastRecordId;

	}

	getRecordById (id) {

		return this.records[id];

	}

	getRecordByElement (element) {

		const data = Reporter.getSkuViewData(element);
		const id = this.getUniqueId(data);

		return this.records[id];

	}

	getRecordsByStatus (status) {

		return Object.keys(this.records).reduce((memo, item) => {

			const record = this.records[item];
			if (record.status === status) {

				memo.push(record);

			}
			return memo;

		}, []);

	}

	static updateRecordsStatus (records, status) {

		records.forEach((record) => {

			record.status = status; // eslint-disable-line no-param-reassign

			if (status === STATUS_TRACKED) {

				record.resolve();

			} else if (status === STATUS_FAILED) {

				record.reject();

			}

		});

	}

	static getEventIdByType (type) {

		const event = window.lp.dataCapture.eventsData[type];
		if (event) {

			return event;

		}

		console.error(`Event Id not found for ${type}`);

	}

	static getPageSectionIdByType (type) {

		const pageSection = window.lp.dataCapture.pageSectionsData[type];
		if (pageSection) {

			return pageSection;

		}

		console.error(`Page Section Id not found for ${type}`);

	}

}

export default Reporter;
