function redirectPlaToPdp () {

	const sfpRegEx = /\/sfp\//i;
	const productsPath = '/products/';

	if (window.top.location.href.match(sfpRegEx)) {

		// sfp
		window.top.location = window.top.location.href.replace(sfpRegEx, productsPath);

	}

}

export default redirectPlaToPdp;
