function setPageLevelDataCapture () {

	const utag_data = window.utag_data || {};

	const pageLevelData = {
		...window.lp.dataCapture.pageLevelData,
		aspnetSessionId: utag_data.aspnet_session_id || null,
		viewId: utag_data.view === 'Desktop' ? '1' : '2',
		resolution: `${window.screen.width}x${window.screen.height}`,
		userSessionManagerId: utag_data.user_session_manager_id || null,
		correlationId: utag_data.correlation_id || null,
		websiteMode: utag_data.website_mode || null,
		storeNumber: utag_data['cp.BopusStoreNumber'] || null,
		url: utag_data['dom.url'] || window.location.href,
		pageTypeId: utag_data.page_type_id || null,
		userType: utag_data.user_type || null,
		sublocationCode: utag_data.sublocation_code || null,
		cartId: utag_data.cart_id || null,
		orderConfirmationId: utag_data.order_id || null,
		userAgent: navigator.userAgent,
		referrerUrl: utag_data.referral_url || null,
		rewardNumber: utag_data.customer_rewards_number || ''
	};

	window.lp.dataCapture.pageLevelData = pageLevelData;

}

export default setPageLevelDataCapture;
