import request from './request';

function getDefaultStore () {

	return request({
		options: {
			path: '/storeavailability/mystore'
		}
	});

}

export default getDefaultStore;
