import { utagLinkHeader, utagViewArtificialPageView } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

const trackArtificalPageViewEvent = utagViewArtificialPageView();
const trackHeaderEvent = utagLinkHeader();

function initSignInButtonEvents (hideDropDown) {

	const signInButton = document.getElementById('signInButton');
	const signInFormContainer = document.querySelector('.signInFormContainer');

	if (!signInButton || !signInFormContainer) {

		return;

	}

	signInButton.addEventListener(
		'click',
		() => {

			trackHeaderEvent({ eventAction: 'click-Account-Sign-In', eventLabel: window.location.pathname });
			setGenericEvent({ event_name: 'navtop_SIGN IN_click' });

		},
		{ once: true }
	);

	$(signInButton).lpModal({
		content: $(signInFormContainer),
		lpModalClass: 'lpModal--alt1',
		width: 400,
		height: 520,
		closeEvt: () => {

			trackArtificalPageViewEvent({ pageName: '' });

		},
		preCloseEvt () {

			window.signInFormValidator.resetForm();

			return 1;

		},
		openEvt: () => {

			trackArtificalPageViewEvent({ pageName: 'signin' });

			if (typeof hideDropDown === 'function') {

				hideDropDown();

			}

		},
		afterOpenEvt () {

			// Safari 15.3 focusing on input fields and displaying validations
			// Following snippet can be removed if issue is fixed by browser vendor
			setTimeout(() => {

				window.signInFormValidator.resetForm();

			}, 0);

		},
		backdropClose: false
	});

}

export default initSignInButtonEvents;
