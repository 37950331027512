type GeoLocationOptions = {
	maximumAge?: number,
	timeout?: number,
	enableHighAccuracy?: boolean
}

function getCurrentPosition(options: GeoLocationOptions = {
	maximumAge: 0,
	timeout: 10 * 1000,
	enableHighAccuracy: true
}) {

	if (window.navigator.geolocation) {

		return new Promise((resolve, reject) => window.navigator.geolocation.getCurrentPosition(resolve, reject, options));

	}

	return new Promise((resolve, reject) => reject());

}

export default getCurrentPosition;
