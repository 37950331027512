import { persistentSearchSessionStorageName, persistentSearchTypes } from './config';

const propExists = (prop) => prop !== undefined && prop !== '';
const urlExistsIfPdpSearch = (parsedObj) => (parsedObj.resultType === persistentSearchTypes.pdp ? propExists(parsedObj.url) : true);

// Used to populate search bar with the last searched string
function getPersistentSearch () {

	const parsed = JSON.parse(window.sessionStorage.getItem(persistentSearchSessionStorageName));

	// Check validity of all possible properties
	if (parsed && propExists(parsed.query) && propExists(parsed.resultType) && urlExistsIfPdpSearch(parsed)) {

		return parsed;

	}

	return null;

}

export default getPersistentSearch;
