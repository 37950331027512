function utagLinkAddToCart (tag, sortParams) {

	if (tag && typeof tag === 'object') {

		// remove the page_type, event_value and non-interaction flag.
		const {
			page_type, event_value, gua_non_interaction, ...restTag
		} = tag;

		// combine event props with utag data
		const data = {
			...restTag,
			event_name: 'cart_add',
			event_category: 'ecommerce',
			event_action: 'add to cart',
			event_label: tag.product_sku.join(),
			product_impression_list: sortParams ? [ sortParams.SortPath ] : '',
			product_impression_position: sortParams ? [ sortParams.SortPosition ] : '',
			list_type: sortParams ? sortParams.ListType : ''
		};

		// delete this because it has irrelevant skus in it from sort page.
		delete data.product_impression_sku;
		delete data.product_impression_name;

		return data;

	}

}

export default utagLinkAddToCart;
