function init () {

	$('#lpHeader-hdrPortal .freeShippingHeader').on('click keypress', function (e) {

		if (e.key === 'Enter' || e.type === 'click') {

			$(this).lpModal({
				content: $('#freeShippingOverlay'),
				lpModalClass: 'lpModal--alt1',
				height: 198,
				width: 624,
				onInit: true
			});

		}

	});

}
export default init;
