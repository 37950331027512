import { getAssetCounts } from 'features/asset/api';

import showCartErrorMessage from './showCartErrorMessage';

import './addToCartFailure.scss';

/**
 * Show Add to Cart Failure messages in a modal or if no messages, use showCartErrorMessage.
 * @param params Object containing:
 * 	cartStatus - string or array of error codes/messages
 *	fromModal - flag to indicate if modal is already present to display the error message in the current modal and
 *	hide the existing content response - the full response of the Cart API ajax call that resulted in the error
 */
function showAddToCartFailureModal (params) {

	// If we have no AddToCartFailures, use showCartErrorMessage instead
	if (!params.response || !params.response.AddToCartFailures || params.response.AddToCartFailures.length < 1) {

		showCartErrorMessage(params);
		return null;

	}

	const modalConfig = {
		onInit: true,
		width: 402,
		height: 448,
		appendContentToForm: false
	};

	return {
		parentSelector: '.addToCartFailureModal',
		initialize () {

			modalConfig.closeEvt = () => {

				$(this.parentSelector).remove();

			};

			if (params.modalOptions) {

				if (typeof params.modalOptions.preCloseEvt === 'function') {

					modalConfig.preCloseEvt = params.modalOptions.preCloseEvt;

				}

			}

			this.render();

			$('.addToCartFailureModal .dismiss')
				.off()
				.on('click', (e) => {

					e.preventDefault();

					$('body').lpModalClose();

				});

			getAssetCounts(window.lp.globals.isCustomerConnected);

		},
		render () {

			let $modalContent;

			if (params.fromModal) {

				$modalContent = window.parent.$('#lpModalContent');
				$modalContent.children().hide();
				window.parent.$('body').lpModalResize({
					width: modalConfig.width,
					height: modalConfig.height
				});
				$modalContent.append(this.htmlTemplate()).show();

				this.setCartFailureListHeight($modalContent.find('.addToCartFailures'));

			} else {

				$('body').append(this.htmlTemplate());
				modalConfig.content = $(this.parentSelector);
				$('body').lpModal(modalConfig);

				this.setCartFailureListHeight($('body').find('.addToCartFailures'));

			}

		},
		setCartFailureListHeight ($list) {

			const $listItems = $list.find('li');
			let listHeight = 0;

			if ($listItems.length >= 4) {

				$listItems.each(function (index) {

					if (index < 3) {

						listHeight += parseFloat($(this).outerHeight());

					} else if (index === 3) {

						listHeight += parseFloat($(this).outerHeight()) / 2;

					}

				});

				$list.css({
					height: `${listHeight}px`,
					'overflow-y': 'auto'
				});

			}

		},
		htmlTemplate () {

			let addToCartFailuresHtml;

			if (params.response.AddToCartFailures) {

				const addToCartFailuresList = params.response.AddToCartFailures.map(
					(product) => `
							<li>
								<div class="productImage">
									<img
										src="${product.ProductImageUrl}"
									/>
								</div>
								Style ${product.ShortSku}<br>
								${product.ErrorMessage}
							</li>
							`
				).join('');

				addToCartFailuresHtml = `
						<ul class="addToCartFailures">
							${addToCartFailuresList}
						</ul>
					`;

			} else {

				addToCartFailuresHtml = '';

			}
			return `
			<div class="addToCartFailureModal">
				<p><strong>There are issues with some of the products selected.  See details below.</strong><br>
				For questions, please call ${window.lp.globals.consultantPhoneNumber}.</p>

				${addToCartFailuresHtml}

				<div class="tac">
					<button type="button" class="calloutBtn alt1 dismiss">Dismiss</button>
				</div>
			</div>
			`;

		}
	}.initialize();

}

export default showAddToCartFailureModal;
