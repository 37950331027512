/**
 * Initialize event listener to block "clicking" aria-disabled button by blocking Enter key while button has focus.
 * Mouse click should already be blocked via pure CSS by adding "noPointerEvents" class name.
 * For accessibility reason, we are using "aria-disabled" instead of "disabled" attribute for disabled buttons.
 * @param {HTMLElement} buttonElement - The button DOM element,
 * @param {Boolean} isStopPropagation - Boolean flag to indicate if stopPropagation() needs to be called or not. Optional.
 * @param {Boolean} isStopImmediatePropagation - Boolean flag to indicate if stopImmediatePropagation() needs to be called
 * 											or not. Optional.
 */
function initBlockEnterKeyForDisabledButton (buttonElement, isStopPropagation, isStopImmediatePropagation) {

	if (!buttonElement) {

		return;

	}

	const keydownListener = (e) => {

		if (e.currentTarget.getAttribute('aria-disabled') !== 'true') {

			return true;

		}

		// event.which and event.keyCode are deprecated but IE/Edge don't support event.key, so...
		const isEnter = e.key === 'Enter' || e.which === 13;

		// Pressing Space bar on buttons also triggers 'click'
		const isSpaceBar = e.key === ' ' || e.key === 'Spacebar' || e.which === 32;

		if (isEnter || isSpaceBar) {

			e.preventDefault();

			if (isStopPropagation) {

				e.stopPropagation();

			}

			if (isStopImmediatePropagation) {

				e.stopImmediatePropagation();

			}

			return false;

		}

		return true;

	};

	buttonElement.removeEventListener('keydown', keydownListener);
	buttonElement.addEventListener('keydown', keydownListener);

}

export default initBlockEnterKeyForDisabledButton;
