function renderRoomCount (count) {

	const element = window.top.document.getElementById('savedPortfolio-yourRooms--spnRoomsCount');
	const wrapper = window.top.document.getElementById('savedPortfolio-yourRooms');

	if (element && wrapper) {

		if (count > 0) {

			wrapper.classList.remove('zeroRoom');
			element.textContent = count;

		} else {

			wrapper.classList.add('zeroRoom');
			element.textContent = 0;

		}

	}

	window.top.lp.globals.roomCount = count;

}

export default renderRoomCount;
