import React from 'react';
import PropTypes from 'prop-types';

import './Currency.scss';

function Currency ({ price, sign, superscriptCents }) {

	const data = price.toString().split('.');

	const dollars = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	let cents = `.${data[1] || '00'}`;
	// always use 2 digits for cents
	cents = cents.substring(0, 3).length === 2 ? `${cents.substring(0, 3)}0` : cents.substring(0, 3);

	const currencySign = sign ? ` ${sign}` : '';

	return (
		<span className="Currency">
			{superscriptCents ? <span className="Currency__superscript">$</span> : '$'}
			{dollars}
			{superscriptCents ? (
				<span className="Currency__superscript">
					{cents}
				</span>
			) : cents}
			{currencySign}
		</span>
	);

}

Currency.propTypes = {
	price: PropTypes.oneOfType([ PropTypes.string.isRequired, PropTypes.number.isRequired ]),
	sign: PropTypes.string,
	superscriptCents: PropTypes.bool
};

export default Currency;
