import { addSaleAttributesToProducts } from 'features/certona/utilities';
import { formatCurrency } from 'utilities/localization';

import calloutHelper from './calloutHelper';

function getSimiliarNoSearchContent (results, settings) {

	const { classname, moreLikeThis, outerClass } = settings;
	const imageSize = 274;
	const baseUrl = window.lp.globals.secureSiteURL;

	return results.Products.map(addSaleAttributesToProducts)
		.map((p, i) => {

			const calloutHTML = calloutHelper(p.CertonaProductModel.SearchCallout);
			const addMoreLikeThis = moreLikeThis
				? `<a href="/more-like-this/${p.ShortSku}/" class="sortMoreLikeThisBtn" rel="nofollow">More Like This</a>`
				: '';
			const priceBlock = `
				<div class="${p.saleClassName} ${classname}ProdPrice" itemprop="sku" content="${p.ShortSku}">
					${formatCurrency(p.FormattedPrice)}${p.clearanceCalloutText}${p.saleCalloutText}
					${addMoreLikeThis}
				</div>
				`;

			return `
				${outerClass ? `<div class="${classname}Container">` : ''}
					<a href="${baseUrl}${p.Url}" target="_top" data-certonaSku="${p.ShortSku}">
						${calloutHTML}
						<div class="${classname}ImgContainer">
							<img class="${classname}ProdImg"
							src="${p.ImageBaseUrl}?qlt=55&amp;wid=${imageSize}&amp;hei=${imageSize}&amp;op_sharpen=1&amp;fmt=jpeg"
							width="${imageSize}" height="${imageSize}" data-sku="${p.ShortSku}" data-sku-input-type="${p.SkuInputType}"
							data-price="${p.FormattedPrice}" data-primary-category="${p.Category}" data-position="${i + 1}" title="${p.ProductName}" alt="" />
						</div>
						<span class="${classname}ProdName" itemprop="name">${p.ProductName}</span>
					${moreLikeThis ? `</a>${priceBlock}` : `${priceBlock}</a>`}
				${outerClass ? '</div>' : ''}
				`;

		})
		.join('');

}

export default getSimiliarNoSearchContent;
