import { setGenericEvent } from 'features/tealium/ga4/custom';
import { domReady } from 'utilities/dom';

domReady(() => {

	let eventTriggered = false;
	const recentlyGaEvents = () => {

		if (eventTriggered) return;
		eventTriggered = true;
		const container = document.getElementById('recentlyViewedContainer');

		if (!container) {

			return;

		}
		const { scheme } = container.parentElement.dataset;
		const isSchemeGfFullRr = scheme === 'GF_full_rr';

		const viewAllLink = document.querySelector('.viewAllRecentlyViewedBtn');
		const moreLikeThisLinks = container.querySelectorAll('.moreLikeThisLink');
		const recentlyViewedProdImgs = container.querySelectorAll('.moreYouMayLikeItem a:first-child');

		const onClickGenerator = (eventAction) => (element, i) => {

			element.addEventListener('click', () => {

				setGenericEvent({ event_name: eventAction, item_position_number: i + 1 });

			});

		};

		recentlyViewedProdImgs.forEach(onClickGenerator('rcntvw_product_click'));
		if (!isSchemeGfFullRr) {

			viewAllLink.addEventListener('click', () => {

				setGenericEvent({ event_name: 'rcntvw_viewall_click' });

			});
			moreLikeThisLinks.forEach(onClickGenerator('rcntvw_mlt_click'));

		}

	};

	// Certona
	if (window.lp.globals.canShowCertona) {

		document.addEventListener('recommendedItems:loaded', recentlyGaEvents);
		if (window.lp.globals.isMobile && [ 'sfp_full', 'sfp_partial' ].includes(window.utag_data.page_type)) {

			const eventInterval = setInterval(() => {

				if (document.querySelectorAll('#recentlyViewedContainer .moreYouMayLikeItem a').length) {

					recentlyGaEvents();
					clearInterval(eventInterval);

				}

			}, 1000);

		}

	}

});
