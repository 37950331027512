import dynamicImport from '../../../../features/webpack/dynamicImport.macro';

const { compactGlobalHeaderEnabled, stickyGlobalHeaderEnabled, stickySearchEnabled } = window.lp.layoutData;

if (compactGlobalHeaderEnabled || stickyGlobalHeaderEnabled) {

	dynamicImport('desktop/layouts/page-navBarHeader-compactGlobalHeader', './../compactGlobalHeader').then(
		(module) => {

			const initCompactGlobalHeader = module.default;
			initCompactGlobalHeader(stickyGlobalHeaderEnabled, stickySearchEnabled);

		}
	);

}
