import { formatCurrency } from 'utilities/localization';

function getMoreLikeThisCartPageContent (result, settings = {}) {

	const { withOutBase, imageSize } = settings;
	const baseUrl = withOutBase ? '' : window.lp.globals.secureSiteURL;

	if (result.Products.length > 0) {

		return result.Products.map((p, index) => {

			let ratingStr = null;
			const ratings = (Math.round((p.CertonaProductModel.TurnToAverageRate + 0.25) * 100.0) / 100.0).toString();
			if (ratings > 2.5) {

				const decimal = parseInt(ratings.substring(2, 3), 10);
				ratingStr = `${ratings.substring(0, 1)}-${decimal >= 5 ? '5' : '0'}`;

			}
			return `
			<div class="moreYouMayLikeProduct">
				<a class="sortResultProdImage" tabindex="0" data-certonasku="${p.ShortSku}" data-url="${baseUrl}${
	p.Url
}" data-index="${index}" data-scheme="SimilarATC">
				<div class="productImage">
					<div class="sortResultImgContainer">
						<img class="sortResultProdImg" data-sku="${p.ShortSku}" 
						data-sku-input-type="${p.SkuInputType}" src="${
	p.ExtraSmallImageUrl
}" width="${imageSize}" height="${imageSize}" title="${p.ProductName}" alt="${p.ProductName}">
					</div>
					</div>
					<div class="productName">
						<span>${p.ProductName}</span>
					</div>
				</a>
				<div class="productSummaryRating">
					<div class="goldReviewStars goldReviewStars--${ratingStr}">
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
					</div>
					<span class="productSummaryRating__reviewCount">
						${ratingStr ? `(${p.CertonaProductModel.TurnToReviewCount})` : ''}
					</span>
				</div>
				<div class="productRate" content="${p.ShortSku}">
					<div class="productPrice">
					<div class="itemTotal">${formatCurrency(p.FormattedPrice)} ${
	p.CertonaProductModel.SearchCallout.IsOnSale ? '<span class="onSale">Sale</span>' : ''
}</div>
					</div>
				</div>
				<div class="addToCartSection">
					<button type="button"  tabindex="0" class="calloutBtn modalAddToCartButton"
						data-capture-section="MoreLikeThisModal" data-has-add-to-cart="1"
						data-sku-input-type="${p.SkuInputType}" 
						data-sku="${p.ShortSku}">Add to Cart</button>
				</div>
			</div>`;

		}).join('');

	}
	return `<div class="noSimilarProductsMsg">
					<h5>We apologize, there are no products similar to this one.</h5>
				</div>`;

}

export default getMoreLikeThisCartPageContent;
