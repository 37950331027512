import { baseAPIRequest } from 'utilities/http';

function getRecommendedItems ({ data, currentWebSiteModeSubLocation, timer }) {

	const url = `/api/recommendeditems/?cartSubLocationCode=${currentWebSiteModeSubLocation}&_=${new Date().getTime()}`;
	return baseAPIRequest({
		url,
		data,
		params: { method: 'POST' },
		timer
	});

}

export default getRecommendedItems;
