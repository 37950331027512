function loadJsFile (jsPath: string, scriptTagId: string, attributes: Record<string, string> = {}) {

	return new Promise((resolve, reject) => {

		const js = document.getElementById(scriptTagId);
		if (!js) {

			const script = document.createElement('script');
			script.id = scriptTagId;

			if (attributes) {

				Object.keys(attributes).forEach((key) => {

					script.setAttribute(key, attributes[key]);

				});

			}

			script.onload = () => resolve(scriptTagId);
			script.onerror = () => {

				document.head.removeChild(script);
				reject(new Error(`JS ${scriptTagId} failed to load.`));

			};

			script.src = jsPath;
			document.head.appendChild(script);

		} else {

			resolve(scriptTagId);

		}

	});

}

export default loadJsFile;
