import LPGlider from 'ui/glider';

const initMymlGlider = ({ arrowsSelector, selector, content }) => {

	const gliderContainer = document.querySelector(selector);

	if (!gliderContainer) {

		return null;

	}

	gliderContainer.innerHTML = content;

	// Initialize Glider
	const gliderOptions = {
		slidesToShow: 5,
		slidesToScroll: 5,
		dots: false,
		rewind: false,
		duration: 0,
		arrows: {
			prev: `${arrowsSelector} .glider-prev`,
			next: `${arrowsSelector} .glider-next`
		}
	};

	gliderContainer.addEventListener('glider-loaded', function () {

		const glider = LPGlider(this);
		glider.setActiveSlide(0);
		this.classList.remove('glider-loading');

	});

	return new LPGlider(gliderContainer, gliderOptions);

};

export default initMymlGlider;
