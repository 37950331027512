import LPGlider from 'ui/glider';

function initRecentlyViewedGlider (recentlyViewedContent, { slidesToShow = 5, ...rest } = {}) {

	const recentlyViewedContainer = document.getElementById('recentlyViewedContainer');

	if (!recentlyViewedContainer) {

		return null;

	}

	recentlyViewedContainer.innerHTML = recentlyViewedContent;

	const recentlyViewedWrapperSelector = '.recentlyViewedWrapper';

	// Initialize Glider
	const gliderOptions = {
		slidesToShow,
		slidesToScroll: slidesToShow,
		dots: false,
		rewind: false,
		duration: 0,
		arrows: {
			prev: `${recentlyViewedWrapperSelector} .glider-prev`,
			next: `${recentlyViewedWrapperSelector} .glider-next`
		},
		...rest
	};

	// Glider doesn't work when elements are hidden
	document.querySelector(recentlyViewedWrapperSelector).classList.remove('hidden');

	recentlyViewedContainer.addEventListener('glider-loaded', function () {

		const glider = LPGlider(this);
		glider.setActiveSlide(0);
		this.classList.remove('glider-loading');

	});

	return new LPGlider(recentlyViewedContainer, gliderOptions);

}

export default initRecentlyViewedGlider;
