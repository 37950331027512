import { addSaleAttributesToProducts } from 'features/certona/utilities';
import { formatCurrency } from 'utilities/localization';

import calloutHelper from './calloutHelper';

const isLuxuryLightingPage = window.location.href.indexOf('/lp_luxury-lighting/') > -1;
const imgSize = isLuxuryLightingPage ? 382 : 274;

function getMoreLikeThisContent (results) {

	return results.Products.map(addSaleAttributesToProducts)
		.map((p, i) => {

			const calloutHTML = calloutHelper(p.CertonaProductModel.SearchCallout);
			const moreLikeThisURL = isLuxuryLightingPage
				? `/lp_luxury-lighting/more-like-this/${p.ShortSku}/`
				: `/more-like-this/${p.ShortSku}/`;

			return `
		<div class="sortResultContainer">
			<a target="_top" href="${p.Url}" data-certonaSku="${p.ShortSku}" data-has-product-click>
				<div class="sortResultImgContainer">
					${calloutHTML || ''}
					<img class="sortResultProdImg" data-sku="${p.ShortSku}" data-sku-input-type="${p.SkuInputType}" data-price="${
	p.Price
}" data-position="${i + 1}" data-product-is-lpproduct="${p.IsLPProduct ? 1 : 0}"
					src="${p.ImageBaseUrl}?qlt=75&wid=${imgSize}&hei=${imgSize}&op_sharpen=1&resMode=sharp2&fmt=jpeg"
					width=${imgSize} height=${imgSize}
					 title="${p.ProductName}" alt="" />
				</div>
				<div class="sortResultProdName">
					<span>${p.ProductName}</span>
				</div>
			</a>

			<div class="sortResultsProdInfo" content="${p.ShortSku}">
				<div class="${p.saleClassName} sortResultProdPrice">
					${formatCurrency(p.FormattedPrice)}${p.clearanceCalloutText}${p.saleCalloutText}
					<br />
				</div>
				<div class="sortResultSavePrice"></div>
				<div class="clear"></div>
				<div class="sortSaveShipReturns">
					${p.ShippingText ? p.ShippingText : ''}
				</div>
				<a target="_top" href="${moreLikeThisURL}" class="sortMoreLikeThisBtn" rel="nofollow">
					More Like This
				</a>
			</div>
		</div>
		`;

		})
		.join('');

}

export default getMoreLikeThisContent;
