import { offset } from 'utilities/dom';

function unveil (selectorOrNodelist, threshold = 0, isCapturing = false, scrollContainer) {

	if (
		!selectorOrNodelist
		|| selectorOrNodelist.length === 0
		|| (typeof selectorOrNodelist !== 'string' && !(selectorOrNodelist instanceof NodeList))
	) {

		return;

	}

	// we want an array of img nodes in order to remove loaded images from it
	let images = Array.prototype.slice.call(
		typeof selectorOrNodelist === 'string' ? document.querySelectorAll(selectorOrNodelist) : selectorOrNodelist
	);

	if (images.length === 0) {

		return;

	}

	const attrRetina = 'data-src-retina';
	const attrDefault = 'data-src';
	const attr = window.devicePixelRatio > 1 ? attrRetina : attrDefault;
	const inPartialView = (el) => {

		const { offsetWidth, offsetHeight } = el;
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= -offsetHeight
			&& rect.left >= -offsetWidth
			&& rect.right <= (window.innerWidth || document.documentElement.clientWidth) + offsetWidth
			&& rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offsetHeight
		);

	};

	function unveilImages () {

		// filter out loaded images as it processes lazy loading
		images = images.filter((image) => {

			const isImageInView = inPartialView(image);

			// do not show animated skeleton UI for images that are not visible in viewport
			// to prevent unnecessary browser repaint
			if (!isImageInView) {

				image.classList.add('notInView');
				return true;

			}

			image.classList.remove('notInView');

			const winScrollTop = window.pageYOffset;
			const winBottom = winScrollTop + window.innerHeight;
			const imageOffsetTop = offset(image).top;
			const imageStyle = window.getComputedStyle(image, null);
			const imageHeight =				image.offsetHeight - parseInt(imageStyle.paddingTop, 10) - parseInt(imageStyle.paddingBottom, 10);
			const imageBottom = imageOffsetTop + imageHeight;

			if (imageBottom >= winScrollTop - threshold && imageOffsetTop <= winBottom + threshold) {

				const imgSrc = image.getAttribute(attr) || image.getAttribute(attrDefault);
				if (imgSrc) {

					const onload = (e) => {

						const img = e.target;
						img.classList.add('unveil--done');
						img.removeEventListener('load', onload);

					};

					const onerror = (e) => {

						const img = e.target;
						img.classList.add('unveil--done');
						img.removeEventListener('error', onerror);

					};

					image.addEventListener('load', onload);
					image.addEventListener('error', onerror);
					image.setAttribute('src', imgSrc);
					image.removeAttribute(attrDefault);
					image.removeAttribute(attrRetina);

				}

				// image has been lazy loaded or has no data-src
				// remove it to prevent from looping to it again
				return false;

			}

			// keep image on the list for next execution
			return true;

		});

	}

	window.addEventListener('scroll', unveilImages, isCapturing);

	if (scrollContainer) {

		scrollContainer.addEventListener('scroll', unveilImages);

	}

	// unveil on init
	unveilImages();

}

export default unveil;
