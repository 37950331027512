function createResxclsaScript () {

	const resxclsaScriptId = 'resxclsaScript';

	if (document.getElementById(resxclsaScriptId) === null) {

		// add Certona pixel tracking js
		const certonaScript = document.createElement('script');
		certonaScript.type = 'text/javascript';
		certonaScript.async = true;
		certonaScript.src = `/Scripts/_vendor/certona/resxclsa.js${window.resx.resourceversion}`;
		certonaScript.id = resxclsaScriptId;

		document.querySelector('head').appendChild(certonaScript);

	}

}

export default createResxclsaScript;
