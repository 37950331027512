function getCommonParamsHeaders ({ withAntiForgery = false } = {}) {

	const { antiForgeryHeaderKey, antiForgeryToken } = window.lp.globals || window.parent.lp.globals;

	const commonParamHeader = {
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			'X-Requested-With': 'XMLHttpRequest'
		},
		params: {
			credentials: 'same-origin'
		}
	};

	if (withAntiForgery) {

		commonParamHeader.headers[antiForgeryHeaderKey] = antiForgeryToken;

	}

	return commonParamHeader;

}

// GET headers
function setGetRequestParams ({ params = {}, headers = {} } = {}) {

	const common = getCommonParamsHeaders();

	return {
		...common.params,
		method: 'GET',
		...params,
		headers: { ...common.headers, ...headers }
	};

}

// POST headers
function setPostRequestParams ({ data, params = {}, headers = {} } = {}) {

	try {

		const common = getCommonParamsHeaders({ withAntiForgery: true });
		const requestParams = {
			...common.params,
			method: 'POST',
			...params,
			headers: { ...common.headers, ...headers }
		};

		// allow for missing data param (not common for POST requests)
		if (data) {

			requestParams.body = JSON.stringify(data);

		}

		return requestParams;

	} catch (ex) {

		throw new Error(`ERROR:${ex}`);

	}

}

function setPutRequestParams ({ data, params = {}, headers = {} } = {}) {

	const common = getCommonParamsHeaders({ withAntiForgery: true });

	return {
		...common.params,
		method: 'PUT',
		body: JSON.stringify(data),
		...params,
		headers: { ...common.headers, ...headers }
	};

}

export { setGetRequestParams, setPostRequestParams, setPutRequestParams };
