import utagLinkGeneric from './utagLinkGeneric';

function utagLinkManageAccount (eventCategory = 'Manage Account') {

	return ({ eventAction, eventLabel = '' }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel
		});

	};

}

export default utagLinkManageAccount;
