import { setPostRequestParams, setGetRequestParams } from 'utilities/http';

function request ({ data, options }) {

	if (!options) {

		throw new Error('Options object is required, but missing.');

	}

	const rawResponseHandler = (r) => {

		if (!r.ok) {

			throw new Error(r.statusText);

		}
		return r.json();

	};
	const requestData = data || true;
	let requestUrl = `/api${options.path}`;
	const requestMethod = options.method ? options.method.trim().toLowerCase() : 'get';
	let requestParams;

	if (requestMethod === 'get') {

		// // append querystring for GET requests
		if (typeof requestData === 'object') {

			requestUrl += `?${Object.keys(requestData)
				.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(requestData[key])}`)
				.join('&')}`;

		}
		requestParams = setGetRequestParams({
			headers: { 'Content-Type': options.contentType || 'application/json;charset=UTF-8' }
		});

	} else {

		requestParams = setPostRequestParams({
			data: requestData,
			headers: { 'Content-Type': options.contentType || 'application/json;charset=UTF-8' },
			params: { method: requestMethod }
		});

	}

	return fetch(requestUrl, requestParams).then(rawResponseHandler);

}

export default request;
