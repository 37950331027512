import { getAssetCounts } from '../api';

import renderCartCount from './renderCartCount';
import renderRoomCount from './renderRoomCount';
import renderWishListCount from './renderWishlistCount';
import renderTotalSavedPortfolioCount from './renderTotalSavedPortfolioCount';

function updateCounts () {

	const { lp } = window.top;

	getAssetCounts(lp.globals.isCustomerConnected)
		.then((response) => response.json())
		.then(({ CartItemCount, SceneCount, WishlistItemCount }) => {

			lp.globals.cartCount = CartItemCount;
			lp.globals.roomCount = SceneCount;
			lp.globals.wishlistItemCount = WishlistItemCount;
			renderCartCount(CartItemCount);
			renderRoomCount(SceneCount);
			renderWishListCount(WishlistItemCount);
			if (!lp.globals.isHospitality) renderTotalSavedPortfolioCount(SceneCount + WishlistItemCount);
			else renderTotalSavedPortfolioCount(WishlistItemCount);

		})
		.catch(() => renderRoomCount(0));

}

export default updateCounts;
