/**
 * Delayed execution of any function. Only last call will be executed;
 */
function delayedCall<T extends (...args: any[]) => any>(
	context: any,
	fn: T,
	milliseconds: number) {

	let timerFn: () => void;
	let timer: ReturnType<typeof setTimeout>;
	let pReject: (parameter: any) => any; // previous reject function

	/** Cancel delayed call */
	function cancel () {

		if (timer) {

			clearTimeout(timer);
			timer = undefined;
			pReject('Delayed call was canceled');
			return true;

		}
		return false;

	}

	/** Immediately invoke delayed call */
	function now () {

		if (timerFn && timer) {

			clearTimeout(timer);
			timer = undefined;
			timerFn();
			return true;

		}
		return false;

	}

	function delay (...args: Parameters<T>): Promise<ReturnType<T>> {

		return new Promise((resolve, reject) => {

			cancel();
			pReject = reject;
			timerFn = () => {

				timer = undefined;
				resolve(fn.apply(context, args));

			};
			timer = setTimeout(timerFn, milliseconds);

		});

	}

	delay.cancel = cancel;
	delay.now = now;

	return delay;

}

export default delayedCall;
