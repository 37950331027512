import classNames from 'classnames';

import { formatCurrency } from 'utilities/localization';

import { schemes } from '../schemes';

function getSortPageContent (result, settings = {}) {

	const { withOutBase, scheme } = settings;
	const baseUrl = withOutBase ? '' : window.lp.globals.secureSiteURL;
	const isCategoryLanding1 = scheme === schemes.CATEGORY_LANDING;
	const isCategoryLanding2 = scheme === schemes.CATEGORY_LANDING_02;
	const className = classNames({
		sortTrendingProdContainer: isCategoryLanding2,
		sortMYMLProdContainer: !isCategoryLanding2,
		'sortMYMLProdContainer--landing': isCategoryLanding1
	});

	return result.Products.map(
		(p) => `
				<div class="${className}">
					<a href="${baseUrl}${p.Url}" data-certonaSku="${p.ShortSku}">
						<img
						src="${p.ImageBaseUrl}?qlt=65&amp;wid=160&amp;hei=160&amp;op_sharpen=1&amp;fmt=jpeg"
						data-sku="${p.ShortSku}" data-sku-input-type="${p.SkuInputType}" width="160" height="160" alt="${p.ProductName}" />
						<span>${formatCurrency(p.FormattedPrice)}</span>
					</a>
				</div>
			`
	).join('');

}

export default getSortPageContent;
