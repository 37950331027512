function mapRecommendedItemToProduct (item) {

	const product = {
		...item,
		DisplayPrice: item.FormattedPrice,
		ProductDetailUrl: item.Url,
		ProductName: item.ProductName,
		...item.CertonaProductModel,
		...item.CertonaProductModel?.SearchCallout,
		CalloutText: item.CertonaProductModel?.SearchCallout?.CallOut
	};

	// Remove non-standard props
	delete product.CertonaProductModel;
	delete product.FormattedPrice;
	delete product.SearchCallout;
	delete product.Url;

	// From SearchCallout
	delete product.CallOut;
	delete product.ShortSKU;

	return product;

}

export default mapRecommendedItemToProduct;
