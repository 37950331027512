function azureApiRequest (data) {

	if (!window.lp.globals.enableDataCapture) {

		return Promise.reject();

	}

	const TRACK_URL = `${window.lp.globals.dataCaptureBaseUrl}`;

	return fetch(TRACK_URL, {
		method: 'post',
		headers: {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	});

}

export default azureApiRequest;
