import { schemes } from '../schemes';

function getDefaultBoxTitleFromScheme (scheme) {

	switch (scheme.toLowerCase()) {

		case schemes.ORDER_CONFIRMATION:
		case schemes.CATEGORY_LANDING:
		case schemes.ORDER_STATUS:
		case schemes.BRAND_PAGE:
		case schemes.SORT_PAGE:
		case schemes.WISHLIST:
		case schemes.PRODUCT:
		case schemes.STYLE:
		case schemes.CART:
		case schemes.TYPE:
			return 'More You May Like';
		case schemes.GF_CATEGORY_LANDING:
		case schemes.GF_HOME:
		case schemes.GF_NO_SEARCH:
		case schemes.GF_SORT_PAGE:
		case schemes.GF_DEFAULT:
		case schemes.GF_PRODUCT:
		case schemes.GF_SEARCH:
			return 'Recently Viewed';
		case schemes.DAILY_SALES:
		case schemes.SIMILAR_2:
		case schemes.SHIPPING:
			return '';
		case schemes.MORE_LIKE_THIS:
		case schemes.SIMILAR:
			return 'Similar Designs';
		case schemes.RELATED:
			return 'Related Items';
		case schemes.NO_SEARCH_SKU:
		case schemes.NO_SEARCH:
			return 'Other Designs You May Like';
		case schemes.LAST_CATEGORY:
			return 'Recommended For You';
		case schemes.CATEGORY_LANDING_02: {

			let categoryName = $(`[data-scheme=${schemes.CATEGORY_LANDING_02}_rr]`).attr('data-category');
			if (categoryName === 'Mirrors Decor') categoryName = 'Mirrors & Décor';
			return `Top Trending ${categoryName}`;

		}
		case schemes.HOME:
			return 'New & Trending Products';
		default:
			return 'More You May Like';

	}

}

export default getDefaultBoxTitleFromScheme;
