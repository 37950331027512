import { displayStoreDirections, initializeMakeMyStoreHandler } from 'features/bopus/utilities';

function init () {

	// For Store Region/Details page
	initializeMakeMyStoreHandler();

	$('#mapDirections').on('click', (e) => {

		e.preventDefault();
		displayStoreDirections();

	});

	$('#mapImage').on('keydown', (e) => {

		// If the user presses the "Enter" key on the keyboard
		if (e.key === 'Enter') {

			document.getElementById('mapDirections')?.click();

		}

	});

}

export default init;
