import cache from './cache';
import getProducts from './getProducts';

function getProductsWithCache (searchUrls) {

	const result = {};
	const uncachedProductUrls = [];

	for (let i = 0; i < searchUrls.length; i++) {

		if (cache.products[searchUrls[i]]) {

			result[searchUrls[i]] = cache.products[searchUrls[i]];

		} else {

			uncachedProductUrls.push(searchUrls[i]);

		}

	}

	if (uncachedProductUrls.length) {

		return getProducts(uncachedProductUrls).then((response) => {

			for (let i = 0; i < uncachedProductUrls.length; i++) {

				result[uncachedProductUrls[i]] = response[uncachedProductUrls[i]];
				cache.products[uncachedProductUrls[i]] = response[uncachedProductUrls[i]];

			}
			return result;

		});

	}

	return new Promise((resolve) => {

		resolve(result);

	});

}

export default getProductsWithCache;
