import { initRecentSearches, capturePreviousSearch } from 'features/search/recentSearches';

import initHospitalitySearch from './hospitalitySearch';
import initSearch from './search';
import initSearchAutocomplete from './searchAutocomplete';
import initHomeSearch from './homeSearch';

function init () {

	// capturePreviousSearch must be loaded statically to record previous search
	capturePreviousSearch();

	if (!window.lp.globals.isHospitality) initRecentSearches('#search', '#searchContainer', '#searchBtn');
	initSearch();
	initHospitalitySearch();
	initSearchAutocomplete();
	initHomeSearch();

}

export default init;
