const localStorageName = 'LP_RecentSearches';
const unvalidatedQueryLocalStorageName = 'UnvalidatedSearchQuery';
const persistentSearchSessionStorageName = 'PersistentSearch';
const persistentSearchTypes = {
	sort: 0,
	pdp: 1
};
const maxRecentSearches = 10;

export {
	localStorageName,
	unvalidatedQueryLocalStorageName,
	persistentSearchSessionStorageName,
	persistentSearchTypes,
	maxRecentSearches
};
