(function () {

	// set antiforgery globally in the header for all ajax requests
	// DO NOT PUT INSIDE DOM READY - must run first
	// make sure window.parent.location.origin === window.location.origin otherwise will result in Cross Origin error
	$.ajaxSetup({
		beforeSend: function (request, settings) {

			if (!/\bget\b/i.test(settings.type)) {

				request.setRequestHeader(
					window.lp.globals.antiForgeryHeaderKey || window.parent.lp.globals.antiForgeryHeaderKey,
					window.lp.globals.antiForgeryToken || window.parent.lp.globals.antiForgeryToken
				);

			}

		}
	});

})();
