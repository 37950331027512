import { formatCurrency } from 'utilities/localization';

function getGenericContent (result, settings) {

	const {
		itemsPerRow, imageSize, withPrice, moreLikeThis, doNotAppend
	} = settings;
	const baseUrl = window.lp.globals.secureSiteURL;
	const productCount = result.Products.length;

	return result.Products.map((p, i) => {

		if (!doNotAppend) {

			window.resx.prodDetailCertonaSkusUsed.push(`${p.ShortSku}|${result.Scheme}`);

		}

		const isProductRR = result.Scheme === 'product_rr' || result.Scheme === 'shipping_rr';
		const openingDiv = isProductRR && i === 0;
		const newDiv = isProductRR && i % itemsPerRow === 0 && i !== productCount - 1 && i !== 0;
		const closingDiv = isProductRR && i === productCount - 1;

		return `
			${openingDiv ? '<div class="flex-row">' : ''}
			${newDiv ? '</div><div class="flex-row">' : ''}
			<div class="moreYouMayLikeItem sortMYMLProdContainer" data-qa-sku-source="${p.ShortSku}" data-sku="${p.ShortSku}">
				<a href="${baseUrl}${p.Url}" data-certonaSku="${p.ShortSku}">
					<img data-sku="${p.ShortSku}"
					data-sku-input-type="${p.SkuInputType}"
					src="${p.ImageBaseUrl}?qlt=55&amp;wid=${imageSize}&amp;hei=${imageSize}&amp;op_sharpen=1&amp;fmt=jpeg"
					width="${imageSize}" height="${imageSize}" alt="${p.ProductName}" />

				${withPrice ? `<span class="${p.IsOnSale ? 'callout' : ''}">${formatCurrency(p.FormattedPrice)}</span>` : ''}
				</a>
				${moreLikeThis ? `<a class="moreLikeThisLink" href="/more-like-this/${p.ShortSku}/">More Like This</a>` : ''}
			</div>
			${closingDiv ? '</div>' : ''}`;

	}).join('');

}

export default getGenericContent;
