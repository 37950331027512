import request from './request';

function updateMyStore (storeNumber) {

	return request({
		options: {
			method: 'put',
			path: `/storeavailability/mystore/${storeNumber}`
		}
	});

}

export default updateMyStore;
