import { setGetRequestParams, setPostRequestParams, setPutRequestParams } from './requestOptions';

function methodRequestHeaders ({ data, params = {}, headers = {} } = {}) {

	switch (params.method) {

		case 'DELETE': {

			return { ...params, ...headers };

		}
		case 'GET': {

			return setGetRequestParams({ params, headers });

		}
		case 'POST': {

			return setPostRequestParams({ data, params, headers });

		}
		case 'PUT': {

			return setPutRequestParams({ data, params, headers });

		}
		default: {

			return { ...params, ...headers };

		}

	}

}

function baseAPIRequest ({
	url, data, params, errorStatusHandlers, abortController, timer, headers = {}
}) {

	const reqHeaders = {
		cache: 'no-cache',
		'Content-Type': 'application/json;charset=UTF-8',
		...headers
	};

	const revisedErrorStatusHandlers = {
		401 () {

			window.top.location.href = `${
				window.lp.globals.secureSiteURL
			}/account/sign-in?ReturnUrl=${encodeURIComponent(window.location.href)}`;
			return 'You have to sign in to perform this action';

		},
		...errorStatusHandlers
	};

	let controller = abortController;
	if (timer > 0 && 'AbortController' in window) {

		if (!controller) {

			controller = new AbortController();

		}

		setTimeout(() => controller.abort(), timer);

	}

	if (data) {

		const keys = Object.keys(data);

		if (params.method === 'GET' && keys.length > 0) {

			const qs = keys
				.map((key) => {

					const encodedKey = encodeURIComponent(key);
					return Array.isArray(data[key])
						? data[key].map((value) => `${encodedKey}=${encodeURIComponent(value)}`).join('&')
						: `${encodedKey}=${encodeURIComponent(data[key])}`;

				})
				.join('&');

			url = `${url}?${qs}`;

		}

	}

	const request = fetch(url, {
		...methodRequestHeaders({ data, params, headers: reqHeaders }),
		// Object property shorthand leads to read-only error on `signal`
		signal: controller ? controller.signal : undefined
	})
		.then((response) => {

			if (!response.ok) {

				if (revisedErrorStatusHandlers && revisedErrorStatusHandlers[response.status]) {

					throw new Error(revisedErrorStatusHandlers[response.status](response));

				}
				throw new Error(response.statusText);

			}
			return response;

		})
		/*
			Pass on just the response JSON for further consumption
		*/
		.then((r) => r.json());

	return request;

}

export default baseAPIRequest;
