import { utagLinkAddToCart } from 'features/tealium';
import { utagLinkAddToCart as utagLinkGa4AddToCart } from 'features/tealium/ga4/ecommerce';
import { getSelectedUtagData } from 'features/tealium/utils';
import { baseAPIRequest } from 'utilities/http';
import { getCookie, deleteCookie } from 'utilities/browser';

type AddToCartParams = {
	arrData: Array<LP.cart.AddToCartItem>;
	successCallback: (response: any) => void;
	errorCallback?: (errors: string, xhr: XMLHttpRequest, response: any) => void;
	includeCartOverview?: boolean;
	rememberLastAddedItem?: boolean;
	sortParams?: {
		SortPath?: string,
		SortPosition?: string,
		SortSku?: string,
		ListType?: string
	};
  }

// TODO: Research whether includeCartOverview can be removed
function addToCart ({
	arrData,
	successCallback,
	errorCallback,
	includeCartOverview,
	rememberLastAddedItem,
	sortParams
}: AddToCartParams) {

	addABTestingData(arrData);

	const data = {
		CartProductAddItemsModel: arrData,
		RememberLastAddedItem: rememberLastAddedItem,
		SortPathParameter: sortParams,
		IncludeTagView: true,
		IncludeCartOverview: true
	};

	// TODO: Remove explicit arguments errorStatusHandlers, abortController, timer when baseAPIRequest is in TS; these are optional
	return baseAPIRequest({
		url: '/api/globalcart/current-cart/items',
		data,
		params: {
			method: 'POST'
		},
		errorStatusHandlers: {},
		abortController: null,
		timer: null
	}).then((response) => {

			if (response.IsSuccess) {

				if (window.utag) {

					const newUtagData = JSON.parse(response.GlobalCartResponseModel.TealiumTagData);
					const indexes = arrData.map((product) => newUtagData.product_sku?.indexOf(product.ShortSku.toUpperCase()));

					const tag = {
						...window.utag_data,
						...getSelectedUtagData(newUtagData, indexes)
					};

					// since cart alredy can have some of the skus then we have to update tag.product_quantity
					tag.product_sku.forEach(
						(sku: string, i: number) => (tag.product_quantity[i] = arrData.find((p) => p.ShortSku.toUpperCase() == sku).Quantity)
					);

					// SortParams should be same for all added skus
					if (arrData[0].SystemOption) {

						sortParams.SortPosition = '';

					}

					if (Array.isArray(tag.sku_input_type)) {

						const arrOfSkuInputType = arrData
							.filter((pi) => {

								if (typeof pi.SkuInputType === 'number') {

									return true;

								}
								return Boolean(pi.SkuInputType);

							})
							.map((pi) => String(pi.SkuInputType));
						tag.sku_input_type = [ ...arrOfSkuInputType ];

					}

					// dont send sortPosition for a system option
					const data = utagLinkAddToCart(tag, sortParams);
					const ga4data = utagLinkGa4AddToCart(tag);

					// Items were added to the cart and we have tag data
					// then track 'add to cart' event and redirect to cart.
					const utagTrackingPromise = new Promise((resolve) => {

						const wasSuccessful = window.utag.link(data, () => resolve('success'));
						const wasSuccessfulGa4 = window.utag.link(ga4data, () => resolve('success'));

						if (wasSuccessful && wasSuccessfulGa4) resolve('success');

						// Tracking is allowed 500ms for successful resolution,
						// then we redirect to the cart regardless.
						setTimeout(() => resolve('success'), 500);

					});

					// finally() not supported in ie11/edge
					utagTrackingPromise.then(() => {

						if (successCallback) {

							successCallback.call(this, response);

						}

					});

				} else if (successCallback) {

					// we cant track since we dont have tags so then just redirect to the cart
					successCallback.call(this, response);

				}

			} else if (errorCallback) {

				errorCallback.call(this, response.CartStatus, this, response);

			}

		})
		.catch((error) => {

			if (errorCallback) {

				errorCallback.call(this, error.CartStatus, this, error);

			}

		});

}

function getSearchProvider () {


	if (
		document.referrer.indexOf('products') > -1
		&& !document.referrer.endsWith('.html')
		&& getCookie('SearchProviderFromSort') != null
	) {

		return getCookie('SearchProviderFromSort');

	}
	deleteCookie('SearchProviderFromSort');
	return '';

}

function addABTestingData (arrData: Array<LP.cart.AddToCartItem>) {

	arrData.forEach((data) => {

		data.MMFilterId = window.utag && window.utag.data.filter_id && window.utag.data.filter_id[0] ? window.utag.data.filter_id[0] : undefined;
		data.ABTestCompositionId = window.utag && window.utag.data.testcomposition_id && window.utag.data.testcomposition_id[0] ? window.utag.data.testcomposition_id[0] : undefined;
		data.SearchProvider = getSearchProvider();

	});

}

export default addToCart;
