import utagLinkGeneric from './utagLinkGeneric';

function utagLinkCart (eventCategory = 'Cart') {

	return ({ eventAction, eventLabel = '', nonInteraction = 0 }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkCart;
