import { getSearchUrl } from 'features/search';

import { persistentSearchTypes } from './config';
import getPersistentSearch from './getPersistentSearch';

const populatePersistentSearch = (searchElementSelector) => {

	const pSearch = getPersistentSearch();
	const url = document.location.href;
	const { isOpenBoxSort, isOnSaleSearch } = window.lp.pageData;

	if (!pSearch || isOpenBoxSort || isOnSaleSearch) {

		return;

	}

	// Populates the search bar with last search
	// Will enter this block on pdp refresh, or when applying filters on search sort page
	if (
		(pSearch.resultType === persistentSearchTypes.sort && url.includes(getSearchUrl(pSearch.query)))
		|| (pSearch.resultType === !persistentSearchTypes.pdp && url === pSearch.url)
	) {

		document.querySelector(searchElementSelector).value = pSearch.query;

	}

};

export default populatePersistentSearch;
