const rule = {
	name: 'monthYearExp',
	method (value, element, params) {

		const isValidMonthYearFormat = $.validator.methods.monthYearFormat.call(this, value, element, params);
		if (isValidMonthYearFormat) {

			const today = new Date();
			today.setHours(0, 0, 0, 0);

			const inputtedValue = value.split('/');

			const dateInput = new Date(inputtedValue[1], inputtedValue[0], 0);

			return dateInput >= today;

		}

		return true; // invalid MM/YYYY format will be handled in the 'monthYearFormat' rule

	},
	errorMessage: () => 'The expiration date entered is already expired.'
};

export default rule;
