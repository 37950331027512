/* eslint-disable no-param-reassign */
import { utagLinkHeader } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import { getSignInSetup } from '../../api';

import initSignInButtonEvents from './initSignInButtonEvents';

const trackHeaderEvent = utagLinkHeader();

function bindEvents (hideDropDown) {

	const orderHistoryLink = document.querySelector('.orderHistoryLink');
	const recentlyViewedLink = document.querySelector('.recentlyViewedLink');

	$('#createAccountLink').on('click', () => {

		trackHeaderEvent({ eventAction: 'click-Create-Account', eventLabel: '' });
		setGenericEvent({ event_name: 'navtop_Create Account_click' });

	});

	if (orderHistoryLink) {

		orderHistoryLink.addEventListener('click', () => {

			trackHeaderEvent({ eventAction: 'click-LoggedOut-Order-History', eventLabel: window.location.pathname });
			setGenericEvent({ event_name: 'navtop_Order History_click' });

		});

	}

	if (recentlyViewedLink) {

		recentlyViewedLink.addEventListener('click', () => {

			trackHeaderEvent({ eventAction: 'click-Recently-Viewed', eventLabel: window.location.pathname });
			setGenericEvent({ event_name: 'navtop_Recently Viewed_click' });

		});

	}

	initSignInButtonEvents(hideDropDown);

}

function initSignInDropDown (container, template, hideDropDown) {

	return getSignInSetup().then((response) => {

		container.innerHTML = template(response);
		bindEvents(hideDropDown);

	});

}

export default initSignInDropDown;
