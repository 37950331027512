function trimFieldValue (element) {

	// Trim element if it is a textarea or an input field that is not a password field
	if (element.tagName === 'TEXTAREA' || (element.tagName === 'INPUT' && element.type !== 'password')) {

		element.value = element.value.trim();

	}

}

export default trimFieldValue;
