function escapeSearch (searchUrl) {

	return searchUrl
		.replace(/-/g, ' ')
		.replace(/@@@@@/g, ':')
		.replace(/@@@/g, '.')
		.replace(/@@/g, '$')
		.replace(/@/g, '-')
		.replace(/,/g, '&')
		.replace(/%3f/g, '?')
		.replace(/%22/g, '"')
		.replace(/%27/g, "'")
		.replace(/%2a/g, '*')
		.replace(/%3e/g, '>')
		.replace(/%3c/g, '<');

}

/**
 * Return decoded search term from url encoded search term
 * @param {string} searchUrl
 * @returns {string}
 */
function decodeUrlSearchTerm (encodedSearch) {

	return escapeSearch(encodedSearch.trim().toLowerCase());

}

export default decodeUrlSearchTerm;
