function showHidePassword (e) {

	const target = e.currentTarget;
	const show = target.textContent === 'show';
	const passwordField = target.parentNode.querySelector(`[type="${show ? 'password' : 'text'}"]`);

	if (passwordField) {

		if (show) {

			passwordField.setAttribute('type', 'text');
			target.textContent = 'hide';
			target.setAttribute('aria-label', 'hide password');

		} else {

			passwordField.setAttribute('type', 'password');
			target.textContent = 'show';
			target.setAttribute('aria-label', 'show password');

		}

	}

}

function lpPasswordToggle (elementContainer) {

	if (!elementContainer) {

		return;

	}

	elementContainer.addEventListener('click', showHidePassword);
	elementContainer.addEventListener('keydown', (e) => {

		if (e.which === 13 && e.target.tagName.toLowerCase() !== 'button') {

			showHidePassword.call(elementContainer, e);
			setTimeout(() => elementContainer.focus(), 0); // IE needs this

		}

	});

}

export default lpPasswordToggle;
