import { updateCounts } from 'features/asset/desktop';

function init () {

	// pages where we don't need to call the asset count API
	const exemptPages = [ '/cart/shipping/', '/cart/billing/' ];

	// only get counts when not in an iframe and not in pages where we don't display asset counts
	const exemptPageFound = exemptPages.filter((page) => window.location.pathname.toLowerCase() === page.toLowerCase());

	if (exemptPageFound.length === 0) {

		updateCounts();

	}

}

export default init;
