import { formatCurrency } from 'utilities/localization';
import { addSaleAttributesToProducts } from 'features/certona/utilities';

function getMoreYouMayLikeContent (result, settings, pdpMoreYouMayLike = 0) {

	const {
		itemsPerRow, imageSize, withPrice, moreLikeThis, doNotAppend, withRatings, withTitle, dynamicImageSize
	} =		settings;
	const baseUrl = window.lp.globals.secureSiteURL;
	const badgeFlag = window.utag_data.user_type.toLowerCase() === 'employee';
	return result.Products.map(addSaleAttributesToProducts)
		.map((p, i) => {

			if (!doNotAppend) {

				window.resx.prodDetailCertonaSkusUsed.push(`${p.ShortSku}|${result.Scheme}`);

			}

			let ratingStr = '';
			const ratings = (Math.round((p?.CertonaProductModel?.TurnToAverageRate + 0.25) * 100.0) / 100.0).toString();
			const ratingsAriaLabel =				!ratings || ratings < 2.5 ? '' : `star rating, ${p.CertonaProductModel.TurnToAverageRate} out of 5`;

			if (ratings > 2.5) {

				const decimal = parseInt(ratings.substring(2, 3), 10);
				ratingStr = `${ratings.substring(0, 1)}-${decimal >= 5 ? '5' : '0'}`;

			}

			return `
			<div class="moreYouMayLikeItem sortMYMLProdContainer sortTrendingProdContainer" data-qa-sku-source="${p.ShortSku}">
				<a class="moreYouLikeLink" href="${baseUrl}${p.Url}${pdpMoreYouMayLike ? '?moreyoumaylike=true' : ''}"
				data-certonaSku="${p.ShortSku}"
				aria-label="${p.ProductName}">
				<div class="moreYouLikeImageWrapper ${p.IsLPProduct && badgeFlag ? 'moreYouLikebadgPosition' : ''}">
					<div class="moreYouLikeBadgeWrapper">
						<img data-sku="${p.ShortSku}"
							data-sku-input-type="${p.SkuInputType}"
							src="${p.ImageBaseUrl}?qlt=55&amp;wid=${imageSize}&amp;hei=${imageSize}&amp;op_sharpen=1&amp;fmt=jpeg"
							width="${imageSize}" height="${imageSize}"
							alt="" />
						${p.IsLPProduct && badgeFlag ? '<div class="moreYouLikeBadge">LP </div>' : ''}
					</div>
					<div class="moreYouLikeProductsName ${withTitle ? '' : 'hidden'}">
						<span>${p.ProductName}</span>
					</div>
				</div>
				${
	withPrice
		? `<span  class="moreLikePrice ${p.saleClassName}">
							${formatCurrency(p.FormattedPrice)}${p.clearanceCalloutText}${p.saleCalloutText}
							</span>
						  	<div class="sortResultSavePrice"></div>
						  	<div class="clear"></div>
						  `
		: ''
}
				<div class="${ratingStr < 2.5 ? 'hideVisibility' : ''} ${withRatings ? '' : 'hidden'}">
					<div class="productSummaryRating">
						<div class="goldReviewStars goldReviewStars--${ratingStr}" ${
	ratingsAriaLabel ? `aria-label="${ratingsAriaLabel}"` : 'aria-hidden'
}>
							<div class="goldReviewStars__star productSummaryRating__star"></div>
							<div class="goldReviewStars__star productSummaryRating__star"></div>
							<div class="goldReviewStars__star productSummaryRating__star"></div>
							<div class="goldReviewStars__star productSummaryRating__star"></div>
							<div class="goldReviewStars__star productSummaryRating__star"></div>
						</div>	
					</div>
				</div>
				</a>
				${moreLikeThis ? `<a class="moreLikeThisLink" href="/more-like-this/${p.ShortSku}/">More Like This</a>` : ''}
			</div>
		`;

		})
		.join('');

}

export default getMoreYouMayLikeContent;
