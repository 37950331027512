import { getCookie } from 'utilities/browser';

function setCertonaTag (response) {

	const trackingInfo = { pageid: response.PageId };
	if (getCookie('RES_TRACKINGID') !== response.TrackingId) {

		trackingInfo.trackingid = response.TrackingId;

	}

	// for checking page
	const bodyID = document.body.getAttribute('id');
	const pageSkus = [];
	const separator = ';';
	window.resx = { ...window.resx, ...trackingInfo };
	const { resx } = window;

	// get skus of items pulled in from certona
	const widgets = (response && response.Widgets) || [];
	const certonaSkus = widgets.reduce((memo, widget) => {

		const scheme = widget.Scheme;
		widget.Products.forEach((product) => {

			const sku = product.ShortSku;
			memo.push(`${sku}|${scheme}`);

		});

		return memo;

	}, []);

	// if on sort page
	if (bodyID === 'bdProdSort') {

		const sortResultContainer = document.querySelectorAll('.sortResultContainer');
		sortResultContainer.forEach((el) => {

			const sku = el.id.replace('sortResultContainer', '');
			pageSkus.push(sku);

		});

		resx.products = pageSkus.join(separator);
		// if on wishlist

	} else if (bodyID === 'bdWishlist' || bodyID === 'bdQL') {

		const dataSku = document.querySelectorAll('[data-sku]');
		dataSku.forEach((el) => {

			const sku = el.getAttribute('data-sku');
			pageSkus.push(sku);

		});
		resx.products = pageSkus.join(separator);

	}

	resx.links = '';
	if (resx.products) {

		resx.links = resx.products;

	}
	if (resx.coordProducts) {

		if (resx.links) {

			resx.links += separator + resx.coordProducts;

		} else {

			resx.links = resx.coordProducts;

		}

	}
	if (certonaSkus.length || resx.prodDetailCertonaSkusUsed.length) {

		if (resx.links) {

			resx.links += separator + certonaSkus.concat(resx.prodDetailCertonaSkusUsed).join(separator);

		} else {

			resx.links = certonaSkus.concat(resx.prodDetailCertonaSkusUsed).join(separator);

		}

	}

	resx.links = resx.links.replace(/,/g, separator);

}

export default setCertonaTag;
