import {
	addClassDuringActivity,
	setButtonStateDuringActivity,
	initBlockEnterKeyForDisabledButton
} from 'utilities/dom';
import { lpPasswordToggle } from 'ui/password-toggle';
import { utagLinkHeader, utagLinkOrderHistory } from 'features/tealium';
import { formToJSON } from 'utilities/form';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import { authenticateUser, getSignInSetup, sendActivationEmail } from '../../api';
import getSignInErrorMessage from '../../getSignInErrorMessage';

const trackHeaderEvent = utagLinkHeader();
const trackOrderHistoryEvent = utagLinkOrderHistory();

function initSignInForm (container, template, hasFormValidation, redirectOnSucess = true) {

	return getSignInSetup().then((response) => {

		// Parsing it through DOMParser since innerHTML removes the <form> tag in container.innerHTML = template(response, hasFormValidation);
		const tempDoc = new DOMParser().parseFromString(template(response, hasFormValidation), 'text/html');
		container.innerHTML = ''; // to remove the loading indicator
		tempDoc.body.childNodes.forEach((ele) => container.appendChild(ele));

		bindEvents(container, hasFormValidation, redirectOnSucess);

	});

}

function bindEvents (container, hasFormValidation, redirectOnSucess) {

	const form = container.querySelector('.signInForm');
	const message = form.querySelector('.signInMessage');
	const error = form.querySelector('.signInError');
	const signInBtn = form.querySelector('.signInBtn');
	const username = form.querySelector('#UserName') || form.querySelector('#UserNameModal');
	const password = form.querySelector('#Password') || form.querySelector('#PasswordModal');
	const passwordToggleField = form.querySelector('.toggleRevealPassword');
	const continueShopping = document.getElementById('continueShopping');
	const createAccount = document.getElementById('createAccount');
	const forgotPassword = document.getElementById('forgotPassword');
	const rememberMeCheckbox = document.getElementById('KeepLoggedInModal');
	const accountFacebookConnectBtn = document.getElementById('accountFacebookConnectBtn');

	// loading button
	initBlockEnterKeyForDisabledButton(signInBtn);

	if (createAccount) {

		createAccount.addEventListener('click', (e) => {

			if (window.location.pathname.indexOf('/order-history/') > 0) {

				trackOrderHistoryEvent({ eventAction: '	click-Create-Account', eventLabel: '' });

			} else {

				trackHeaderEvent({ eventAction: 'click-Create-Account-Modal', eventLabel: '' });
				setGenericEvent({ event_name: 'login_create_account_click' });

			}

		});

	}

	if (accountFacebookConnectBtn) {

		accountFacebookConnectBtn.addEventListener('click', () => {

			trackHeaderEvent({ eventAction: 'click-Account-Facebook', eventLabel: 'N/A' });
			setGenericEvent({ event_name: 'login_facebook_click' });

		});

	}

	if (forgotPassword) {

		forgotPassword.addEventListener('click', (e) => {

			trackHeaderEvent({ eventAction: 'click-Account-Password', eventLabel: '' });
			setGenericEvent({ event_name: 'login_forgotpass_click' });

		});

	}

	if (rememberMeCheckbox) {

		rememberMeCheckbox.addEventListener('change', (event) => {

			if (event.currentTarget.checked) {

				setGenericEvent({ event_name: 'login_remember_check' });

			}

		});

	}

	const processResponse = (response) => {

		if (response.RedirectUrl) {

			if (redirectOnSucess) {

				window.location.href = response.RedirectUrl;
				return;

			}

			// reload page on successful authentication
			window.location.reload();

		}

		message.innerHTML = response.SignInMessage || '';
		const signInErrorMessage = getSignInErrorMessage(response);
		error.innerHTML = signInErrorMessage;
		trackHeaderEvent({ eventAction: 'Error-Login', eventLabel: signInErrorMessage });
		error.classList.remove('hidden');

	};
	const resetMessages = () => {

		message.innerHTML = '';
		error.innerHTML = '';
		error.classList.add('hidden');

	};
	const onkeydown = (e) => {

		if (e.which !== 13) {

			return true;

		}

		const hasUsername = username.value.trim() !== '';
		const hasPassword = password.value.trim() !== '';

		if (hasFormValidation) {

			if (hasUsername && hasPassword) {

				signInBtn.click();

			}

		} else {

			signInBtn.click();

		}

	};

	if (hasFormValidation) {

		window.signInFormValidator = window.initFormValidation(true, form);

	}

	signInBtn.addEventListener('click', (e) => {

		e.preventDefault();

		if (window.location.pathname.indexOf('/order-history/') > 0) {

			trackOrderHistoryEvent({ eventAction: 'click-Sign-In', eventLabel: '' });

		} else {

			trackHeaderEvent({ eventAction: 'click-Account-Sign-In-Modal', eventLabel: window.location.pathname });
			setGenericEvent({ event_name: 'login' });

		}

		resetMessages();

		if (hasFormValidation && !$(form).valid()) {

			form?.querySelector('input.error')?.focus();
			return false;

		}

		const data = formToJSON(form);
		const request = authenticateUser(data).then(processResponse);

		setButtonStateDuringActivity(e.target, request);

	});

	username.addEventListener('keydown', onkeydown);
	password.addEventListener('keydown', onkeydown);

	error.addEventListener('click', (e) => {

		e.preventDefault();

		// Do event delegation because Resend Activation Email link is dynamically generated after form is rendered
		if (e.target.id === 'resendActivationEmail') {

			const data = formToJSON(form, 'Password');
			const request = sendActivationEmail(data).then(processResponse);

			addClassDuringActivity(e.target, 'loading noPointerEvents disabled', request);

		}

	});

	if (passwordToggleField) {

		lpPasswordToggle(passwordToggleField);

	}

	if (continueShopping) {

		continueShopping.addEventListener('click', () => {

			document.getElementById('lpModalClose').click();

		});

	}

}

export default initSignInForm;
