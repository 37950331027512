import utagLinkGeneric from './utagLinkGeneric';

function utagLinkEasyAskSearch (eventCategory = 'Nav-Search-EA') {

	return ({ eventAction, eventLabel = '', nonInteraction = 0 }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkEasyAskSearch;
