import { templateAccountDropDown, templateSignInForm } from 'features/account/signInForm';
import { initSignInDropDown, initSignInForm } from 'features/account/signInForm/template/desktop';

function init () {

	const isIPad = window.isIpad();
	const $signInContainer = $('.signInContainer');
	const $signInFormContainer = $('.signInFormContainer');
	const $hdrSignIn = $('#hdrSignIn');
	const $headerContainer = $('#lpHeader-content');
	let isSignInDropdownOpen = false;

	function initializeSignInForm () {

		if ($signInFormContainer.find('.signInForm').length === 0 && !$signInFormContainer.data('loading')) {

			$signInFormContainer.data('loading', true);

			const hasFormValidation = true;
			const resetLoading = () => {

				delete $signInFormContainer.removeData('loading');

			};

			initSignInForm($signInFormContainer.get(0), templateSignInForm, hasFormValidation, false)
				.then(resetLoading) // use finally() when it becomes widely supported
				.catch(resetLoading);

		}

	}

	initializeSignInForm();

	function hideSignInDropdown () {

		$signInContainer.removeClass('isOpened');
		$hdrSignIn.removeClass('isActive');
		isSignInDropdownOpen = false;

	}

	function showSignInDropdown () {

		if ($signInContainer.find('.accountDropDown').length === 0) {

			initSignInDropDown($signInContainer.get(0), templateAccountDropDown, hideSignInDropdown);

		}

		isSignInDropdownOpen = true;
		$signInContainer.addClass('isOpened');
		$hdrSignIn.addClass('isActive');

	}

	if (isIPad) {

		$signInContainer.addClass('isIPad');
		$('.headerDropDowns')
			.on('over', (e) => {

				// eslint-disable-next-line no-unused-expressions
				e.target.id === 'pnlLoggedOut' ? showSignInDropdown() : hideSignInDropdown();

			})
			.on('out', hideSignInDropdown);

	} else {

		$signInContainer.on('mouseout', (e) => {

			hideSignInDropdown();

		});

		// hide account popup if other portal links on hovered
		$('.hdrPortal-portalLink').on('mouseover focus', function () {

			const isAccountPopupLink = $(this).find('#hdrSignIn').length > 0;

			// if we hover or click on account sign in
			if (isAccountPopupLink) {

				showSignInDropdown();
				return;

			}

			hideSignInDropdown();

		});

		$('.hdrPortal-portalLink').on('keydown', function (e) {

			if (e.key === 'Enter' || e.type === 'click') {

				const isAccountPopupLink = $(this).find('#hdrSignIn').length > 0;

				// if we hover or click on account sign in
				if (isAccountPopupLink) {

					showSignInDropdown();

				}

			}

		});

	}

}

export default init;
