import { doSearch } from 'features/search';

function init () {

	// On Click of Search Button
	document.getElementById('searchBtn').addEventListener('click', (e) => {

		e.preventDefault();

		const searchMode = $('#hospitalityMaskSearchMode option:selected').text();
		const searchTextTerm = document.getElementById('search');
		const query = searchTextTerm.value.trim();

		if (searchMode === 'SKU') {

			const strSearchMaskDep = $('#searchMaskDep').val().trim();
			const strSearchMaskBase = $('#searchMaskBase').val().trim();
			const strSearchMaskFinish = $('#searchMaskFinish').val().trim();

			let strMashSearchUrl = `https://${window.location.host}/products/`;
			if (strSearchMaskDep !== '') {

				strMashSearchUrl += `hmdep_${strSearchMaskDep}/`;

			}
			if (strSearchMaskBase !== '') {

				strMashSearchUrl += `hmbase_${strSearchMaskBase}/`;

			}
			if (strSearchMaskFinish !== '') {

				strMashSearchUrl += `hmfinish_${strSearchMaskFinish}/`;

			}

			window.location = strMashSearchUrl;

		} else {

			doSearch(query);

		}

	});

}

export default init;
