import { doSearch } from 'features/search';
import { utagLinkGeneric } from 'features/tealium';

function init () {

	const searchBtnHomePage = document.getElementById('searchBtnHomePage');
	const searchTextTerm = document.getElementById('searchHomePage');
	if (searchBtnHomePage && searchTextTerm) {

		searchBtnHomePage.addEventListener('click', (e) => {

			e.preventDefault();
			searchHomeQuery();

		});

		searchTextTerm.addEventListener('keyup', (event) => {

			if (event.keyCode === 13) {

				event.preventDefault();
				searchHomeQuery();

			}

		});

	}

	const searchHomeQuery = () => {

		const query = searchTextTerm.value.trim();
		searchGAEvent(query);
		doSearch(query);

	};
	const searchGAEvent = (value) => {

		utagLinkGeneric({
			eventCategory: 'Homepage',
			eventAction: 'Content-Search-Submit',
			eventLabel: value
		});


	};

}

export default init;
