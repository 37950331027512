// call api to get assets for cart, wishlist, and rooms
function getAssetCounts (isCustomerConnected) {

	return fetch(`/api/asset/count/?isConnected=${isCustomerConnected}`, {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json; charset=utf-8'
		},
		credentials: 'same-origin',
		method: 'GET',
		cache: 'no-cache'
	});

}

export default getAssetCounts;
