import { apiMessages } from 'ui/messages';

/**
 * Show a generic error messages in a modal or in the specified container element.
 * @param params Object containing:
 *		errors - string or array of error messages
 *		title - modal header title. Defaults to 'Error'.
 *		showOnModal - flag to indicate if error should be displayed in a modal or not. Defaults to true.
 *		modal - lpModal options
 *		fromModal - flag to indicate if modal is already present to display the error message in the current modal
 *					and hide the existing content
 *		$el - jQuery object reference of the container to display error message at if showOnModal is false.
 */
const showErrorMessage = (params) => {

	let errors = [];
	const defaultOptions = {
		$el: $(),
		title: 'Error',
		showOnModal: true,
		fromModal: false,
		errors: [],
		modal: {
			onInit: true,
			width: 560,
			height: 100,
			appendContentToForm: false
		}
	};
	const options = $.extend(true, {}, defaultOptions, params);

	if (options.errors instanceof Array) {

		if (options.errors.length > 0) {

			errors = options.errors;

		} else {

			errors[0] = apiMessages.error;

		}

	} else {

		errors[0] = options.errors || apiMessages.error;

	}

	const errorMessage = `<p>${errors.join('</p><p>')}</p>`;

	if (options.showOnModal) {

		const genericErrorModalId = 'genericErrorModal';

		const errorHtml = `<div id="${genericErrorModalId}" class="modalError">
			${options.title ? `<h4>${options.title}</h4>` : ''}
			${errorMessage}
		</div>`;

		if (options.fromModal) {

			const $modalContent = window.parent.$('#lpModalContent');
			$modalContent.children().hide();
			window.parent.$('body').lpModalResize({
				width: options.modal.width,
				height: options.modal.height
			});

			$modalContent.append(errorHtml).find(`#${genericErrorModalId}`).show();

		} else {

			const $genericErrorModal = $(`#${genericErrorModalId}`);
			if ($genericErrorModal.length) {

				$genericErrorModal.replaceWith(errorHtml);

			} else {

				$('body').append(errorHtml);

			}

			options.modal.content = $(`#${genericErrorModalId}`);
			$('body').lpModal(options.modal);

		}

	} else {

		options.$el.html(errorMessage);

	}

};

export default showErrorMessage;
