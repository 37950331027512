import topSearchContent from './topSearchContent';
import topCategorySearch from './topCategorySearch';

function renderRecentSearches (recentSearches) {

	const { SearchTerm: categorySearchTerm, Suggestions: categorySuggestions } =		lp.certona.searchSuggestionConfiguration || {};

	if (window.lp.globals.isHospitality) return '';

	return `
		<div class="ie11-wrapper recentsearches hidden">
			<div class="searchdropdown-root">
				<div class="searchdropdown-root__suggestions">
				${`<div class="searchdropdown-root__title ${recentSearches.length > 0 ? '' : 'hidden'}" > Your recent searches</div>
					<div class="searchSuggestionCount sr-only ${recentSearches.length > 0 ? '' : 'hidden'}" aria-live="assertive">
					${recentSearches.length} suggestions are available, use up and down arrow to navigate them.
					</div>
					${recentSearches
		.map(
			(item) => `
						<div class="searchdropdown-root__suggestion-wrapper">
							<div class="searchdropdown-root__suggestion recentSearchesItem">
								${window.decodeURIComponent(item)}
							</div>
						</div>`
		)
		.join('')}`}
					<div class="searchdropdown-root__clear ${recentSearches.length > 0 ? '' : 'hidden'}">Clear search history</div>
					${
	categorySuggestions?.length
		? topCategorySearch(categorySuggestions, categorySearchTerm, recentSearches.length)
		: topSearchContent(recentSearches.length)
}
				</div>
			</div>
		</div>
	`;

}

export default renderRecentSearches;
