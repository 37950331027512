import { addClassDuringActivity } from 'utilities/dom';

import initRateUsModal from './initRateUsModal';

const attachRateUsHandler = (el, params = {}, callback) => {

	if (!el) {

		return;

	}

	const footerRateUsLink = document.getElementById('footer_rate_us');

	el.addEventListener('click', (e) => {

		e.preventDefault();

		if (footerRateUsLink) {

			footerRateUsLink.setAttribute('aria-controls', 'rateUsPage');

		}

		const element = e.currentTarget;

		if (element.classList.contains('noKeyboardEvents')) {

			return false;

		}

		if (callback) {

			callback();

		}

		const request = initRateUsModal(params);
		addClassDuringActivity(element, 'noPointerEvents noKeyboardEvents', request);

	});

};

export default attachRateUsHandler;
