function calloutHelper (calloutData) {

	let html = '';

	if (!calloutData) {

		return html;

	}

	if (calloutData.SoldOut) {

		return '<div class="sortCallout2">Sold<br>Out</div>';

	}

	if (calloutData.CallOut && !calloutData.IsDecrementable) {

		let callOutText = '';
		if (calloutData.CallOut.indexOf('16') > -1) {

			callOutText = '<strong>16+</strong> Colors';

		} else if (calloutData.CallOut.indexOf('100') > -1) {

			callOutText = '<strong>100+</strong> Colors';

		} else if (calloutData.CallOut.toLowerCase().indexOf('more') > -1) {

			callOutText = 'More Options';

		}

		html += `<div class="sortCallout3">${callOutText || calloutData.CallOut}</div>`;

	}

	if (calloutData.IsDecrementable) {

		if (calloutData.ShowInventory) {

			html += `<div class="sortCallout${calloutData.IsDecrementable ? '5' : '6'}">${
				calloutData.AdjustedInventory
			} Left</div>`;

		}
		html += '<div class="sortCallout4">Daily<br>Sale</div>';

	}

	return html;

}

export default calloutHelper;
