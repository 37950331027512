import { getNearByStores, getDefaultStore } from 'features/bopus/api';
import { updateMyStoreAndNearbyStoresView, initializeMakeMyStoreHandler } from 'features/bopus/utilities';
import { getCurrentPosition } from 'utilities/geolocation';
import { getRetailStoreInfoByLatLng } from 'features/storeAvailability/api';
import { setGeoLocationCookie } from 'features/geolocation';

async function getStoresByGeoLocation (
	{ renderMyDefaultStore, renderNearbyStores, initializeMakeMyStore },
	doneCallbackFn = () => {}
) {

	const geoSuccess = async function (position) {

		const locationLongLat = {
			longitude: position.coords.longitude,
			latitude: position.coords.latitude
		};

		setGeoLocationCookie(locationLongLat);
		try {

			const [ storesByLatLong, defaultStore ] = await Promise.all([
				getRetailStoreInfoByLatLng(position),
				getDefaultStore()
			]);

			const formattedResponse = storesByLatLong.map((store) => ({
				City: store.City,
				IsDefaultStore: store.IsSelected,
				StoreHours: store.StoreHours,
				IsOpenNow: store.StoreHours.findIndex((day) => day.IsOpenNow) > -1,
				DetailUrl: `/stores/${store.Region.toLowerCase()}/${store.City.toLowerCase()}-${store.Zip}`,
				StoreImageUrl: `${store.StoreImageUrl}`,
				EndTime: store.StoreHours.find((day) => day.IsCurrentDay).EndTime,
				openTime: store.StoreHours.find((day) => day.IsCurrentDay).StartTime,
				IsTemporarilyClosed: store.IsTemporarilyClosed,
				Phone: store.Phone,
				State: store.State,
				StoreName: store.StoreName,
				StoreNumber: store.LocationNumber,
				StoreSms: store.StoreSms,
				StreetAddress: store.Address,
				ZipCode: store.Zip
			}));
			const defaultStoreIndex = formattedResponse.findIndex(
				(store) => store.StoreNumber === defaultStore.ApiResult.StoreNumber.toString()
			);
			if (defaultStoreIndex >= 0) {

				formattedResponse[defaultStoreIndex].IsDefaultStore = true;

			} else {

				const myStore = { ...defaultStore.ApiResult };
				myStore.IsDefaultStore = true;
				formattedResponse.push(myStore);

			}
			updateMyStoreAndNearbyStoresView({
				stores: formattedResponse,
				renderMyDefaultStore,
				renderNearbyStores
			});
			if (initializeMakeMyStore) {

				initializeMakeMyStoreHandler({ renderMyDefaultStore, renderNearbyStores, useGeoLocation: true });

			}

		} catch (error) {

			initDefaultStores({ renderMyDefaultStore, renderNearbyStores, initializeMakeMyStore });

			console.log('error getting stores:', error);

		}

	};

	const geoError = function (error) {

		initDefaultStores({ renderMyDefaultStore, renderNearbyStores, initializeMakeMyStore });

		console.log(`Error getting geolocation: error.code:${error.code} error.message:${error.message}`);

	};

	getCurrentPosition().then(geoSuccess).catch(geoError).finally(doneCallbackFn);

}

function initDefaultStores ({ renderMyDefaultStore, renderNearbyStores, initializeMakeMyStore }) {

	getNearByStores().then((response) => {

		updateMyStoreAndNearbyStoresView({
			stores: response.ApiResult,
			renderMyDefaultStore,
			renderNearbyStores
		});

	});

	if (initializeMakeMyStore) {

		initializeMakeMyStoreHandler({ renderMyDefaultStore, renderNearbyStores });

	}

}
export default getStoresByGeoLocation;
