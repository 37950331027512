function renderTotalSavedPortfolioCount (count) {

	const element = window.top.document.getElementById('savedPortfolio-totalSaved--totalSavedCount');
	const wrapper = window.top.document.getElementById('savedPortfolio-totalSaved');

	if (count === 0) {

		element.textContent = '';
		wrapper.classList.remove('maxSaved');
		wrapper.classList.add('zeroSaved');

	} else if (count > 99) {

		element.textContent = '99+';
		wrapper.classList.remove('zeroSaved');
		wrapper.classList.add('maxSaved');

	} else {

		element.textContent = count;
		wrapper.classList.remove('zeroSaved', 'maxSaved');

	}

}

export default renderTotalSavedPortfolioCount;
