function getSignInErrorMessage (data) {

	if (data && data.Error) {

		if (data.HasActivationRequiredError && !data.ResendActivationEmail) {

			return `${data.Error}
				<button type="button" id="resendActivationEmail" class="anchorLink">resend activation email</button>`;

		}

		return data.Error;

	}

	return '';

}

export default getSignInErrorMessage;
