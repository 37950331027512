function init () {

	let assetsAreShared;

	if ($('#stopAssetShareLink').length > 0) {

		$('#stopAssetShareLink').lpTooltip({
			arrow: true,
			arrowBorderWidth: 1,
			arrowSize: 15,
			borderRadius: '2px',
			boxShadow: {
				enabled: true
			},
			evtType: 'click',
			horzAlign: 'center',
			offsetY: 16,
			showCloseBtn: true,
			tooltip: $('#stopAssetSharingTooltip'),
			closeEvt () {

				if (assetsAreShared === false) {

					window.location.reload();

				}

			}
		});

		$('#stopAssetSharingTooltip').on('click', '.cancel, .dismiss', (e) => {

			e.stopPropagation();
			// have click of 'cancel' link trigger click of 'close' link
			$(e.target)
				.parents('#stopAssetSharingTooltip')
				.find('.close')
				.trigger('click');

		});

		$('#clearData').click((e) => {

			e.stopPropagation();

			const url = '/api/connectascustomer/usersessionmanager/operation/unshareassets/';
			$.ajax({
				type: 'get',
				url,
				success () {

					$(e.target)
						.parents('#message')
						.html('<h5><strong>Thank You</strong></h5><p>Your Lamps Plus data has been cleared from this device.</p><p class="tac"><a class="calloutBtn alt1 small dismiss">Dismiss</a></p>');
					// flag to trigger tooltip onClose method.
					assetsAreShared = false;

				},
				error () {

					$(e.target)
						.parents('#message')
						.html('<h5><strong>Error</strong></h5><p>Sorry, we\'ve encountered an error! Please try your request again.</p><p class="tac"><a class="calloutBtn alt1 small cancel">Close</p>');

				}
			});

		});

	}

}

export default init;
