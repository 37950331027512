import getSignInErrorMessage from '../../getSignInErrorMessage';

// TODO: Convert .signInForm div to form tag after ALL ASPX pages are converted into MVC
// DO NOT PASS true for the hasFormValidation until ALL ASPX pages are converted into MVC
function templateAccountDropDown (data) {

	return `	
		<div class="accountDropDown">
				<ul class="accountQuickLinks unstyledList">
					${
	data.RegistrationAllowed
		? '<li><a id="createAccountLink" href="/account/create/" data-has-header-tracking="true"><span class="lpIcon-createaccount"></span>Create Account</a></li>'
		: ''
}
					<li><a class="orderHistoryLink" href="/account/order-history/"><span class="lpIcon-orderhistory"></span>Order History</a></li>
					${
	!window.lp.globals.isKiosk
		? '<li><a class="recentlyViewedLink" href="/recently-viewed/"><span class="lpIcon-recentlyviewed"></span>Recently Viewed</a></li>'
		: ''
}
				</ul>	
				<button type="submit" id="signInButton" class="calloutBtn alt1">Sign In</button>
			</div>`;

}

export default templateAccountDropDown;
