import events from './events';
import trackEvent from './trackEvent';

const trackingMiddleware = (store) => (next) => (action) => {

	const event = events[action.type];
	if (event) {

		const data = event(action.payload, store.getState());
		trackEvent(data);

	}

	next(action);

};

export default trackingMiddleware;
