type ViewPortSizeType = {
	height: number;
	width: number;
}

function getViewportSize (): ViewPortSizeType {

	return {
		height: window.innerHeight,
		width: window.innerWidth
	};

}

export default getViewportSize;
