import Glider from 'glider-js';
import 'glider-js/glider.min.css';
import './glider.scss';

const LPGlider = Glider;

// Override original buildDots implementation
LPGlider.prototype.buildDots = function () {

	const _ = this;
	if (Array.from(document.querySelector(_.opt.dots)?.classList || [])?.indexOf('gliderDotsLoaded') >= 0) {

		return;

	}

	if (!_.opt.dots) {

		if (_.dots) _.dots.innerHTML = '';
		return;

	}

	if (typeof _.opt.dots === 'string') {

		_.dots = document.querySelector(_.opt.dots);

	} else _.dots = _.opt.dots;
	if (!_.dots) return;

	_.dots.innerHTML = '';
	_.dots.setAttribute('role', 'tablist');
	_.dots.classList.add('glider-dots');

	// Adding a class to check next time if its already loaded, on scrolling for iPhone/Android phones it reconstructs the UI which causes the issue of flickering dots LP-56962.
	_.dots.classList.add('gliderDotsLoaded');

	const total = Math.ceil(_.slides.length / _.opt.slidesToShow);
	for (let i = 0; i < total; ++i) {

		const dot = document.createElement('button');
		dot.dataset.index = i;
		dot.setAttribute('aria-label', `Show slide ${i + 1} of ${total}`);
		dot.setAttribute('role', 'tab');
		dot.className = 'glider-dot';
		_.event(dot, 'add', {
			click: _.scrollItem.bind(_, i, true)
		});

		const li = document.createElement('li');
		li.setAttribute('role', 'presentation');
		li.className = i ? '' : 'active'; // default to first slide
		li.appendChild(dot);
		_.dots.appendChild(li);

	}

};

// Custom function to set active slide without animation
LPGlider.prototype.setActiveSlide = function (index) {

	const gliderInstance = this;

	const { ele, opt } = gliderInstance;
	const { duration } = opt;

	gliderInstance.setOption({ duration: 0 });
	ele.classList.add('glider--noScroll');

	gliderInstance.scrollItem(index, false);

	gliderInstance.setOption({ duration });

	window.requestAnimationFrame(() => ele.classList.remove('glider--noScroll'));

};

const originalDestroy = Glider.prototype.destroy;
LPGlider.prototype.destroy = function () {

	const gliderInstance = this;

	// Clean up arrows
	if (gliderInstance.arrows) {

		const arrows = Object.values(gliderInstance.arrows);
		arrows.forEach((arrow) => {

			// Remove the event listener
			arrow.removeEventListener('click', arrow._func);
			// Remove the added property _func
			delete arrow._func;

		});

	}

	return originalDestroy.call(gliderInstance);

};

export default LPGlider;
