// Validate against PO Box Address (LP-2666)

const rule = {
	name: 'isNotPOBox',
	method: (value, element, params) => {

		const regex = /^p.?\s?o.?\s*box.*|^(p.?\s?o.?\s?|pob|box)\s?#?[0-9]+|^post office box/i;
		return !regex.test(value);

	},
	errorMessage: (params, element) => 'We are unable to ship to P.O. Box addresses.'
};

export default rule;
