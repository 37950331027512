// Validate email for a TLD (Top Level Domain) of .com, .net, .jp, etc.

const rule = {
	name: 'emailRequireDomainSuffix',
	method: (value, element, params) => {

		const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
		return value.length < 1 || regex.test(value.trim());

	},
	errorMessage: (params, element) => 'Please enter a valid email address.'
};

export default rule;
