/* eslint-disable max-len */
/**
 * Error messages for Cart based on GlobalCartOperationStatus enum
 * Not all of these messages will actually be displayed under normal conditions but they're added here for completeness' sake.
 * TODO: Error message implementation should be refactored in the future to avoid having to define the cart error messages on frontend.
 * TODO: Mobile and Desktop error messages differ, why?
 */

const errorMessages = [
	"Sorry, we've encountered an error! Please try your request again.",
	'', // Success - this is not used at all for obvious reason
	'Sorry! You have reached the maximum number of items that can be added to cart at one time. Please remove some from your list and try again.',
	'Sorry! You are not authorized to perform this operation.',
	'Sorry! Item is out of stock.',
	'Sorry! Discount is invalid.',
	'Sorry! There is an error in quantity.',
	'Sorry! Zip code is invalid.',
	// Calculator error - this is not used at all as actual errors in CalculatorResults are used
	"Sorry, we've encountered an error! Please try your request again.",
	'Sorry! We could not find the item from the cart.',
	'Sorry! We could not find the cart.',
	'Sorry! Your session has expired, please refresh the page.',
	'This operation requires manager approval. Please enter manager login information.',
	'Sorry! Manager login information is incorrect.',
	'Sorry! There is no item to import.',
	// Calculator warning - this is not used at all as actual warnings in CalculatorResults are used
	"Sorry, we've encountered an error! Please try your request again.",
	'Sorry! The coupon code entered does not exist. Please check the promo code and try again.',
	'Sorry! The coupon code entered has expired and is no longer valid. Please check the promo code and try again.',
	'Sorry! You have no permission to perform that operation',
	'Sorry! Multiple shipping address feature is not supported at this time.',
	'Sorry! Shipping option is invalid.',
	'Sorry! The length of Order Id exceeds the maximum limit.',
	'Sorry! You have reached the maximum number of characters that can be used for comments.',
	'Sorry! You have reached the maximum number of characters that can be used for Cart name.',
	'Sorry! In-Store Pickup is not allowed',
	'This operation requires vendor approval.',
	'Sorry! Discount Reason is required.',
	'Sorry! Store number is invalid.',
	'Sorry! Delivery is not available for the specified shipping address.',
	'Sorry! Order number is not found.',
	'You cannot lower the quantity of recommended bulbs. If you would like to lower the quantity of a recommended bulb, please remove the recommended bulb from the cart and then re-add the bulb yourself at the desired quantity.',
	'Sorry! This product is currently unavailable.',
	'Quantity is reduced due to availability',
	'Sorry! Country code provided is not valid.',
	'Cart has been modified due to availability.',
	'No availability update.',
	'Sorry! Unable to import cart because source cart does not exist.',
	'First Name and Last Name fields do not accept special characters, as well as commas or period marks.',
	'Sorry! Commission employee number is invalid.'
];

export default errorMessages;
