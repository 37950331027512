/**
 * This asset map is to connect a page level name to all the webpack assets for
 * that page to be prefetched. Use prefetchWebpackAssetSet with a key from this
 * map to call at the page level.
 */
const assets = {
	'desktop/product': [
		'desktop/product',
		'desktop/Scripts/product',
		'desktop/Scripts/productNotFound',
		'desktop/Content/product',
		'desktop/product-productQuestionsAndAnswers',
		'desktop/product-productReviews'
	],
	'mobile/product': [
		'mobile/product',
		'mobile/Scripts/product',
		'mobile/Scripts/productNotFound',
		'mobile/Content/product',
		'mobile/product-productQuestionsAndAnswers',
		'mobile/product-productReviews'
	],
	'desktop/sort': [ 'desktop/sort', 'desktop/Content/sort' ],
	'mobile/sort': [ 'mobile/sort', 'mobile/Content/sort' ],
	'desktop/cart/overview': [ 'desktop/Scripts/cart', 'desktop/cart/overview', 'desktop/Content/cartOverview' ],
	'mobile/cart/overview': [ 'mobile/Scripts/cart', 'mobile/cart/overview', 'mobile/Content/cartOverview' ],
	'desktop/cart/shipping': [ 'desktop/cart/shipping', 'desktop/Content/cartShipping' ],
	'mobile/cart/shipping': [ 'mobile/cart/shipping', 'mobile/Content/cartShipping' ],
	'desktop/cart/billing': [ 'desktop/cart/billing', 'desktop/Content/cartBilling' ],
	'mobile/cart/billing': [ 'mobile/cart/billing', 'mobile/Content/cartBilling' ]
};

export default assets;
