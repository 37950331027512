import { formatCurrency } from 'utilities/localization';
import { addSaleAttributesToProducts } from 'features/certona/utilities';

const { page_type } = window.utag_data;

function getHomeTrendingProductsContent(result, settings) {

    const baseUrl = window.lp.globals.secureSiteURL;

    if (result.Scheme === 'home_rr') {

        document.querySelector('.homeTrendingProducts')?.classList.remove('hidden');

    }

    return result.Products.map(addSaleAttributesToProducts)
        .map((p) => {

            let ratingStr = null;
            let ratingsAriaLabel;

            if (page_type === 'home' && result.Scheme === 'home_rr') {

                const ratings = (
                    Math.round((p.CertonaProductModel.TurnToAverageRate + 0.25) * 100.0) / 100.0
                ).toString();
                if (ratings > 2.5) {

                    const decimal = parseInt(ratings.substring(2, 3), 10);
                    ratingStr = `${ratings.substring(0, 1)}-${decimal >= 5 ? '5' : '0'}`;
                    ratingsAriaLabel = !ratings || ratings < 2.5
                        ? ''
                        : `star rating, ${p.CertonaProductModel.TurnToAverageRate} out of 5`;

                }

            }

            const calloutClass = p.IsOnSale ? 'callout' : '';
            window.resx.prodDetailCertonaSkusUsed.push(`${p.ShortSku}|${result.Scheme}`);
            return `
		<div class="homeTrendingProductsItem" data-qa-sku-source="${p.SkuSource}">
			<a href="${baseUrl}${p.Url}"
			data-certonaSku="${p.ShortSku}"
			aria-label="${p.ProductName}"
			>
				<img
					src="${p.ImageBaseUrl}?qlt=55&amp;wid=${settings.imageSize}&amp;hei=${settings.imageSize
                }&amp;op_sharpen=1&amp;fmt=jpeg"
					data-sku-input-type="${p.SkuInputType}"
					width="${settings.imageSize}"
					height="${settings.imageSize}"
					alt="${p.ProductName}"
					data-sku="${p.ShortSku}"
				/>
				${(page_type === 'home' && result.Scheme === 'home_rr') ? `
					<div class="MLTItemsContent">
						${settings.withTitle ? `<span class="productTitle">${p.ProductName}</span>` : ''}
						${settings.withPrice
                        ? `<div class="productPrice ${calloutClass} ${p.saleClassName}">${formatCurrency(p.FormattedPrice)}${p.clearanceCalloutText
                        }${p.saleCalloutText}</div>`
                        : ''
                    }
						${settings.withRatings
                        ? `
						<div class="productSummaryRating ${ratingStr < 2.5 ? 'hideVisibility' : ''}" >
							<div class="goldReviewStars goldReviewStars--${ratingStr}" ${ratingsAriaLabel ? `aria-label="${ratingsAriaLabel}"` : 'aria-hidden'
                        }>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
								<div class="goldReviewStars__star productSummaryRating__star"></div>
							</div>
						</div>`
                        : ''
                    }
					</div>`
                    : ''
                }
			</a>
		</div>`;
        })
        .join('');

}

export default getHomeTrendingProductsContent;
