function updateMyStoreAndNearbyStoresView ({ stores, renderMyDefaultStore, renderNearbyStores }) {

	if (stores.length) {

		// find the default store
		const defaultStoreArr = stores.filter((s) => s.IsDefaultStore);

		// if we have a default stores render the first one
		if (defaultStoreArr.length && typeof renderMyDefaultStore === 'function') {

			renderMyDefaultStore(defaultStoreArr[0]);

		}

		// render the stores nearby
		if (typeof renderNearbyStores === 'function') {

			renderNearbyStores(stores);

		}

	}

}

export default updateMyStoreAndNearbyStoresView;
