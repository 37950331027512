function renderCartCount (count) {

	const TRIPLE_DIGIT_CLASS = 'headerCart__count--99plus';
	const element = window.top.document.getElementById('cartCount');

	if (count > 0) {

		if (count >= 10) {

			if (count > 99) {

				element.classList.add(TRIPLE_DIGIT_CLASS);
				element.textContent = '99+';
				element.dataset.count = count;

			} else {

				element.classList.remove(TRIPLE_DIGIT_CLASS);
				element.textContent = count;
				element.dataset.count = count;

			}

		} else {

			element.classList.remove(TRIPLE_DIGIT_CLASS);
			element.textContent = count;
			element.dataset.count = count;

		}

		element.classList.remove('hidden');

		const portfolioContainer = element.closest('#portfolioContainer');
		if (portfolioContainer) {

			portfolioContainer.classList.add('notZero');

		}

	} else {

		element.classList.add('hidden');
		element.textContent = '0';
		element.dataset.count = '0';

	}

	window.top.lp.globals.cartCount = count;

}

export default renderCartCount;
