/**
 * Used for reformating paper catalog prefixes.
 * Previously there were printed special skus on paper catalog.
 * The skus were in this format: AADDDDD Where A - any letter and D - any digit
 * We did it for tracking this catalog skus' searches.
 * @param {string} searchTerm
 * @returns {string}
 */
function reformatCatalogPrefix (searchTerm) {

	const splitter = '-';
	const split = searchTerm.trim().split(splitter);
	const isPaperCatalogSku = /^[a-z]{2}[0-9]{5}$/i;

	return isPaperCatalogSku.test(split[0])
		? `${split[0].substring(0, 2)}${splitter}${split[0].substring(2)}${split.slice(1).join(splitter)}`
		: searchTerm;

}

function escapeSearch (searchTerm) {

	return reformatCatalogPrefix(searchTerm)
		.replace(/[\\?%#\/\+]/g, '-')
		.replace(/\s/g, '-')
		.replace(/-+/g, '-')
		.replace(/\$/g, '@@')
		.replace(/\./g, '@@@')
		.replace(/:/g, '@@@@@')
		.replace(/&/g, ',')
		.replace(/"/g, '%22')
		.replace(/'/g, '%27')
		.replace(/\*/g, '%2A')
		.replace(/>/g, '%3E')
		.replace(/</g, '%3C');

}

/**
 * Return search url for searchTerm
 * @param {string} searchTerm
 * @returns {string}
 */
function getSearchUrl (searchTerm) {

	const MAXLENGTH = 1000;
	const trimmedSearchTerm =		searchTerm.length > MAXLENGTH ? searchTerm.substring(0, MAXLENGTH)?.trim() : searchTerm.trim();

	return `s_${escapeSearch(trimmedSearchTerm.toLowerCase())}/`;

}

export default getSearchUrl;
