import utagViewArtificialPageGeneric from './utagViewArtificialPageGeneric';

function utagViewArtificialPageView () {

	const contentSquareId = 295;
	const tagIdFilter = [ contentSquareId ];

	let ContentsquareArtificialPageViewEventToggleOn = false;

	if (window.lp && window.lp.globals) {

		ContentsquareArtificialPageViewEventToggleOn = JSON.parse(
			window.lp.globals.contentsquareArtificialPageViewEventToggleOn || false
		);

	}

	return ({ pageName = '' }) => {

		if (ContentsquareArtificialPageViewEventToggleOn) {

			utagViewArtificialPageGeneric({ pageViewData: { contentSquarePopinName: pageName }, tagIdFilter });

		}

	};

}

export default utagViewArtificialPageView;
