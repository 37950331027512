function init () {

	// Hospitality Mask Search - retain search values
	let isMaskSearch = false;
	const hmArray = window.location.pathname.split('/');
	const $searchMaskDep = $('#searchMaskDep');
	const $searchMaskBase = $('#searchMaskBase');
	const $searchMaskFinish = $('#searchMaskFinish');

	$searchMaskDep.val('');
	$searchMaskBase.val('');
	$searchMaskFinish.val('');

	$.each(hmArray, (key, value) => {

		if (value.indexOf('_') !== -1) {

			const maskArray = value.split('_');

			if (maskArray[0] === 'hmdep') {

				isMaskSearch = true;
				$searchMaskDep.val(maskArray[1]);

			}

			if (maskArray[0] === 'hmbase') {

				isMaskSearch = true;
				$searchMaskBase.val(maskArray[1]);

			}

			if (maskArray[0] === 'hmfinish') {

				isMaskSearch = true;
				$searchMaskFinish.val(maskArray[1]);

			}

		}

	});

	if (isMaskSearch) {

		$('#hospitalityMaskSearchMode').val(2);
		$('#search').hide();
		$('#searchMask').show().css('display', 'inline-block');

	}

	// On Hospitality Mask Site/Sku search dropdown change
	$('#hospitalityMaskSearchMode').change(() => {

		setHospitalitySearchMode();

	});

	setHospitalitySearchMode();

	function setHospitalitySearchMode () {

		const searchMode = $('#hospitalityMaskSearchMode option:selected').text();

		if (searchMode === 'SITE') {

			$('#search').show();
			$('#searchBtn').addClass('site').removeClass('sku');
			$('#searchMask').hide();

		} else if (searchMode === 'SKU') {

			$('#search').hide();
			$('#searchBtn').addClass('sku').removeClass('site');
			$('#searchMask').show();
			$('#searchMask').css('display', 'inline-block');

		}

	}

}

export default init;
