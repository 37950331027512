/* eslint-disable no-param-reassign */

type FormToJSONOptions = {
	includeDisabled?: boolean;
	exclusions?: Array<string>;
  }

/**
 * Encodes a set of form elements as JSON. Per HTML standards, only successful controls which are valid for form
 * submission are encoded. A successful control must have "name" attribute and is not disabled. If you want to
 * include disabled elements, you need to pass "includeDisabled" flag as true as part of options parameter.
 * @param {string or DOMElement} form The form DOM element or form CSS selector string
 * @param {*} options The options object containing the following properties:
 * 	includeDisabled: true|false - indicates if disabled elements are included or not. Defaults to false.
 * 	exclusions: [] - an Array of element names you want to exclude. Defaults to empty Array.
 */
function formToJSON (form: string | HTMLElement, options: FormToJSONOptions = {}) {

	const defaultOptions: FormToJSONOptions = {
		includeDisabled: false,
		exclusions: []
	};

	const config: FormToJSONOptions = { ...defaultOptions, ...options };

	try {

		const formElement = typeof form === 'string' ? document.querySelector(form) : form;
		const filterSelector = config.includeDisabled ? '' : ':not([disabled])';
		const formControls = formElement?.querySelectorAll(`[name]${filterSelector}`);
		const serialized = [].slice.call(formControls).reduce((obj: { [key: string]: string | boolean }, element: HTMLInputElement) => {

			if (
				!obj[element.name]
				&& (!Array.isArray(config.exclusions) || config.exclusions.indexOf(element.name) === -1)
			) {

				// handle different element types
				if (element.type === 'checkbox') {

					obj[element.name] = element.checked;

				} else if (element.type === 'radio') {

					// get value from selected radio button
					const nameQuery = `[name=${element.name}]`;
					const selectedRdo = formElement?.querySelector(`${nameQuery}:checked`);

					// if none of the radio buttons with matched name are not checked we get value of the first radio button
					obj[element.name] = selectedRdo
						? (selectedRdo as HTMLInputElement).value
						: (formElement?.querySelector(nameQuery) as HTMLInputElement).value;

				} else {

					obj[element.name] = element.value;

				}

			}

			return obj;

		}, {});

		return serialized;

	} catch (ex) {

		throw new Error(`ERROR: ${ex}`);

	}

}

export default formToJSON;
