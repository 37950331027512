function initNoCookiesOverlay () {

	if (!window.navigator.cookieEnabled) {

		const p = document.createElement('p');
		const noCookiesContainerBkg = document.createElement('div');
		noCookiesContainerBkg.id = 'noCookiesContainerBkg';
		const noCookiesContainer = document.createElement('div');
		noCookiesContainer.id = 'noCookiesContainer';
		p.textContent =			'Your Cookies setting is not enabled! You have either disabled it or are using an older browser that does not support it. Because of this, you will not be able to view our web pages or use our site features. In order to browse our site, please turn on Cookies in your browser settings.';
		noCookiesContainerBkg.style.display = 'block';
		noCookiesContainer.appendChild(p);
		noCookiesContainer.style.display = 'block';
		document.body.appendChild(noCookiesContainer);
		document.body.appendChild(noCookiesContainerBkg);

	}

}

export default initNoCookiesOverlay;
