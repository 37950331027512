import getSearchUrl from './getSearchUrl';

class SearchAutocompleteModel {

	constructor ({ api }) {

		this.api = api;
		this.currentSearch = undefined;
		this.query = '';
		this.activeSuggestionIndex = -1;
		this.activeProductIndex = -1;
		this.suggestions = [];
		this.products = [];

	}

	getData () {

		return {
			query: this.query,
			activeProductIndex: this.activeProductIndex,
			activeSuggestionIndex: this.activeSuggestionIndex,
			products: this.products,
			suggestions: this.suggestions
		};

	}

	getSuggestion (i) {

		return i > -1 ? this.suggestions[i] : { keyWord: this.query, searchUrl: getSearchUrl(this.query) };

	}

	getActiveSuggestion () {

		return this.getSuggestion(this.activeSuggestionIndex);

	}

	/**
	 * Set active suggestion. Update products too if @pullProducts is true
	 * @param {integer} id
	 * @param {bool} pullProducts
	 * @returns {Promise}
	 */
	setActiveSuggestion (id, pullProducts = true) {

		if (id < -1) {

			id = -1;

		} else if (id >= this.suggestions.length) {

			id = this.suggestions.length - 1;

		}

		this.activeSuggestionIndex = id;
		const searchUrl = id === -1 ? getSearchUrl(this.query) : this.suggestions[id].searchUrl;

		if (pullProducts) {

			return this.getProducts([ searchUrl ]).then((r) => {

				this.products = r[searchUrl] || [];

			});

		}

		return Promise.resolve();

	}

	getActiveProduct () {

		return this.products[this.activeProductIndex];

	}

	setActiveProduct (i) {

		this.activeProductIndex = i;

	}

	// Temporary code to record response time for unbxed api without product suggestions
	getSuggestionsWithoutProduct (search) {

		console.time(`Response Time Without Product Suggestion for "${search}"`);
		this.currentSearch = search;
		return this.api.getSuggestions(search, { addProducts: false }).then(() => {

			console.timeEnd(`Response Time Without Product Suggestion for "${search}"`);

		});

	}

	getSuggestions (search, { infields, products }) {

		// Temporary code to record response time for unbxed api without product suggestions
		if (window.lp.globals.pssProductSuggestionConsoleEnabled) {

			console.time(`Response Time With Product Suggestion for "${search}"`);

		}

		this.currentSearch = search;
		return this.api.getSuggestions(search, { addProducts: products }).then((r) => {

			if (this.currentSearch !== search) {

				return Promise.reject(search);

			}

			this.suggestions = [];
			this.query = search;
			this.products = r.popularProducts || [];
			this.activeSuggestionIndex = -1;

			// infields options first
			if (r.infieldModels) {

				for (let i = 0; i < r.infieldModels.length; i++) {

					this.suggestions.push(r.infieldModels[i]);

					// check if we would like to add infields
					if (infields) {

						for (let ii = 0; ii < r.infieldModels[i].attributes.length; ii++) {

							this.suggestions.push({
								docType: r.infieldModels[i].docType,
								keyWord: r.infieldModels[i].keyWord,
								value: r.infieldModels[i].attributes[ii].value,
								searchUrl: r.infieldModels[i].attributes[ii].searchUrl,
								name: r.infieldModels[i].attributes[ii].name
							});

						}

					}

				}

			}

			// regular plain suggestions
			if (r.keyWordSuggestions) {

				for (let i = 0; i < r.keyWordSuggestions.length; i++) {

					this.suggestions.push(r.keyWordSuggestions[i]);

				}

			}

			if (products) {

				// pre-cache products
				const searchUrls = [];
				for (let i = 0; i < this.suggestions.length; i++) {

					if (this.suggestions[i].searchUrl !== 's_/' && this.suggestions[i].searchUrl !== 's_ /') {

						searchUrls.push(this.suggestions[i].searchUrl);

					}

				}
				this.api.getProducts(searchUrls).then(() => {

					// Temporary code to record response time for unbxed api without product suggestions
					if (window.lp.globals.pssProductSuggestionConsoleEnabled) {

						console.timeEnd(`Response Time With Product Suggestion for "${search}"`);

					}

				});

			}

			return r;

		});

	}

	getProducts (searchUrls) {

		return this.api.getProducts(searchUrls);

	}

	getActiveProductIndex () {

		return this.activeProductIndex;

	}

	getActiveSuggestionIndex () {

		return this.activeSuggestionIndex;

	}

	resetModel (query) {

		this.query = query;
		this.activeProductIndex = -1;
		this.activeSuggestionIndex = -1;
		this.suggestions = [];
		this.products = [];

	}

}

export default SearchAutocompleteModel;
