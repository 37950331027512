import { domReady } from 'utilities/dom';

domReady(() => {

	const hasNotificationsStorageKey = 'lp.notifications.customerHasNotifications';

	class Notifications {

		constructor () {

			this.pollNotificationsInterval = parseInt(window.lp.globals.pollNotificationsInterval, 10) || 60000;
			this.nextPage = 1;
			this.pollInterval = null;
			this.$el = $('#userNotifications');
			this.$count = $('#notificationsCount');
			this.$panel = $('#notifPanel');
			this.$noNotifs = $('#noNotifications');
			this.$notifs = $('#notifications');
			this.$loadMore = $('#loadMoreNotifications');
			this.$notifMenuItem = $('.notifMenuItem');
			this.setDropDownMaxHeight();
			this.bindEventHandlers();
			this.$el.addClass('connected').removeClass('disabled');
			this.pollNotificationsCount();
			this.startPollNotificationsCount();
			window.lp.globals.isCustomer ? this.toggleCustomerNotification() : this.showEl();

		}

		setDropDownMaxHeight () {

			const winH = $(window).height();
			// Height from other factors to counter available height
			const counter = $('.portal').outerHeight();
			// #notifPanel is box-sizing: border-box;
			const maxHeight = winH - counter;

			this.$panel.css('max-height', maxHeight);

		}

		bindEventHandlers () {

			$(window).on('resize', this.setDropDownMaxHeight.bind(this));
			this.$notifMenuItem.on('click', this.togglePanel.bind(this));

		}

		pollNotificationsCount () {

			$.ajax({
				type: 'get',
				url: '/api/connectascustomer/usersessionmanager/notificationscount',
				data: {
					rewardNumber: window.lp.globals.rewardNumber,
					isCustomerService: window.lp.globals.isCustomerService
				},
				success: this.updateCount.bind(this)
			});

		}

		updateCount (data) {

			const count = parseInt(data, 10);

			if (count > 0) {

				this.$el.addClass('hasNewNotifications');
				this.$count.text(count);
				// For customers this may be first notification, so...
				// Show the notifications icon
				this.showEl();
				// Set the localStorage key/value pair that prevents unnecessary additional calls
				if (window.lp.globals.isCustomer) {

					window.localStorage.setItem(hasNotificationsStorageKey, '1');

				}

			} else {

				this.$el.removeClass('hasNewNotifications');
				this.$count.text('0');

			}

		}

		startPollNotificationsCount () {

			this.pollInterval = setInterval(this.pollNotificationsCount.bind(this), this.pollNotificationsInterval);

		}

		toggleCustomerNotification () {

			const hasNotifications = window.localStorage.getItem(hasNotificationsStorageKey);
			const that = this;

			if (hasNotifications) {

				$.ajax({
					type: 'get',
					url: `/api/connectascustomer/usersessionmanager/notifications/?currentpage=${this.nextPage}&canupdate=false`,
					success (data) {

						if (!data) {

							window.localStorage.setItem(hasNotificationsStorageKey, '0');
							return;

						}

						that.showEl();

					}
				});

			} else if (hasNotifications === '1') {

				this.showEl();

			}

		}

		showEl () {

			this.$el.removeClass('hidden');

		}

		togglePanel () {

			// This is a .toggleDropDown but we hide the panel until loadNotifictions
			if (this.$el.hasClass('closed')) {

				// Don't listen for new notifications when the panel is open
				clearInterval(this.pollInterval);
				// Load newest notifications and update timestamp
				this.loadNotifications(true);
				// Clear count of new notifications
				this.updateCount(0);

			} else {

				// Current implementation does not retain loaded notifications, so just reset
				this.resetPanel();

			}

		}

		loadNotifications (isFirstPage) {

			if (isFirstPage) {

				this.nextPage = 1;
				this.$notifs.empty();

			}

			$.ajax({
				type: 'get',
				url: `/api/connectascustomer/usersessionmanager/notifications/?currentpage=${
					this.nextPage
				}&canupdate=${(!!isFirstPage).toString()}`,
				success: this.handleLoadSuccess.bind(this),
				error: this.showPanel.bind(this)
			});

		}

		handleLoadSuccess (data) {

			let li;
			// Render notifications
			if (data) {

				if (data.UserActivityNotifications && data.UserActivityNotifications.length > 0) {

					data.UserActivityNotifications.forEach((item) => {

						li = `
	                        <li class="${item.IsNew ? 'new' : ''}">
								<div class="notificationsLeft">
									<span class="date">${item.FormattedCreatedDate}</span>
									<p>${item.ActivityText}</p>
								</div>
								<div class="notificationsRight">
	                            	${item.Link ? `<a href="${item.Link}">${item.UserAssetDescription}</a>` : ''}
								</div>
	                        </li>
	                    `;

						this.$notifs.append(li);

					});

					this.$noNotifs.addClass('hidden');
					this.$notifs.removeClass('hidden');

				}

				// Track page increment
				this.nextPage += 1;

				// Toggle Load More button
				if (data.NoMoreData) {

					this.$loadMore.addClass('hidden');

				} else {

					this.$loadMore.removeClass('hidden');

				}

			}

			this.showPanel();

		}

		showPanel () {

			this.$panel.removeClass('hidden');

		}

		resetPanel () {

			this.updateCount(0);
			this.startPollNotificationsCount();
			this.nextPage = 1;

			this.$panel.addClass('hidden');
			this.$noNotifs.removeClass('hidden');
			this.$notifs.empty().addClass('hidden');
			this.$loadMore.addClass('hidden');

		}

	}

	// Notifications are for Customers and CSR
	if (window.lp.globals.isCustomer || window.lp.globals.isCustomerService) {

		lp.notifications = new Notifications();

	}

	// Pagination
	$('.sortNavPagingNext, .sortNavPagingPrev', '.notificationsForm').on('click', function (e) {

		if ($(this).hasClass('disabled')) {

			return;

		}

		const $form = $(this).closest('form');
		let nextPrevPage = 1;
		const pageNumberField = $('.resultsPageNumber', $form);

		if ($(this).hasClass('sortNavPagingPrev')) {

			nextPrevPage = -1;

		}

		pageNumberField.val(parseInt(pageNumberField.val(), 10) + nextPrevPage);
		e.preventDefault();
		$form.submit();

	});

});
