const rule = {
	name: 'phoneNumber',
	method: (value, element, params) => {

		if (value === '') {

			return true;

		}
		return /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/.test(value);

	},
	errorMessage: (params, element) => 'Please enter a valid phone number'
};

export default rule;
