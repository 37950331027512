import { utagLinkCheckout, utagLinkCart, utagViewArtificialPageView } from 'features/tealium';

import staticErrorMessages from './errorMessages';

/**
 * Show Cart error messages in a modal or in the specified container element.
 * @param params Object containing:
 * 	cartStatus - string or array of error codes/messages
 *	showOnModal - flag to indicate if error should be displayed in a modal or not. Defaults to true.
 *	modal - lpModal options
 *	fromModal - flag to indicate if modal is already present to display the error message in the current modal and
 *	hide the existing content $el - jQuery object reference of the container to display error message at if
 *	showOnModal is false. response - the full response of the Cart API ajax call that resulted in the error
 */
const trackArtificalPageViewEvent = utagViewArtificialPageView();

const resizeModalHeight = () => {

	const modalHeight = $('#lpModalContent').outerHeight(true);
	const modalContentHeight = $('#cartErrorModal').outerHeight(true);

	if (modalHeight < modalContentHeight) {

		$('body').lpModalResize({
			height: modalContentHeight,
			noAnimation: true
		});

	}

};

function showCartErrorMessage (params) {

	let errors = [];
	let errorHtml = [];
	let $cartErrorModal;
	let $modalContent;
	const trackCheckoutEvents = utagLinkCheckout();
	const trackCartEvents = utagLinkCart();
	const defaultOptions = {
		$el: $(),
		title: 'Error',
		// eslint-disable-next-line max-len
		errorMessages: staticErrorMessages, // defaults to staticErrorMessages but caller can pass a different list of errors (e.g. errors for creating an account from Order Confirmation page)
		showOnModal: true,
		modal: {
			onInit: true,
			width: 560,
			height: 100,
			appendContentToForm: false,
			openEvt: resizeModalHeight,
			closeEvt: () => trackArtificalPageViewEvent({ pageName: '' })
		}
	};
	const options = $.extend(true, {}, defaultOptions, params);

	if (!options.cartStatus && options.response) {

		options.cartStatus = options.response.CartStatus;

	}

	if (options.cartStatus instanceof Array) {

		errors = $.map(options.cartStatus, (value) => {

			// Calculator Error, find exact message from CalculatorResults
			if (value === 8 && options.response) {

				const calculatorErrors = [];
				const prop = window.lp.globals.isCustomerService ? 'Message' : 'UserFriendlyMessage';

				if (
					options.response.GlobalCartResponseModel
					&& options.response.GlobalCartResponseModel.CalculatorResults
				) {

					const calculatorResults = options.response.GlobalCartResponseModel.CalculatorResults;
					if (calculatorResults.length > 0) {

						calculatorResults.forEach((result) => {

							const errorMessages = result.ErrorMessages;
							if (errorMessages && errorMessages.length > 0) {

								errorMessages.forEach((error) => {

									calculatorErrors.push(error[prop]);

								});

							}

						});

						return calculatorErrors;

					}

				}

			}

			const errorString =				typeof value === 'string' && options.errorMessages instanceof Array
				? value
				: options.errorMessages[value];

			trackCheckoutEvents({
				eventAction: 'Error-Billing',
				eventLabel: errorString
			});

			if (errorString === 'The credit card provided was rejected.') {

				trackArtificalPageViewEvent({ pageName: 'Card Rejected' });

			} else if (errorString === 'Sorry but your card has expired.') {

				trackArtificalPageViewEvent({ pageName: 'Card Expired' });

			}

			return errorString;

		});

		// If error code was not found, then there must be a new error code introduced in the backend that must
		// be added on frontend. In that case, use a generic error message.
		// TODO: Error message implementation should be refactored in the future to avoid having to define the cart
		// error messages on frontend.
		if (errors.length === 0) {

			errors.push(staticErrorMessages[0]);

		}

	} else {

		const errorString = options.cartStatus
			? options.cartStatus
			: options.errorMessages instanceof Array
				? options.errorMessages[0]
				: options.cartStatus;
		if (
			window.location.pathname.includes('cart')
			&& !window.location.pathname.includes('shipping')
			&& !window.location.pathname.includes('billing')
		) {

			trackCartEvents({
				eventAction: 'Error-Cart',
				eventLabel: errorString
			});

		} else {

			const errorAction = window.location.pathname.includes('shipping') ? 'Error-Shipping' : 'Error-Billing';
			trackCheckoutEvents({
				eventAction: errorAction,
				eventLabel: errorString
			});

		}
		errors.push(errorString || staticErrorMessages[0]);

	}

	const errorMessage = `<p>${errors.join('</p><p>')}</p>`;

	if (options.showOnModal) {

		errorHtml.push('<div id="cartErrorModal" class="modalError">');

		if (options.title) {

			errorHtml.push(`<h4>${options.title}</h4>`);

		}

		errorHtml.push(errorMessage);
		errorHtml.push('</div>');
		errorHtml = errorHtml.join('');

		if (options.fromModal) {

			$modalContent = window.parent.$('#lpModalContent');
			$modalContent.children().hide();
			window.parent.$('body').lpModalResize({
				width: options.modal.width,
				height: options.modal.height
			});

			$modalContent.append(errorHtml).find('#cartErrorModal').show();

		} else {

			$cartErrorModal = $('#cartErrorModal');
			if ($cartErrorModal.length) {

				$cartErrorModal.replaceWith(errorHtml);

			} else {

				$('body').append(errorHtml);

			}

			options.modal.content = $('#cartErrorModal');
			$('body').lpModal(options.modal);

		}

	} else {

		options.$el.html(errorMessage);

	}

}

export default showCartErrorMessage;
