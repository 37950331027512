/**
 * Returns suggestions and popular products for @searchKey
 * @param {string} searchKey
 * @param {object} additionalData
 * @returns {Promise}
 */
function getSuggestions (searchKey, additionalData) {

	const endpoint = window.lp.globals.autoComplete.suggestionsUrl;
	const options = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		},
		credentials: 'same-origin'
	};
	const data = {
		searchKey,
		...additionalData
	};
	const query = Object.keys(data)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
		.join('&');

	return fetch(`${endpoint}/?${query}`, options).then((res) => res.json());

}

export default getSuggestions;
