import { utagLinkSearch } from 'features/tealium/';
import { setGenericEvent } from 'features/tealium/ga4/custom';

const trackSearchEvents = utagLinkSearch();

function renderRecentlyViewedItems () {

	if (window.lp.globals.isCustomerService || window.lp.globals.isHospitality) return false;
	const { recommendedItemsJsonSSR } = lp.certona;
	let productArr = [];

	if (recommendedItemsJsonSSR) {

		recommendedItemsJsonSSR.Widgets.forEach((eachWidget) => {

			if (
				[
					'GF_homepage_rr',
					'GF_sortpage_rr',
					'GF_nosearch_rr',
					'GF_full_rr',
					'GF_categorylanding_rr',
					'GF_default_rr',
					'GF_product_rr'
				].indexOf(eachWidget.Scheme) >= 0
				&& productArr.length === 0
			) {

				productArr = eachWidget.Products.slice(0, 3);

			}

		});

	}

	const newRecentlyViewedEleString =		productArr.length === 0
		? ''
		: `<div id="recentlyViewedSearchWidgetContainer" class="certonaSearchWidgetContainer" data-capture-section="RecentlyViewedSearchWidget" data-has-add-to-cart="0" >
				<div class="certonaSearchWidgetHeading">
					<div class="certonaSearchWidgetContainer__header">Recently Viewed</div>
					<div class="certonaSearchWidgetContainer__viewAll">
						<a href="/recently-viewed/" id="viewAllRecentlyViewedSearchBtn" class="certonaSearchWidgetContainer__viewAllBtn" rel="nofollow">View all</a>
					</div>
				</div>
				<div class="certonaSearchWidgetContainer__list">
					${productArr
		.map(
			(eachProduct) => `<div class="certonaSearchWidgetContainer__item">
									<a href="${eachProduct.Url}" data-certonasku="${eachProduct.ShortSku}" class="recentlyViewedItem">
										<img src="${eachProduct.PreviewImageUrl || eachProduct.ImageBaseUrl}" data-sku="${
	eachProduct.ShortSku
}" data-sku-input-type="${eachProduct.SkuInputType}" width="80" height="80" alt="${
	eachProduct.ProductName
}">
									</a>
								</div>`
		)
		.join('')}
				</div>
			</div>`;

	const elementRV = document.createElement('div');
	elementRV.innerHTML = newRecentlyViewedEleString;

	const recentlyViewedDestination = document.querySelector('.recentsearches .searchdropdown-root__suggestions');
	if (recentlyViewedDestination) {

		recentlyViewedDestination.insertBefore(elementRV, recentlyViewedDestination.firstChild);

		document.querySelectorAll('.recentlyViewedItem').forEach((element, i) => {

			element.addEventListener('click', (e) => {

				const shortSku = e.target.getAttribute('data-sku') || '';
				trackSearchEvents({
					eventAction: 'Recently Viewed Product-SelectSubmit',
					eventLabel: shortSku
				});
				setGenericEvent({ event_name: 'search_recent_product_select', item_id: shortSku });

				setGenericEvent({ event_name: 'rcntvw_product_click', item_position_number: i + 1 });

			});

		});

		const viewAllBtn = document.querySelector('#viewAllRecentlyViewedSearchBtn');
		viewAllBtn?.addEventListener('click', (e) => {

			trackSearchEvents({
				eventAction: 'View All Recent Products-SelectSubmit',
				eventLabel: e.target.innerText || ''
			});
			setGenericEvent({ event_name: 'search_view_all_recent_click' });

			setGenericEvent({ event_name: 'rcntvw_viewall_click' });

		});

	}

}

export default renderRecentlyViewedItems;
