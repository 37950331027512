function topSearchContent (recentSearchesCount) {

	if (recentSearchesCount) return '';
	const topSearches = [
		{ name: 'Lamp Shades' },
		{ name: 'Floor Lamps' },
		{ name: 'Bathroom Vanity Lights' },
		{ name: 'Table Lamps' },
		{ name: 'Kitchen Pendants' },
		{ name: 'Ceiling Fans' },
		{ name: 'Sconces' },
		{ name: 'Barstool' },
		{ name: 'Chandeliers' },
		{ name: 'Desk Lamps' }
	];

	return `<div class="searchdropdown-root__title ${topSearches.length > 0 ? '' : 'hidden'}" >Top Searches</div>
	<div class="searchSuggestionCount sr-only ${topSearches.length > 0 ? '' : 'hidden'}" aria-live="assertive">
	</div>
	${topSearches
		.map(
			(item) => `<div class="searchdropdown-root__suggestion-wrapper">
						<div class="searchdropdown-root__suggestion topSearchContentItem">${item.name}</div>
					</div>`
		)
		.join('')}`;

}

export default topSearchContent;
