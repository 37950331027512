const rule = {
	name: 'valueGreaterThanZero',
	method: (value) => {

		const hasValue = value.trim() !== '';
		return hasValue && Number(value) > 0;

	},
	errorMessage: () => 'Please enter a value greater than zero.'
};

export default rule;
