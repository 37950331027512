import { domReady } from 'utilities/dom';

domReady(() => {

	const { gtag } = window;
	const utagCustomerRewardsNumber = window.top.utag_data?.customer_rewards_number;

	if (typeof gtag === 'function' && utagCustomerRewardsNumber) {

		gtag('set', {
			user_id: utagCustomerRewardsNumber
		});

	}

});
