import './rateus.scss';

function rateUsFormTemplate (data) {

	const { isLoggedIn } = window.lp.globals;

	return `
		<div class="header">
			<div class="title">
				<h1>Give Us Your Feedback</h1>
			</div>
		</div>
		<br class="clear">
		<form id="frmRateUs" class="lpForm validationGroup">
			<div class="requiredNote">* Required</div>
			<div class="ratingForm">
				<div class="field">
					<h2 class="h3 requiredLabel">1. How are we doing?</h2>
					<span class="validationMessage overallRatingValidation"></span>
					<div class="rating fieldCheckbox">
						<input id="rb1" aria-required="true" name="OverallRating" type="radio" value="1" aria-label="One Star" />
						<input id="rb2" aria-required="true" name="OverallRating" type="radio" value="2" aria-label="Two Stars" />
						<input id="rb3" aria-required="true" name="OverallRating" type="radio" value="3" aria-label="Three Stars"/>
						<input id="rb4" aria-required="true" name="OverallRating" type="radio" value="4" aria-label="Four Stars" />
						<input id="rb5" aria-required="true" name="OverallRating" type="radio" value="5" aria-label="Five Stars"/>
					</div>
				</div>

				<div class="field">
					<h2 class="h3">2. Why did you choose this rating? Suggestions are always welcome.</h2>
					<textarea name="Comments" id="Comments" maxlength="750" rows="3" aria-label="Comments"></textarea>
				</div>

				<div class="zipEmail">
	${
	!isLoggedIn
		? `<div class="field">
						<div>
							<label for="txtEmail">Email Address (Optional)</label>
						</div>
						<input type="email" name="Email" id="txtEmail" class="emailNotRequired" maxlength="50"
							value="${data.Email || ''}" aria-label="Email">
						<div class="validationMessage"></div>
				</div>`
		: ''
}
	${
	data.ShowOptIn
		? `<div class="field fieldCheckbox">
					<input id="OptIn" name="OptIn" type="checkbox" value="true"${data.OptIn ? ' checked="checked"' : ''}/>
					<label for="OptIn">Email me exclusive offers, sale alerts, & more.</label>
				</div>`
		: ''
}
		</div>
				<button type="submit" id="btnSubmitRating" class="calloutBtn">Submit Rating</button>
				<p class="disclaimer">
					Unless you request special offers and sales updates, your email address will only be used to respond
					to any questions or comments you provide with your rating.
					Reviews may be posted on LampsPlus.com or shared on social media sites at our discretion.
					<a href="/rate-us/privacy-policy/" class="rateUsPrivacyPolicy">View our privacy policy.</a>
				</p>
			</div>
			<input name="Location" type="hidden" value="${data.Location}" />
			<input name="ShowOptIn" type="hidden" value="${data.ShowOptIn}" />
              <input name="CartId" type="hidden" value="${data.CartId}" />
	           <input name="CustomerType" type="hidden" value="${data.CustomerType}" />
		</form>`;

}
export default rateUsFormTemplate;
