import { getSearchUrl } from 'features/search';

import cache from './cache';
import getSuggestions from './getSuggestions';

function getSuggestionsWithCache (search, additionalData) {

	if (cache.suggestions[search]) {

		return new Promise((resolve) => {

			resolve(cache.suggestions[search]);

		});

	}
	return getSuggestions(search, additionalData).then((response) => {

		cache.suggestions[search] = response;
		cache.products[getSearchUrl(search)] = response.popularProducts;
		return response;

	});

}

export default getSuggestionsWithCache;
