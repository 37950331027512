import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { mapRecommendedItemToProduct } from 'features/product';

import { getRecommendedItems } from './api';

const reducerName = 'recommendedItems';

const fetchRecommendedItems = createAsyncThunk(
	reducerName,
	async () => {

		const response = await getRecommendedItems({
			data: window.resx.RecommendedItemsRequest,
			currentWebSiteModeSubLocation: window.lp.globals.currentWebSiteModeSubLocation,
			timer: window.resx.ajaxTimeout
		});
		return response;

	},
	{
		condition: (arg, { getState }) => {

			const { recommendedItems } = getState();
			const { fetchStatus } = recommendedItems;
			if (fetchStatus === 'fulfilled' || fetchStatus === 'pending') {

				return false;

			}
			return true;

		}
	}
);

const recommendedItemsSlice = createSlice({
	name: reducerName,
	initialState: {
		PageId: '',
		OrderId: '',
		SubLocation: '',
		Widgets: {},
		fetchStatus: ''
	},
	reducers: {
		setWidgetBoxTitle (state, action) {

			const { schemeName, title } = action.payload;
			const { Widgets } = state;
			const widget = Widgets[schemeName];

			if (widget) {

				widget.BoxTitle = title;

			}

		}
	},
	extraReducers: {
		[fetchRecommendedItems.pending]: (state) => {

			state.fetchStatus = 'pending';

		},
		[fetchRecommendedItems.fulfilled]: (state, action) => {

			const { payload } = action;
			Object.keys(state).forEach((key) => {

				if (key === 'Widgets') {

					state.Widgets = payload.Widgets.reduce((memo, widget) => {

						const mappedWidget = {
							...widget,
							Products: widget.Products.map((product) => mapRecommendedItemToProduct(product))
						};
						memo[widget.Scheme] = mappedWidget; // eslint-disable-line no-param-reassign
						return memo;

					}, {});

				} else {

					state[key] = payload[key];

				}

			});
			state.fetchStatus = 'fulfilled';

		},
		[fetchRecommendedItems.rejected]: (state, action) => {

			state.fetchRecommendedItemsError = action.payload;
			state.fetchStatus = 'rejected';

		}
	}
});

const { setWidgetBoxTitle } = recommendedItemsSlice.actions;

export { fetchRecommendedItems, setWidgetBoxTitle };
export default recommendedItemsSlice.reducer;
