const rule = {
	name: 'price',
	method: (value, element, params) => {

		if (value === '') {

			return true;

		}
		return /^\$?([1-9]+[0-9]*)(\.)?([0-9]{1,2})\$??$/gi.test(value);

	},
	errorMessage: (params, element) => 'Please enter valid price.'
};
export default rule;
