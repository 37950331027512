import { localStorageName } from './config';

function clearRecentSearches () {

	// if user is loggged in, then call clear recent search api
	if (window.lp.globals.isLoggedIn) {

		return fetch('/api/v1/search/clearrecentsearch', { method: 'delete' });

	}

	// clear recent searches locally
	if (typeof Storage !== 'undefined') {

		localStorage.setItem(localStorageName, '[]');
		return Promise.resolve();

	}

	return Promise.reject(new Error('Unable to clear Recent Searches.'));

}

export default clearRecentSearches;
