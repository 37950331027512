import getSignInErrorMessage from '../../getSignInErrorMessage';

function templateSignInForm (data, hasFormValidation) {

	return `
		<form id="loginForm" class="signInForm inlineSignIn validationGroup lpForm${
	hasFormValidation ? ' skipGlobalOnloadInitFormValidation' : ''
}">
			<div class="accountSignInContainer">
			<h1 class="formHeader">Sign In</h1>
			<div class="signInError server-error hidden">${getSignInErrorMessage(data)}</div>
			<div class="requiredNote">* Required</div>


				<div class="signInMessage">${data.SignInMessage ? data.SignInMessage : ''}</div>

				<div class="field">
					<label for="UserNameModal" class="requiredLabel">Email</label>
					<input type="email" id="UserNameModal" name="UserName" class="required userNameAndEmailAddress" maxlength="60" autocomplete="off" />
					${hasFormValidation ? '<div class="validationMessage"></div>' : ''}
				</div>
				<div class="field">
					<label for="PasswordModal" class="requiredLabel">Password</label>
					<div class="revealPasswordWrapper revealPasswordWrapper--wide">
						<input type="password" id="PasswordModal" name="Password" class="required restrictedCharacters password revealPassword" maxlength="60" autocomplete="off" />
						<span class="toggleRevealPassword" tabindex="0" role="button" aria-label="show password">show</span>
					</div>
					${hasFormValidation ? '<div class="validationMessage"></div>' : ''}
				</div>
				<div class="fieldGroup rememberMeContainer">
					${
	!window.lp.globals.isKiosk
		? `
					<div class="field fieldCheckbox fieldInline">
						<input type="checkbox" id="KeepLoggedInModal" name="KeepLoggedIn" />
						<label for="KeepLoggedInModal">Remember me</label>
					</div>`
		: ''
}

					<div class="field forgotPassword">
						<a href="/account/forgot-password/" id="forgotPassword">Forgot Password?</a>
					</div>
				</div>
				<div class="signInBtnContainer">
					<button type="submit" class="signInBtn calloutBtn xLarge">Sign In</button>
				</div>
${
	data.RegistrationAllowed
		? `
				<div >
					<a href="/account/create/" id="createAccount">Create an Account</a>
				</div>`
		: ''
}
				<div id="signInSmallOrContainer">
					<div class="or">or</div>
				</div>

				<div id="fbConnectContainer">
					<a href="${data.FacebookLink}" id="accountFacebookConnectBtn">Connect Using Facebook</a>
				</div>

			</div>
		</form>`;

}

export default templateSignInForm;
