function trackEvent (data) {

	if (window.utag && data.event_category && data.event_action && data.event_label) {

		window.utag.link(data);

	}

}

export default trackEvent;
