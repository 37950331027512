const queue = [];
const utagScript = document.getElementById('LpUtagScript');

if (utagScript && !window.top.utag) {

	utagScript.onload = () => {

		if (window.top.utag) {

			queue.forEach((func) => func());

		} else {

			console.warn('utag is not defined after LpUtagScript load.');

		}

	};

} else if (!utagScript) {

	console.warn('Utag script with ID "LpUtagScript" is not present in html.');

}

const queueUtagCall = (callback) => {

	if (window.top.utag) {

		callback();

	} else {

		queue.push(callback);

	}

};

export default queueUtagCall;
