import { triggerEvent } from 'utilities/dom';

import canHideRelatedItemsTab from './canHideRelatedItemsTab';
import hideRelatedItemsTab from './hideRelatedItemsTab';
import showRelatedItemsTab from './showRelatedItemsTab';

function hideWidget () {

	if (canHideRelatedItemsTab()) {

		hideRelatedItemsTab();

	} else {

		showRelatedItemsTab();

	}

	document.body.classList.remove('loading');

}

export default hideWidget;
