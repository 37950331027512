import { localStorageName, maxRecentSearches } from './config';

function addRecentSearch (query) {

	// if local storage is supported, then save recent search for anonymous user
	if (!window.lp.globals.isLoggedIn && typeof Storage !== 'undefined') {

		const formattedQuery = query
			.replace(/ +/g, ' ')
			.trim()
			.toLowerCase();
		const localStorageValue = localStorage.getItem(localStorageName);
		const recentSearches = localStorageValue === '' || !localStorageValue ? [] : JSON.parse(localStorageValue);
		// Dedupes and limits to max searches items
		const cleanSearches = recentSearches.filter((e) => e !== formattedQuery).slice(0, maxRecentSearches - 1);

		cleanSearches.unshift(formattedQuery);

		localStorage.setItem(localStorageName, JSON.stringify(cleanSearches));

	}

}

export default addRecentSearch;
