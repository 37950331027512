import request from './request';

function getNearByStores () {

	return request({
		options: {
			path: '/storeavailability/nearbystores'
		}
	});

}

export default getNearByStores;
