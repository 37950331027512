// adding alphanumeric only validation

const rule = {
	name: 'alphanumeric',
	method: (value, element, params) => {

		console.log('this', this);
		return /^[\w ]+$/i.test(value);

	},
	errorMessage: (params, element) => 'Letters, numbers, spaces or underscores only please'
};

export default rule;
