import setButtonDisabledState from './setButtonDisabledState';

/**
 * Utility function to make button disabled and unclickable and show spinning icon while the form is being submitted.
 * Call this function only after the form has been validated and all the field inputs are valid.
 * @param {HTMLElement} buttonElement - The button DOM element.
 * @param {Promise} promise - The Promise object. Normally used if submitting form via AJAX request.
 * 							  For regular form submission, it is optional. Specify null or don't specify at all.
 * @param {String} additionalClassNames - Additional class names to add to the button, if any. Optional.
 * 										  By default, 'loading' and 'noPointerEvents' classes are added.
 */
function setButtonStateDuringActivity (
	buttonElement: HTMLButtonElement,
	promise?: Promise<any>,
	additionalClassNames?: string
) {

	if (!buttonElement) {

		return;

	}

	const defaultClassNames = [ 'loading', 'noPointerEvents' ];
	const classNames = additionalClassNames
		? [ ...defaultClassNames, ...additionalClassNames.split(' ') ]
		: defaultClassNames;

	function removeClassName () {

		// Cannot use spread operator due to IE not supporting multiple class names in classList.remove in one statement
		classNames.forEach((className) => buttonElement.classList.remove(className));
		setButtonDisabledState(buttonElement, false);

	}

	// For accessibility reason, use "aria-disabled" instead of "disabled" attribute to disable button
	setButtonDisabledState(buttonElement, true);

	// Cannot use spread operator due to IE not supporting multiple class names in classList.add in one statement
	classNames.forEach((className) => buttonElement.classList.add(className));

	// For traditional form submission, there is no Promise object.
	// In that case, button state should be reset by calling resetButtonStateAfterActivity function
	if (promise) {

		// Remove classNames when the promise is completed
		promise.then(removeClassName, removeClassName);

	}

}

export default setButtonStateDuringActivity;
