import { formatCurrency } from 'utilities/localization';
import { addSaleAttributesToProducts } from 'features/certona/utilities';

function getGlobalRecentlyViewedFooterContent (result, settings) {

	const {
		itemsPerRow, imageSize, withPrice, moreLikeThis, doNotAppend, showRatings, showTitle
	} = settings;
	const baseUrl = window.lp.globals.secureSiteURL;
	const productCount = result.Products.length;
	const maxItemLimit = 10;
	return result.Products.slice(0, maxItemLimit)
		.map(addSaleAttributesToProducts)
		.map((p, i) => {

			if (!doNotAppend) {

				window.resx.prodDetailCertonaSkusUsed.push(`${p.ShortSku}|${result.Scheme}`);

			}

			const isProductRR = result.Scheme === 'product_rr' || result.Scheme === 'shipping_rr';
			const openingDiv = isProductRR && i === 0;
			const newDiv = isProductRR && i % itemsPerRow === 0 && i !== productCount - 1 && i !== 0;
			const closingDiv = isProductRR && i === productCount - 1;
			let ratingStr = null;
			if (showRatings) {

				const ratings = (
					Math.round((p.CertonaProductModel.TurnToAverageRate + 0.25) * 100.0) / 100.0
				).toString();
				if (ratings > 2.5) {

					const decimal = parseInt(ratings.substring(2, 3), 10);
					ratingStr = `${ratings.substring(0, 1)}-${decimal >= 5 ? '5' : '0'}`;

				}

			}

			return `
			${openingDiv ? '<div class="flex-row">' : ''}
			${newDiv ? '</div><div class="flex-row">' : ''}
			<div class="moreYouMayLikeItem sortMYMLProdContainer" data-qa-sku-source="${p.ShortSku}" data-sku="${p.ShortSku}">
				<a href="${baseUrl}${p.Url}" data-certonaSku="${p.ShortSku}">
					<img data-sku="${p.ShortSku}"
					data-sku-input-type="${p.SkuInputType}"
					src="${p.ImageBaseUrl}?qlt=55&amp;wid=${imageSize}&amp;hei=${imageSize}&amp;op_sharpen=1&amp;fmt=jpeg"
					width="${imageSize}" height="${imageSize}" alt="${p.ProductName}" />
				${showTitle ? `<span class="productName">${p.ProductName}</span>` : ''}
				${
	withPrice
		? `<span class="${p.saleClassName}">${formatCurrency(p.FormattedPrice)} ${
			p.clearanceCalloutText
						  }${p.saleCalloutText}</span>`
		: ''
}
				${
	showRatings
		? `
				<div class="productSummaryRating">
					<div class="goldReviewStars goldReviewStars--${ratingStr} ${!ratingStr ? 'invisible' : ''}">
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
						<div class="goldReviewStars__star productSummaryRating__star"></div>
					</div>
				</div>`
		: ''
}
				</a>
				${moreLikeThis ? `<a class="moreLikeThisLink" href="/more-like-this/${p.ShortSku}/">More Like This</a>` : ''}
			</div>
			${closingDiv ? '</div>' : ''}`;

		})
		.join('');

}

export default getGlobalRecentlyViewedFooterContent;
