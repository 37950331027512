/**
 * For limited use (only) about loading widgets to a page where there is performance benefit,
 * compared with loading its JavaScript on page load.
 *
 * Given a marking element, calls the initialization callback based on intersection observation,
 * or if scroll has already passed the top of that element.
 *
 * @param {element} marker - DOM element to observe for running the callback
 * @param {function} callback - function to run when init criteria is met
 * @param {string} rootMargin - override default rootMargin for IntersectionObserver
 */
function initOnIntersection (marker: Element, callback: () => void, rootMargin: string = '0px 0px 200px 0px') {

	// Conditions that require immediate callback

	// If an in-page anchor is part of the page load, callback right away
	if (window.location.hash) {

		callback();

	} else {

		let initialized = false;
		// Listen for programmatic loading of lazy page features
		/*
			Note: the return value of the dynamic import in the
			callback should be added to lazyPageFeatureRequests
			for accurate timing and chaining related to the
			feature load
		*/
		document.addEventListener('loadLazyPageFeatures', () => {

			if (!initialized) {

				callback();
				initialized = true;

			}

		});

		// Initialize immediately if past intersection, or observe for intersection
		const initialize = () => {

			if (initialized) {

				return;

			}

			const intersectionObserver = new IntersectionObserver(
				(entries) => {

					entries.forEach((entry) => {

						const isScrolledPast = entry.boundingClientRect.y < 0;
						if (entry.isIntersecting || isScrolledPast) {

							if (!initialized) {

								callback();

							}
							initialized = true;
							intersectionObserver.disconnect();

						}

					});

				},
				{
					rootMargin
				}
			);
			intersectionObserver.observe(marker);

		};

		// Support calls before and after page load
		if (document.readyState === 'complete' || document.readyState === 'interactive') {

			initialize();

		} else {

			// Wait for page load as browser may restore previous scroll position
			window.addEventListener('load', () => {

				initialize();

			});

		}

	}

}

export default initOnIntersection;
