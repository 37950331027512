function redirectToCart () {

	window.setTimeout(() => {

		window.parent.location.href = '/cart/';

	}, 0);

}

export default redirectToCart;
