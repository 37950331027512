import {
	createResxclsaScript, hideWidget, redirectPlaToPdp, setCertonaTag
} from 'features/certona/utilities';
import { triggerEvent } from 'utilities/dom';
import { fetchRecommendedItems } from 'features/recommendedItems/slice';
import { store } from 'state';
import { getRecommendedItems as getRecommendedItemsRequest } from 'features/recommendedItems/api';

import loadCertonaItems from './loadCertonaItems';
import hideWidgetContainer from './hideWidgetContainer';
import initRelatedItemsGlider from './initRelatedItemsGlider';

const { resx } = window;

// recommendedItemsJsonSSR defined initially on server
const { recommendedItemsJsonSSR } = lp.certona;

function handleRecommendedItemsError () {

	hideWidget();
	if (window.resx.pixelEnabled) {

		createResxclsaScript();

	}

}

function handleRecommendedItemsSuccess (response) {

	const $relatedItemTab = $('[data-tablink="relatedProducts"]');

	if (response) {

		const $widgetContainers = $('.suggestedProductsContainer');
		const $relatedItems = $('#related-items');
		lp.certona.recommendedItemsJsonSSR = response;

		if (window.lp.globals.certonaRequestUrlConsoleEnabled) {

			console.log(response.CertonaRequestUrl);

		}

		let hasRelatedItemsWidget = false;
		const widgets = response.Widgets;
		const widgetCount = widgets ? widgets.length : 0;
		const $allWidgets = $.Deferred();
		const $relatedItemsWidget = $.Deferred();

		if (widgetCount) {

			// wait for all widgets including Related Items widget to be done loading
			$.when($allWidgets, $relatedItemsWidget).done(() => {

				// hide visible empty widgets that may still occur
				if ($widgetContainers.find('#certonaItems div').length > 0) {

					$widgetContainers.find('#certonaItems').parent().removeClass('hidden');

				}

				$widgetContainers.not('.loaded, :hidden').addClass('hidden');

				// need to hide the anchor link as well
				if (!$relatedItems.hasClass('loaded') || $relatedItems.is(':hidden')) {

					$relatedItemTab.addClass('hidden');

				}

				if (resx.RecommendedItemsRequest.Criteria.Category === 'Installation') {

					$widgetContainers.removeClass('hidden');

				}

				if (resx.pixelEnabled) {

					setCertonaTag(response);
					createResxclsaScript();

				}

			});

			widgets.forEach((widget, index, widgets) => {

				const scheme = widget.Scheme && widget.Scheme.replace(/_rr/, '');
				const hasProducts = widget.Products && widget.Products.length;
				const isRelatedScheme = scheme === 'related';
				const isPdpRelatedCC =					window.lp.pdp && window.lp.pdp.pdpRelatedItemsLoaded && lp.pdp.showColorCustomizer;
				const isPdpRelatedCCNotLoaded =					window.lp.pdp && !lp.pdp.pdpRelatedItemsLoaded && lp.pdp.showColorCustomizer;
				const boxTitle = widget.BoxTitle || null;

				if (!hasProducts && scheme === 'SimilarATC') {

					loadCertonaItems(widget, scheme, boxTitle);

				}
				if (hasProducts) {

					if (!isRelatedScheme) {

						loadCertonaItems(widget, scheme, false, boxTitle, widgets);

					}
					if ((isRelatedScheme || isPdpRelatedCC) && !isPdpRelatedCCNotLoaded) {

						loadCertonaItems(widget, 'related', isPdpRelatedCC, boxTitle);

					}

				} else if (scheme === 'similar2') {
					if (!window.location.pathname.indexOf('sfp') > 0) {
						redirectPlaToPdp();
					}

				} else if (!isRelatedScheme) {

					hideWidgetContainer(scheme, true);

				} else if (!window.lp.pdp || (window.lp.pdp && window.lp.pdp.pdpRelatedItemsLoaded)) {

					hideWidgetContainer('related', false);

				}

				if (
					isPdpRelatedCC
					|| !isRelatedScheme
					|| (hasProducts && !lp.pdp)
					|| (hasProducts && lp.pdp && !lp.pdp.showColorCustomizer)
				) {

					$relatedItemsWidget.resolve(); // resolve immediately as nothing to wait because Related items from Color Customizer had loaded first before Certona did

				}

				if (isPdpRelatedCCNotLoaded && isRelatedScheme) {

					$(window.lp.pdp).one('pdpRelItmsLoaded', () => {

						loadCertonaItems(widget, 'related', true, boxTitle);
						$relatedItemsWidget.resolve(); // waiting for Related Items from Color Customizer is done

					});

				}
				// resolve when we are in the last widget
				if (index === widgetCount - 1) {

					$allWidgets.resolve();

				}
				if (isRelatedScheme) {

					hasRelatedItemsWidget = true;

				}
				// remove skeleton UI and show more-like-this
				if (
					!hasProducts
					&& (scheme === 'glo_MLT_sort' || scheme === 'shipping_rr')
					&& window.location.pathname.indexOf('/more-like-this') > -1
				) {

					const $mltContainer = $('.jsMainContainer');
					$mltContainer.find('.sortSkeletonItem').remove();

				}

			});

			// if there's no Related Items widget, we still need to resolve it
			if (!hasRelatedItemsWidget) {

				// Error 404 Page - show suggestedProductsContainer
				if ($('.resultContainer').length > 0) {

					if ($('.resultContainer')[0].children.length > 0 && $('#moreYouMayLikeContainer').length > 0) {

						$('.jsCertonaTitle.certonaHdr').html(widgets[1].BoxTitle);
						$('.suggestedProductsContainer ').removeClass('hidden');

					}

				}

				$relatedItemsWidget.resolve();

			} else {

				$('#pnlRelatedItems .pdTabLoader').hide(); // hide the Related Items tab div that displays over the content

			}

			const pdRelItmsProd = $('.pdRelItmsProd');

			// show related items in kiosk mode
			if (pdRelItmsProd.length && (window.utag_data.is_kiosk === '1' || window.utag_data.is_employee === '1')) {

				$('#pnlRelatedItems .pdTabLoader').hide();
				$('#related-items').removeClass('hidden');
				$('#pnlRelatedItems .rowDivider').remove();
				initRelatedItemsGlider();

			}

			triggerEvent.call(document, 'recommendedItems:loaded');

		} else {

			hideWidget();

		}

	} else {

		hideWidget();
		$relatedItemTab.addClass('hidden');

	}

}

function getRecommendedItems (skus = []) {

	if (
		resx
		&& resx.recommendedItemServiceEnabled
		&& resx.RecommendedItemsRequest
		&& !resx.RecommendedItemsRequest.DisableCertona
	) {

		if (recommendedItemsJsonSSR) {

			// call success method
			handleRecommendedItemsSuccess(recommendedItemsJsonSSR);
			// TODO: When dispatch issue is resolved, dispatch setRecommendedItems as well

		} else {

			// Handle subsequent request for More Like This in Cart
			const requestData = resx.RecommendedItemsRequest;
			let isDynamicMoreLikeThisRequest = false;
			if (window.location.pathname === '/cart/') {

				// Check if call is for More Like This modal where we send a SKU of Out of Stock element from the Cart
				if (skus && skus.length) {

					requestData.Criteria.ShortSkuList = skus;

					requestData.WidgetCriteria = resx.RecommendedItemsRequest.WidgetCriteria.filter(
						(widget) => widget.Scheme === 'SimilarATC_rr'
					);
					isDynamicMoreLikeThisRequest = true;

				}

			}

			if (isDynamicMoreLikeThisRequest) {

				getRecommendedItemsRequest({
					data: window.resx.RecommendedItemsRequest,
					currentWebSiteModeSubLocation: window.lp.globals.currentWebSiteModeSubLocation,
					timer: window.resx.ajaxTimeout
				}).then((response) => handleRecommendedItemsSuccess(response));

			} else {

				store.dispatch(fetchRecommendedItems()).then((action) => {

					if (action.payload) {

						handleRecommendedItemsSuccess(action.payload);

					} else {

						handleRecommendedItemsError();

					}

				});

			}

		}

	} else {

		hideWidget();

	}

}

export default getRecommendedItems;
