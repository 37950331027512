import { rules } from 'features/formValidation';

// Validation Rules
(function ($) {

	/**
	 * Rules that previously had "this.optional(element) ||" pattern
	 * alphanumeric
	 * lettersonly
	 * valueGreaterThanZero
	 * valueNotEqualTo
	 * phoneInt
	 */

	Object.keys(rules).forEach((rule) => {

		const { name, method, errorMessage } = rules[rule];
		// mix in optional with rule methods

		$.validator.addMethod(
			name,
			function (value, element, params) {

				// do not use arrow function to keep the 'this' context

				return this.optional(element) || method.call(this, value, element, params);

			},
			function (params, element) {

				// do not use arrow function to keep the 'this' context

				if (typeof errorMessage === 'function') {

					return errorMessage.call(this, params, element);

				}
				return errorMessage;

			}
		);

	});

}(jQuery));
