// detects if device browsing site is ipad
window.isIpad = function () {

	const { userAgent, platform, maxTouchPoints } = navigator;
	const {
		location: { search }
	} = window;
	// iOS < 13, iOS 13
	return (
		/iPad/.test(userAgent)
		|| (platform === 'MacIntel' && maxTouchPoints > 1)
		|| search.substring(1).indexOf('isIPad=true') > -1
	);

};

if (window.isIpad()) {

	document.addEventListener('DOMContentLoaded', () => {

		if (document.body.parentElement) {

			document.body.parentElement.classList.add('touch');

		}

	});

}

(function () {

	const aliveUrl = `${window.lp.globals.secureSiteURL || ''}/keep-me-alive.ashx`;
	const interval = window.lp.globals.keepMeAliveInterval; // 10 minutes
	let alive = false;

	function isActive () {

		alive = true;

	}

	function removeTrackingIframeIfPresent () {

		const frame = document.getElementById('keep-alive-iframe');
		if (frame) {

			document.body.removeChild(frame);

		}

	}
	function sendKeepAlive () {

		if (alive) {

			removeTrackingIframeIfPresent();
			const frame = document.createElement('iframe');
			frame.setAttribute('src', aliveUrl);
			frame.setAttribute('id', 'keep-alive-iframe');
			frame.setAttribute('style', 'display:none');
			document.body.appendChild(frame);

			alive = false;

		}

	}

	function start () {

		document.addEventListener('keyup', (event) => {

			if (event.target.tagName === 'TEXTAREA') {

				isActive();

			}

		});
		const anchorHandler = function (event) {

			if (event.target.tagName === 'A') {

				isActive();

			}

		};
		document.addEventListener('click', anchorHandler);
		document.addEventListener('mouseover', anchorHandler);

		setInterval(sendKeepAlive, interval);

	}

	start();

}());
