function getCookie (cookieName: string) {

	const result = new RegExp(`(?:^|; )${encodeURIComponent(cookieName)}=([^;]*)`).exec(document.cookie);
	return result ? result[1] : null;

}
function setCookie (
	cookieName: string,
	cookieValue: string,
	expiresDays?: number,
	domain?: string,
	path: string = '/',
	isSecure: boolean = true,
	isSessionCookie?: boolean
) {

	const { cookieDomain } = window.lp.globals;
	let expires = '';
	if (expiresDays) {

		const now = new Date();
		now.setTime(now.getTime() + expiresDays * 86400000);
		expires = `; expires=${now.toUTCString()}`;

	}
	if (isSessionCookie) {

		expires = '';

	}

	document.cookie =		`${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)};`
		+ `${expires}; domain=${domain || cookieDomain}; path=${path}${isSecure ? '; secure' : ''}`;

	return getCookie(cookieName);

}
function deleteCookie (cookieName: string, domain?: string) {

	const cookieDomain = domain || window.lp.globals.cookieDomain;
	setCookie(cookieName, '', -10, cookieDomain);

}

export { deleteCookie, getCookie, setCookie };
