import LPGlider from 'ui/glider';

function initRelatedItemsGlider () {

	const relatedItemsGlider = document.querySelector('#pdRelItmsContainer .glider-container');

	if (!relatedItemsGlider) {

		return null;

	}

	// Initialize Glider
	const gliderOptions = {
		slidesToShow: 5,
		slidesToScroll: 5,
		dots: false,
		rewind: false,
		duration: 0,
		arrows: {
			prev: '#pnlRelatedItems .glider-prev',
			next: '#pnlRelatedItems .glider-next'
		}
	};

	relatedItemsGlider.addEventListener('glider-loaded', function () {

		const glider = LPGlider(this);
		glider.setActiveSlide(0);
		this.classList.remove('glider-loading');

	});

	return new LPGlider(relatedItemsGlider, gliderOptions);

}

export default initRelatedItemsGlider;
