/* eslint-disable no-console */
import { loadJsFile } from 'utilities/browser';

let paypalObj = null;

function fetchPayPalJs (namespace, clientId) {

	const attributes = {};

	if (namespace) {

		attributes['data-namespace'] = namespace;

	}

	return loadJsFile(
		`https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD&components=messages&intent=authorize&commit=false`,
		'paypalLaterJs',
		attributes
	);

}

async function renderPayPalMessage ({
	amount,
	selector,
	placement = 'product',
	namespace,
	style = {},
	onApply,
	onClick,
	onRender,
	fetchPayPalJsIfMissing,
	clientId
}) {

	if (!amount || !selector) {

		console.warn('[PayPal Later]: Amount or widget selector missing.');
		return;

	}

	let paypal =		window[namespace]
		|| (namespace && typeof namespace === 'object' ? namespace : null)
		|| paypalObj
		|| window.paypal;

	if (!paypal || !paypal.Messages) {

		if (fetchPayPalJsIfMissing) {

			const strNamespace = typeof namespace === 'string' && namespace ? namespace : 'paypal';
			await fetchPayPalJs(strNamespace, clientId);

			paypal = window[strNamespace];
			if (!paypal) {

				console.warn('[PayPal Later]: PayPal SDK failed to load.');
				return;

			}
			paypalObj = paypal;

		} else {

			console.warn('[PayPal Later]: PayPal SDK missing.');
			return;

		}

	}

	// According to PayPal SDK, element can be a single DOM element, a CSS selector or an array of DOM elements
	const getPayPalDomElements = (element) => {

		let paypalDomElements;
		if (typeof element === 'string') {

			paypalDomElements = Array.from(document.querySelectorAll(element));

		} else if (Array.isArray(element)) {

			paypalDomElements = element;

		} else {

			paypalDomElements = [ element ];

		}

		return paypalDomElements;

	};

	// This is to override PayPal's odd logic that hides PayPal Later widget if it's < 90% visible within viewport
	const observePayPalMessages = (elements) => {

		elements.forEach((element) => {

			const observer = new MutationObserver((mutations) => {

				mutations.forEach((mutation) => {

					const { type, target, attributeName } = mutation;
					if (type === 'attributes' && attributeName === 'style' && target.tagName === 'IFRAME') {

						// eslint-disable-next-line no-shadow
						const { style } = target;

						// Make PayPal Later widget visible and clickable
						if (style.opacity === '0') {

							style.setProperty('opacity', '1', 'important');

						} else if (style.pointerEvents === 'none') {

							style.setProperty('pointer-events', 'auto', 'important');

						}

					}

				});

			});

			observer.observe(element, {
				subtree: true,
				attributes: true,
				attributeFilter: [ 'style' ]
			});

		});

	};

	const defaultStyle = {
		layout: 'text',
		logo: {
			type: 'inline'
		},
		text: {
			size: 14
		}
	};

	paypal
		.Messages({
			amount,
			placement,
			style: {
				...defaultStyle,
				...style,
				logo: {
					...defaultStyle.logo,
					...(style.logo || {})
				},
				text: {
					...defaultStyle.text,
					...(style.text || {})
				}
			},
			onApply,
			onClick,
			onRender: (e) => {

				if (typeof onRender === 'function') {

					onRender(e);

				}

				const elements = getPayPalDomElements(selector);
				observePayPalMessages(elements);

			}
		})
		.render(selector);

}

export default renderPayPalMessage;
