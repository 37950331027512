import { baseAPIRequest } from 'utilities/http';

function prefetchWebpackAssets (bundleNames) {

	/*

		Safari doesn't support link prefetch out of the box
		as of 10/14/2020 https://caniuse.com/link-rel-prefetch

		Safari will however, use cache from a simple fetch

		Edge supports prefetch but will not use the resource from cache
		if the server responds with 404 on the resource. Edge sends a
		request to the server (ETag cache mechanism)

	*/
	const useLinkPrefetch = !/Safari/.test(navigator.userAgent);

	const data = bundleNames;
	const url = '/api/webpackAssets/';
	const params = {
		method: 'POST'
	};
	const request = baseAPIRequest({
		url,
		data,
		params
	});

	request.then((webpackRecords) => {

		if (webpackRecords.length > 0) {

			const head = document.getElementsByTagName('head')[0];

			webpackRecords.forEach(({ JS, CSS }) => {

				if (JS) {

					if (useLinkPrefetch) {

						const link = document.createElement('link');
						link.setAttribute('rel', 'prefetch');
						link.setAttribute('as', 'script');
						link.setAttribute('href', JS);
						head.append(link);

					} else {

						fetch(JS);

					}

				}

				if (CSS) {

					if (useLinkPrefetch) {

						const link = document.createElement('link');
						link.setAttribute('rel', 'prefetch');
						link.setAttribute('as', 'style');
						link.setAttribute('href', CSS);
						head.append(link);

					} else {

						fetch(CSS);

					}

				}

			});

		}

	});

	return request;

}

export default prefetchWebpackAssets;
