/**
 * Utility function to encode PatternColorCombination object into a string delimited by ~
 */

function encodePatternColorCombination (jsonPattern: LP.scene7.ColorCombination) {

	if (!jsonPattern) {

		return jsonPattern;

	}

	const delimiter = '~';
	const { PatternId } = jsonPattern;
	return typeof jsonPattern === 'object' && PatternId
		? `${PatternId}${delimiter}${jsonPattern.Trim || ''}${delimiter}${[ 1, 2, 3, 4, 5 ]
			.map((num) => jsonPattern[`LayerColor${num}` as keyof LP.scene7.ColorCombination])
			.filter(Boolean)
			.join(delimiter)}`
		: '';

}

export default encodePatternColorCombination;
