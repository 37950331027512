function userNameAndEmailValidation (value, element, params) {

	if (value.length > 0 && value.indexOf('@') !== -1) {

		if (
			!$.validator.methods.email.call(this, value, element, params)
			|| !$.validator.methods.emailRequireDomainSuffix.call(this, value, element, params)
		) {

			return {
				valid: false,
				error: $.validator.messages.email.call(this, params, element)
			};

		}

	} else if (!$.validator.methods.restrictedCharacters.call(this, value, element, params)) {

		// keep existing logic of restrictedCharacters as per requirement in task
		return {
			valid: false,
			error: $.validator.messages.restrictedCharacters.call(this, params, element)
		};

	}

	return { valid: true };

}

const rule = {
	name: 'userNameAndEmailAddress',
	method (value, element, params) {

		// do not use arrow function to keep the 'this' context

		return userNameAndEmailValidation.call(this, value, element, params).valid;

	},
	errorMessage (params, element) {

		// do not use arrow function to keep the 'this' context

		return userNameAndEmailValidation.call(this, element.value, element, params).error;

	}
};

export default rule;
