import utagLinkGeneric from './utagLinkGeneric';

function utagLinkFooter () {

	return ({
		eventCategory = 'Nav-Footer', eventAction, eventLabel = '', nonInteraction = 0
	}) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkFooter;
