import {
	addClassDuringActivity,
	setButtonStateDuringActivity,
	initBlockEnterKeyForDisabledButton
} from 'utilities/dom';
import { utagViewArtificialPageView } from 'features/tealium';
import { formToJSON } from 'utilities/form';
import { createResxclsaScript } from 'features/certona/utilities';

import rateUsFormTemplate from './rateUsFormTemplate';
import rateUsFormThankYouTemplate from './rateUsFormThankYouTemplate';

const trackArtificialPageView = utagViewArtificialPageView();
const initRateUsModal = (params = {}) => {

	let ratingSelected = false;
	let rateUsPage;

	function getRateUsSettings () {

		return fetch(`/rate-us/settings/?location=${encodeURIComponent(location.href)}`).then((response) => response.json());

	}

	function processRating (ratingPostData) {

		const formData = Object.keys(ratingPostData)
			.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(ratingPostData[key])}`)
			.join('&');

		return fetch('/rate-us/rating/', {
			body: formData,
			headers: {
				Accept: 'application/json',
				// this is intentional as backend is collecting Request.Form data
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			credentials: 'same-origin',
			method: 'POST'
		}).then((response) => response.json());

	}

	function submitRating (e) {

		e.preventDefault();

		const submitBtn = e.target;

		const checkValid = $(e.target.form).valid();
		if (!checkValid) {

			const ratingSpan = document.getElementById('OverallRating-error');
			if (ratingSpan) {

				ratingSpan.classList.add('ratingFocusError');
				ratingSpan.tabIndex = 0;
				ratingSpan.focus();

			}

		}

		if (submitBtn.classList.contains('noKeyboardEvents')) {

			return;

		}

		if (!ratingSelected) {

			return;

		}

		const emailInput = document.querySelector('#txtEmail');

		if (emailInput !== null && !$(emailInput).valid()) {

			return;

		}

		const form = document.querySelector('#frmRateUs');
		const ratingPostData = formToJSON(form);
		const request = processRating(ratingPostData).then((response) => {

			if (response) {

				if (window.resx.pixelEnabled && response.RewardNumber) {

					window.resx.customerid = response.RewardNumber;
					if (window.certonaResx) {

						window.certonaResx.run();

					} else {

						createResxclsaScript();

					}

				}

				const thankYouForm = rateUsFormThankYouTemplate();
				const lpModalContent = document.getElementById('lpModalContent');
				lpModalContent.innerHTML = thankYouForm;

			}

		});
		setButtonStateDuringActivity(submitBtn, request);

	}

	function initForm (container) {

		container.querySelector('#btnSubmitRating').addEventListener('click', submitRating);

		container.querySelectorAll('.rating input').forEach((item) => {

			item.addEventListener('click', (e) => {

				e.target.closest('.rating').classList.add('hasChecked');
				ratingSelected = true;

			});

		});

		container.querySelector('.rateUsPrivacyPolicy').addEventListener('click', (e) => {

			e.preventDefault();
			const element = e.target;

			if (element.classList.contains('noKeyboardEvents')) {

				return;

			}

			const togglePrivacyPolicy = () => {

				if (window.getComputedStyle(rateUsPage).display === 'none') {

					rateUsPrivacyPolicy.style.display = 'none';
					rateUsPage.style.display = 'block';

				} else {

					rateUsPrivacyPolicy.style.display = 'block';
					rateUsPage.style.display = 'none';

				}

			};

			let rateUsPrivacyPolicy = document.getElementById('rateUsPrivacyPolicy');

			if (!rateUsPrivacyPolicy) {

				const request = fetch('/rate-us/privacy-policy/')
					.then((response) => response.text())
					.then((response) => {

						const range = document.createRange();
						const fragment = range.createContextualFragment(response);
						const modalContent = document.getElementById('lpModalContent');
						modalContent.appendChild(fragment);

						rateUsPrivacyPolicy = document.getElementById('rateUsPrivacyPolicy');
						togglePrivacyPolicy();

						modalContent.querySelector('.backToRateUsForm').addEventListener('click', (e) => {

							e.preventDefault();
							togglePrivacyPolicy();

						});

					});

				addClassDuringActivity(element, 'noPointerEvents noKeyboardEvents', request);

			} else {

				togglePrivacyPolicy();

			}

		});

		initFormValidation(true, '#frmRateUs');

	}

	function init () {

		const openRateUsModal = (data) => {

			const emailFormContent = rateUsFormTemplate(data);

			rateUsPage = document.getElementById('rateUsPage');
			if (!rateUsPage) {

				rateUsPage = document.createElement('div');
				rateUsPage.id = 'rateUsPage';
				rateUsPage.setAttribute('aria-labelledby', 'footer_rate_us');
				rateUsPage.classList.add('hidden');
				document.body.appendChild(rateUsPage);

			}
			rateUsPage.innerHTML = emailFormContent;

			const { isLoggedIn } = window.lp.globals;
			$(document.body).lpModal({
				onInit: true,
				width: 600,
				height: isLoggedIn ? (data.ShowOptIn ? 502 : 415) : 548,
				lpModalClass: 'lpModal--alt1 rateUsLpModel',
				content: $(rateUsPage),
				openEvt: () => {

					trackArtificialPageView({ pageName: 'Rate Us' });
					initForm(rateUsPage);

				},
				closeEvt: () => {

					trackArtificialPageView({ pageName: '' });

				}
			});

			const btnSubmitRating = document.getElementById('btnSubmitRating');
			initBlockEnterKeyForDisabledButton(btnSubmitRating);

		};

		return getRateUsSettings().then((settings) => {

			const data = { ...settings, ...params };
			openRateUsModal(data);

		});

	}

	return init();

};

export default initRateUsModal;
