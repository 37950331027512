// Full system quantity vs availability

const rule = {
	name: 'systemOptionsQtyAvailable',
	method: (value, element, params) => {

		if (!element.hasAttribute('data-inventory')) {

			return true;

		}
		const available = parseInt(element.getAttribute('data-inventory'));
		return value == '' || parseInt(value) <= available;

	},
	errorMessage: (params, element) => {

		const available = parseInt(element.getAttribute('data-inventory'));
		if (available < 1) {

			return 'Sorry, no more are available.';

		}
		return `Only ${available} available. Please reduce quantity.`;

	}
};

export default rule;
