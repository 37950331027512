// TODO: Import action names from reducers and declare in events object
/*
	Common case: an action payload should include a userEvent property,
	assigned a value of the UI event (e.g. click event), so that the
	event handler can access the DOM at the moment, to aggregate data
	required for tracking that belongs to the DOM. For example, getting
	the textual label of the button clicked (userEvent.target.textContent)
*/
const events = {
	TEST_ACTION: (payload, state) => ({
		event_category: 'test',
		event_action: 'test',
		event_label: 'test'
	})
};

export default events;
