import { SearchAutocomplete } from 'features/search';
import { getProductsWithCache, getSuggestionsWithCache } from 'features/search/api';

function init () {

	const searchApi = {
		getProducts: getProductsWithCache,
		getSuggestions: getSuggestionsWithCache
	};

	const searchElement = document.getElementById('search');

	if (searchElement) {

		const showFlag = window.lp.globals.searchProvider === 'UnBxd';

		new SearchAutocomplete(searchElement, {
			output: searchElement.parentElement,
			api: searchApi,
			show: { products: showFlag, infields: showFlag },
			debounceWait: 300,
			minLetters: 1,
			dontHideOn: [ document.getElementById('searchBtn'), searchElement ],
			doNotFillTextOnHover: true
		});

	}

}

export default init;
