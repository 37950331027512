function renderWishlistCount (count) {

	const element = window.top.document.getElementById('savedPortfolio-yourWishlist--wishlistItemCount');
	const wrapper = window.top.document.getElementById('savedPortfolio-yourWishlist');

	if (count === 0) {

		element.textContent = 0;
		wrapper.classList.remove('maxWishlist');
		wrapper.classList.add('zeroWishlist');

	} else if (count > 99) {

		element.textContent = '99+';
		wrapper.classList.remove('zeroWishlist');
		wrapper.classList.add('maxWishlist');

	} else {

		element.textContent = count;
		wrapper.classList.remove('zeroWishlist', 'maxWishlist');

	}

	window.top.lp.globals.wishlistItemCount = count;

}

export default renderWishlistCount;
