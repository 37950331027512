function getQueryStringParameters (query: string = window.location.search) : Record<string, string> {

	if (!query || !query.length) {

		return {};

	}

	const queryStringWithoutQuestion = query.indexOf('?') === 0 ? query.substring(1) : query;

	return queryStringWithoutQuestion.split('&').reduce((accumulator, keyValuePair) => {

		const hash = keyValuePair && keyValuePair.split('=');

		if (hash) {

			return {
				...accumulator,
				[hash[0].toLowerCase()]: decodeURIComponent(hash[1]).replace(/\+/g, ' ')
			};

		}

		return accumulator;

	}, {});

}

function getQueryStringValue (key: string) {

	return getQueryStringParameters()[key.toLowerCase()];

}

function jsonToQueryString (json: Record<string, string | number | boolean>) {

	return Object.keys(json)
		.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
		.join('&');

}

export { getQueryStringParameters, getQueryStringValue, jsonToQueryString };
