import initRelatedItemsGlider from '../desktop/initRelatedItemsGlider';

function showRelatedItemsTab () {

	const { isHospitality } = window.lp.globals;
	const loader = document.querySelector('#pnlRelatedItems .pdTabLoader');
	if (loader) {

		loader.classList.add('hidden');
		if (isHospitality) {

			$('#pnlRelatedItems .rowDivider').remove();

			// Initialize Glider
			initRelatedItemsGlider();

		}

	}

}

export default showRelatedItemsTab;
