import { hideRelatedItemsTab } from '../utilities';

function hideWidgetContainer (scheme, checkColorCustomizer) {

	const $container = $('.suggestedProductsContainer').filter(`[data-scheme="${scheme}_rr"]`);
	const $sectionAnchor = $('.sectionAnchors').find(`[data-scheme="${scheme}_rr"]`);
	// hide widget with no products from api or already on page
	if (scheme === 'related' && hideRelatedItemsTab(checkColorCustomizer)) {

		$container.addClass('hidden');
		$sectionAnchor.addClass('hidden');

	} else if (scheme !== 'related') {

		$container.addClass('hidden');
		$sectionAnchor.addClass('hidden');

	}

}

export default hideWidgetContainer;
