/*
	Extend functionality to all Cart Views with this HOF
*/
import React from 'react';
import ReactDOM from 'react-dom';

import analyzeModelHelpers from './analyzeModelHelpers';

function withJSXTemplate (View) {

	const view = View.extend({
		constructor (...rest) {

			if (this.JSXTemplate) {

				this.template = false;

			}
			View.call(this, ...rest);

		},
		render (...rest) {

			if (this.JSXTemplate) {

				// unmount before prototype render which could lose references or change this.el
				ReactDOM.unmountComponentAtNode(this.el);

				const Component = this.JSXTemplate;
				const modelAttributes = this.model?.attributes || {};
				const helpers = (this.templateHelpers && this.templateHelpers()) || {};
				ReactDOM.render(<Component modelAttributes={modelAttributes} helpers={helpers} />, this.el);

				if (this.monitorModelHelpers) {

					this.analyzeModelHelpers();

				}

			}

			View.prototype.render.call(this, ...rest);

			return this;

		},

		destroy (...rest) {

			if (this.JSXTemplate) {

				ReactDOM.unmountComponentAtNode(this.el);

			}
			View.prototype.destroy.call(this, ...rest);

		},

		analyzeModelHelpers () {

			console.log(
				`Monitor Model Helpers: ${this.monitorModelHelpers}`,
				analyzeModelHelpers(this.model.attributes, this.templateHelpers())
			);

		}
	});

	return view;

}

export default withJSXTemplate;
