// utility method to delegate events... accepts function and a string selector as the second arg.
function delegateEvent (fn: (e: Event) => any, selector: string) {

	return function (e: Event, ...args: any[]) {

		// get the closest or the current element itself
		const closestEl = (e.target as Element).closest(selector);

		if (!closestEl) {

			return null;

		}

		// the closest element has to be inside the parent (which is 'this')
		const elements = this.querySelectorAll(selector);

		// flag to find the closestEl inside the parent
		let found = false;

		// can't use forEach as we need to break out of the loop
		for (let i = 0; i < elements.length; i += 1) {

			if (elements[i] === closestEl) {

				found = true;
				break;

			}

		}

		if (!found) {

			return null;

		}

		return fn.call(e.target, e, ...args);

	};

}

export default delegateEvent;
