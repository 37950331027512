function utagLinkAddToCart (tag) {

	if (tag && typeof tag === 'object') {

		// remove the page_type, event_value and non-interaction flag.
		const {
			page_type, event_value, gua_non_interaction, ...restTag
		} = tag;

		const data = {
			...restTag,
			event_name: 'add_to_cart'
		};
		return data;

	}

}

export default utagLinkAddToCart;
