import { ecommerceLevelAttributes } from '../configs';

/**
 * Returns Telium properties filtered for selected indexes and attributes
 * @param {Object} utag telium data
 * @param {Array} indexes selected indexes
 * @param {Array} props properties which needs to be filtered
 * @returns
 */
const getSelectedUtagData = (utag, indexes, props = ecommerceLevelAttributes) => props.reduce((acc, prop) => {

	if (utag[prop]) {

		// eslint-disable-next-line no-param-reassign
		acc[prop] = utag[prop].filter((_, i) => indexes.includes(i));

	}
	return acc;

}, {});

export default getSelectedUtagData;
