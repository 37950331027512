/**
 * Redirect to Product
 * @param {string} productUrl
 */
function redirectToProduct (productUrl) {

	const productsPartialUrl = `/products/${productUrl}`;

	// redirect to search product
	window.location.href = productsPartialUrl;

}

export default redirectToProduct;
