const rule = {
	name: 'inlineEmails',
	method: (value, element, params) => inlineEmailValidation(value, params).valid,
	errorMessage: (params, element) => inlineEmailValidation(element.value, params).error
};

function inlineEmailValidation (inputEmails, params) {

	const emails = inputEmails.split(/[,]+/).filter(Boolean);
	const filter = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
	let count = 0;

	for (let i = 0; i < emails.length; i++) {

		const email = emails[i].trim();
		if (email !== '') {

			count++;

			if (!filter.test(email)) {

				return {
					valid: false,
					error: 'Please enter a valid email address.'
				};

			}

			if (email.length > params.maxLengthPerEmail) {

				return {
					valid: false,
					error: `Please enter no more than ${params.maxLengthPerEmail} characters for each email address.`
				};

			}

		}

	}

	if (count > params.maxTotalEmailCount) {

		return {
			valid: false,
			error: `Cannot enter more than ${params.maxTotalEmailCount} emails.`
		};

	}

	return { valid: true };

}

export default rule;
