function triggerEvent (eventName: string, data: Object = null) {

	// if there is no element to trigger the event for, then do nothing
	if (!this) {

		return;

	}

	const event = document.createEvent('CustomEvent');

	event.initCustomEvent(eventName, true, true, data);
	this.dispatchEvent(event);

}

export default triggerEvent;
