// adding restricted characters

const rule = {
	name: 'restrictedCharacters',
	method: (value, element, params) => {

		const match = !/[<>]/.test(value);
		return match;

	},
	errorMessage: (params, element) => 'This field does not accept < or >'
};

export default rule;
