function getPayPalLaterPaymentPlan (creditProductIdentifier) {

	switch (creditProductIdentifier) {

		// These credit product identifiers come from PayPal SDK (add more in the future as needed)
		case 'PAY_LATER_LONG_TERM_US':
			return 'Long Term Payment';

		case 'PAY_LATER_US':
		default:
			return 'Pay in 4';

	}

}

export default getPayPalLaterPaymentPlan;
