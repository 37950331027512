import { setCookie } from 'utilities/browser';

import config from './config';

function setGeoLocationCookie (locationCoords) {

	setCookie(
		config.cookieName,
		JSON.stringify(locationCoords),
		0,
		window.lp.globals.cookieDomain,
		undefined,
		true,
		true
	);

}

export default setGeoLocationCookie;
