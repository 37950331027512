import queueUtagCall from 'features/tealium/queueUtagCall';

function setGenericEvent (payload) {

	queueUtagCall(() => {

		// eslint-disable-next-line no-unused-vars
		const utagTrackingPromise = new Promise((resolve) => {

			// utag.link() returns true, or undefined if tracking is blocked
			const wasSuccessful = window.utag.link(payload, () => resolve());
			if (wasSuccessful) resolve();

			// Tracking is allowed 500ms for successful resolution,
			// then we resolve the promise regardless.
			setTimeout(() => resolve(), 500);

		});

	});

}

export default setGenericEvent;
