/**
 * Utility function to set disabled state for buttons centrally using aria-disabled attribute.
 * For accessibility reason, we are using "aria-disabled" instead of "disabled" attribute.
 * @param {HTMLElement} buttonElement - The button DOM element,
 * @param {Boolean} disabledState - Boolean flag to indicate if button is disabled (true) or enabled (false).
 */
function setButtonDisabledState (buttonElement: HTMLButtonElement, disabledState: boolean) {

	if (!buttonElement) {

		return;

	}

	buttonElement.setAttribute('aria-disabled', String(disabledState));

}

export default setButtonDisabledState;
