import { setGetRequestParams } from 'utilities/http';

import { localStorageName, maxRecentSearches } from './config';

function getRecentSearches () {

	// if user is loggged in, then fetch recent searches from api
	if (window.lp.globals.isLoggedIn) {

		return fetch('/api/v1/search/recentsearchitems/', setGetRequestParams())
			.then((response) => response.json())
			.then((response) => response.map((a) => a.searchText));

	} else if (typeof Storage !== 'undefined') {

		// get searches for anonymous user
		const localStorageValue = localStorage.getItem(localStorageName);
		const recentSearches = localStorageValue === '' || !localStorageValue ? [] : JSON.parse(localStorageValue);
		return Promise.resolve(recentSearches.splice(0, maxRecentSearches)); // limit search results

	}

	return Promise.reject(new Error('Failed to get Recent Searches.'));

}

export default getRecentSearches;
