import config from './config';

const INTERSECTION_PERCENTAGE = 0.75;

function visibilityObserver (entryCallback, thresholdTime = config.VISIBILITY_DURATION) {

	const observer = new IntersectionObserver(
		(entries) => {

			entries.forEach((entry) => {

				if (entry.intersectionRatio >= INTERSECTION_PERCENTAGE) {

					entry.target.dataCaptureVisibilityTimer = setTimeout(() => {

						entryCallback(entry.target);
						observer.unobserve(entry.target);

					}, thresholdTime);

				} else {

					clearTimeout(entry.target.dataCaptureVisibilityTimer);

				}

			});

		},
		{
			root: null,
			rootMargin: '0px',
			threshold: INTERSECTION_PERCENTAGE
		}
	);

	return observer;

}

export default visibilityObserver;
