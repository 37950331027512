import getSearchUrl from './getSearchUrl';
import redirectToSearch from './redirectToSearch';

function doSearch (query) {

	if (query) {

		const searchUrl = getSearchUrl(query);
		redirectToSearch(searchUrl);

	}

}

export default doSearch;
