import utagLinkGeneric from './utagLinkGeneric';

function utagLinkChat (eventCategory = 'Live-Chat') {

	return ({ eventAction, eventLabel = '', nonInteraction = 0 }) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkChat;
