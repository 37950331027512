import { formatCurrency } from 'utilities/localization';

function getGenericHorizontalContent (result) {

	const baseUrl = window.lp.globals.secureSiteURL;

	const products = result.Products.map(
		(p, i) => `
			${i === 0 || (i + 1) % 4 === 1 ? '<li>' : ''}
				<a href="${baseUrl}${p.Url}" data-certonaSku="${p.ShortSku}">
					<img
						src="${p.ImageBaseUrl}?qlt=65&amp;wid=162&amp;hei=162&amp;op_sharpen=1&amp;fmt=jpeg"
						width="162"
						height="162"
						alt="${p.ProductName}"
					/>
					<p>${p.ProductName}</p>
					<p class="scrllPrice">${formatCurrency(p.FormattedPrice)}</p>
				</a>
			${(i + 1) % 4 === 0 || i + 1 === result.Products[i].length ? '</li>' : ''}
		`
	);
	return `
	<div class="certonaContainer group">
		<span class="prev"></span>
		<div class="certonaScrollable">
			<ul class="certonaItems">
				${products}
			</ul>
		</div>
		<span class="next"></span>
	</div>
	<br class="clear" />
	`;

}

export default getGenericHorizontalContent;
