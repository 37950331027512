import { getSearchUrl, redirectToSearch } from 'features/search';
import { utagLinkSearch } from 'features/tealium/';
import { Reporter, visibilityObserver } from 'features/dataCapture';
import { setGenericEvent } from 'features/tealium/ga4/custom';

import renderRecentlyViewedItems from './renderRecentlyViewedItems';
import { unvalidatedQueryLocalStorageName } from './config';
import populatePersistentSearch from './populatePersistentSearch';
import clearRecentSearches from './clearRecentSearches';
import getRecentSearches from './getRecentSearches';
import renderRecentSearches from './renderRecentSearches';

const trackSearchEvents = utagLinkSearch();

function initRecentSearches (searchElementSelector, searchWrapperSelector, searchSubmitSelector, openTheDropdown) {

	const searchElement = document.querySelector(searchElementSelector);
	const searchWrapper = document.querySelector(searchWrapperSelector);
	const searchButton = document.querySelector(searchSubmitSelector);
	const { isMobile } = window.lp.globals;

	if (searchElement && searchWrapper) {

		// variables and functions
		let preventHide = false;
		const recentSearchesClass = 'recentsearches';
		const activeClass = 'searchdropdown-root__suggestion_active';
		const viewAllBtnClass = 'certonaSearchWidgetContainer__viewAllBtn';
		const clearSearchBtn = 'searchdropdown-root__clear';
		const recentlyViewedItem = 'recentlyViewedItem';
		const setUnvalidatedQueryLocalStorage = () => {

			window.localStorage.setItem(unvalidatedQueryLocalStorageName, searchElement.value.toLowerCase());

		};
		const isDropdownHidden = () => {

			const dd = document.querySelector(`.${recentSearchesClass}`);
			return dd ? dd.classList.contains('hidden') : true;

		};
		const movePointer = (direction) => {

			// don't move up or down when dropdown is hidden
			if (isDropdownHidden()) {

				return false;

			}

			const recentSearchItems = document.querySelectorAll(
				`.${recentSearchesClass} .searchdropdown-root__suggestion, #viewAllRecentlyViewedSearchBtn, .${recentlyViewedItem}, .${clearSearchBtn}:not(.hidden)`
			);
			const currentActive = document.querySelector(`.${recentSearchesClass} .${activeClass}`);

			// no elements currently being highlighted
			if (!currentActive) {

				recentSearchItems[0]?.classList.add(activeClass);
				recentSearchItems[0]?.closest('.searchdropdown-root__suggestion-wrapper')?.classList.add('keyActive');
				searchElement.value = recentSearchItems[0].innerText;

			} else {

				let pointerPosition = 0;

				recentSearchItems.forEach((item, index) => {

					if (item.classList.contains(activeClass)) {

						item.classList.remove(activeClass);
						const itemWrapper = item.closest('.searchdropdown-root__suggestion-wrapper');
						itemWrapper?.classList.remove('keyActive');
						itemWrapper?.classList.remove('hoverActive');

						if (direction === 'up') {

							pointerPosition = index > 0 ? --index : 0;

						}

						if (direction === 'down') {

							pointerPosition =								index < recentSearchItems.length - 1 ? ++index : recentSearchItems.length - 1;

						}

					}

				});

				recentSearchItems[pointerPosition].classList.add(activeClass);
				recentSearchItems[pointerPosition]
					.closest('.searchdropdown-root__suggestion-wrapper')
					?.classList.add('keyActive');

				if (
					recentSearchItems[pointerPosition].classList.contains(recentlyViewedItem)
					|| recentSearchItems[pointerPosition].classList.contains(viewAllBtnClass)
					|| recentSearchItems[pointerPosition].classList.contains(clearSearchBtn)
				) {

					searchElement.value = '';

				} else {

					searchElement.value = recentSearchItems[pointerPosition].innerText;

				}

			}

			searchElement.focus();

		};
		const onMouseDownBody = (e) => {

			if (e.target.closest(`.${recentSearchesClass}`)) {

				preventHide = true;

			}

		};
		const onMouseUpBody = () => {

			preventHide = false;

		};
		const bindHideRules = () => {

			document.body.addEventListener('mousedown', (e) => onMouseDownBody(e));
			document.body.addEventListener('mouseup', onMouseUpBody);

		};
		const unBindHideRules = () => {

			document.body.removeEventListener('mousedown', onMouseDownBody);
			document.body.removeEventListener('mouseup', onMouseUpBody);

		};
		const showDropdown = () => {

			const recentSearchesDropdown = document.querySelector(`.${recentSearchesClass}`);
			const currentActive = document.querySelector(`.${recentSearchesClass} .${activeClass}`);

			// if showing dropdown, reset any highlighted elements
			if (currentActive) {

				currentActive.classList.remove(activeClass);
				const currentActiveWrapper = currentActive.closest('.searchdropdown-root__suggestion-wrapper');
				currentActiveWrapper?.classList.remove('keyActive');
				currentActiveWrapper?.classList.remove('hoverActive');

			}

			bindHideRules();
			if (recentSearchesDropdown) {

				recentSearchesDropdown.classList.remove('hidden');
				const searchDropdownRoot = recentSearchesDropdown.querySelector('.searchdropdown-root');

				if (searchDropdownRoot) {

					searchDropdownRoot.style.removeProperty('display');

				}

			}

		};
		const hideDropdown = () => {

			const recentSearchesDropdown = document.querySelector(`.${recentSearchesClass}`);
			unBindHideRules();
			recentSearchesDropdown && recentSearchesDropdown.classList.add('hidden');

		};
		const searchGAEvent = (value, eventAction = 'Search-Submit') => {

			trackSearchEvents({
				eventAction,
				eventLabel: value
			});

			setGenericEvent({ event_name: 'search_sayt_select', search_term: value });

		};
		const searchClickHandler = (query, eventAction) => {

			setUnvalidatedQueryLocalStorage();
			redirectToSearch(getSearchUrl(query));

		};
		const handleOnFocus = (e) => {

			// show dropdown when there is nothing entered
			if (e.target.value.length === 0) {

				showDropdown();

			}

		};
		const handleOnBlur = () => {

			if (!preventHide) {

				hideDropdown();

			}

		};
		const handleOnInput = (e) => {

			// hide recent search when user has entered text
			if (e.target.value.length > 0) {

				hideDropdown();

			}

		};

		const handleOnKeydown = (e) => {

			switch (e.which) {

				/* ESC */
				case 27:
					e.preventDefault();
					hideDropdown();
					break;
				/* ENTER */
				case 13:
					e.preventDefault();
					const selectedElement = document.querySelector(`.${activeClass}:not([data-doc-type=EasyAsk])`);

					if (selectedElement) {

						selectedElement.click();

					} else {

						const recentSearchesWrapper = document.querySelector('.ie11-wrapper.recentsearches');
						const recentSearchDrowdownVisible =							recentSearchesWrapper && !recentSearchesWrapper.classList.contains('hidden');
						if (recentSearchDrowdownVisible) {

							searchClickHandler(e.target.value, 'Recent-SelectSubmit');

						}

					}

					break;
				/* UP */
				case 38:
					e.preventDefault();
					movePointer('up');
					break;
				/* DOWN */
				case 40:
					e.preventDefault();
					movePointer('down');
					break;
				default:
					break;

			}

		};
		const handleOnButtonClick = (e) => {

			const searchValue = document.querySelector(searchElementSelector);
			const value = (searchValue && searchValue.value) || '';
			searchGAEvent(e.target.value || value);

		};

		const initializeDataCapture = () => {

			if (!window.lp.globals.enableDataCapture) {

				return;

			}

			const { reporter } = window.lp.dataCapture;

			const elementObserver = visibilityObserver((element) => {

				const data = Reporter.getSkuViewData(element);
				reporter.track(data);

			});
			const elements = document.querySelectorAll('#recentlyViewedSearchWidgetContainer img[data-sku]');
			elements.forEach((element) => elementObserver.observe(element));

		};

		// render recently viewed widget and bind data-capture events to it
		const renderRecentlyViewedAndFireDataCapture = () => {

			renderRecentlyViewedItems();
			initializeDataCapture();

		};

		const handleOnClick = (e) => {

			const el = e.target;

			// if user is clicking on recent searches
			if (el.closest(`.${recentSearchesClass}`)) {

				const recentSearchesWrapper = document.querySelector('.ie11-wrapper.recentsearches');
				const recentSearchDrowdownVisible =					recentSearchesWrapper && !recentSearchesWrapper.classList.contains('hidden');
				// go to search page for that query
				if (el.classList.contains('searchdropdown-root__suggestion') && recentSearchDrowdownVisible) {

					searchElement.value = el.innerText;
					searchClickHandler(el.innerText, 'Recent-SelectSubmit');

				}

				// clear all recent searches
				if (el.classList.contains(clearSearchBtn)) {

					clearRecentSearches();
					hideDropdown();
					document.querySelector(`.${recentSearchesClass}`).remove();
					const html = renderRecentSearches([]);
					searchWrapper.insertAdjacentHTML('beforeend', html);
					renderRecentlyViewedAndFireDataCapture();
					setGenericEvent({ event_name: 'search_clearall' });

				}

			}

		};
		const handleOnMouseover = (e) => {

			const el = e.target;

			// add hover effect
			if (el.closest(`.${recentSearchesClass}`) && el.classList.contains('searchdropdown-root__suggestion')) {

				const currentActive = document.querySelector(`.${recentSearchesClass} .${activeClass}`);

				if (currentActive) {

					currentActive.classList.remove(activeClass);
					const currentActiveWrapper = currentActive.closest('.searchdropdown-root__suggestion-wrapper');
					currentActiveWrapper?.classList.remove('keyActive');
					currentActiveWrapper?.classList.remove('hoverActive');

				}

				el.classList.add(activeClass);
				const currentActiveWrapper = el.closest('.searchdropdown-root__suggestion-wrapper');
				currentActiveWrapper?.classList.add('hoverActive');
				searchElement.focus();

			}

		};
		const bindInitialHandlers = () => {

			searchElement.addEventListener('keydown', (e) => {

				if (e.which === 13 && searchElement.value !== '') {

					setUnvalidatedQueryLocalStorage();

				}

			});

			searchButton.addEventListener('click', setUnvalidatedQueryLocalStorage);

			// hides recent searches panel on drag
			document.body.addEventListener('touchstart', (e) => {

				const recentSearches = document.querySelector('.recentsearches');

				if (!e.target.closest(searchWrapperSelector) && recentSearches) {

					recentSearches.classList.add('hidden');

				}

			});

		};

		const bindGAEvents = () => {

			document.querySelectorAll('.topSearchContentItem').forEach((element) => {

				element.addEventListener('click', (e) => {

					trackSearchEvents({
						eventAction: 'Top Searches-SelectSubmit',
						eventLabel: e.target.innerText || ''
					});

					setGenericEvent({ event_name: 'search_topterm_select', search_term: e.target.innerText || '' });

				});

			});

			document.querySelectorAll('.topCategoryItem').forEach((element) => {

				element.addEventListener('click', (e) => {

					trackSearchEvents({
						eventAction: 'Top Category-SelectSubmit',
						eventLabel: e.target.innerText || ''
					});

					setGenericEvent({ event_name: 'search_topcat_select', search_term: e.target.innerText || '' });

				});

			});

			document.querySelectorAll('.recentSearchesItem').forEach((element) => {

				element.addEventListener('click', (e) => {

					trackSearchEvents({
						eventAction: 'Recent-SelectSubmit',
						eventLabel: e.target.innerText || ''
					});

					setGenericEvent({ event_name: 'search_recent_select', search_term: e.target.innerText || '' });

				});

			});

		};

		const init = () => {

			bindInitialHandlers();
			populatePersistentSearch(searchElementSelector);
			getRecentSearches().then((response) => {

				// if (response.length) {
				const renderSearch = () => {

					const html = renderRecentSearches(response);
					searchWrapper.insertAdjacentHTML('beforeend', html);
					if (openTheDropdown && !searchElement.value) {

						showDropdown();

					}

					if (window.lp.globals.canShowCertona) {

						if (isMobile || lp.certona.recommendedItemsJsonSSR) {

							renderRecentlyViewedAndFireDataCapture();

						} else {

							document.addEventListener(
								'recommendedItems:loaded',
								renderRecentlyViewedAndFireDataCapture
							);

						}

					}

					// event listeners for search element
					searchElement.addEventListener('focus', (e) => handleOnFocus(e));
					searchElement.addEventListener('blur', handleOnBlur);
					searchElement.addEventListener('input', (e) => handleOnInput(e));
					searchElement.addEventListener('keydown', (e) => handleOnKeydown(e));
					searchElement.addEventListener('mousedown', (e) => handleOnFocus(e));

					// event listeners for clicking and hovering over search items
					searchWrapper.addEventListener('click', (e) => handleOnClick(e));
					searchWrapper.addEventListener('mouseover', (e) => handleOnMouseover(e));

					// event listeners for clicking on search Button
					searchButton.addEventListener('click', handleOnButtonClick);

				};

				renderSearch();

				// }
				bindGAEvents();
				const searchClear = document.querySelector(`.${clearSearchBtn}`);
				searchClear
					&& searchClear.addEventListener('click', () => {

						setTimeout(() => bindGAEvents(), 1000);

					}); // rebind events after clearing history

			});

			// Dynamic import
			// if field is already in focus during init
			// show dropdown when there is nothing entered
			if (document.activeElement === searchElement && searchElement.value.length === 0) {

				showDropdown();

			}

		};

		init();

	}

}

export default initRecentSearches;
