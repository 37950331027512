function topCategorySearch (categorySuggestions = [], categorySearchTerm, recentSearchesCount) {

	if (recentSearchesCount) return '';

	return `<div class="searchdropdown-root__title" >Top ${categorySearchTerm} Searches</div>
	<div class="searchSuggestionCount sr-only" aria-live="assertive">
	</div>
	${categorySuggestions
		.map(
			(item) => `<div class="searchdropdown-root__suggestion-wrapper">
						<div class="searchdropdown-root__suggestion topCategoryItem">${item}</div>
					</div>`
		)
		.join('')}`;

}

export default topCategorySearch;
