const rule = {
	name: 'regex',
	method: (value, element, params) => {

		const re = new RegExp(element);
		return re.test(value);

	},
	errorMessage: (params, element) => 'Invalid input'
};

export default rule;
