lp.menuDropdown = lp.menuDropdown || {};

lp.menuDropdown.$activeDropdown;

function eventTargetIsMenuButtonOfActiveDropdown (clickedItem) {

	return $(clickedItem).hasClass('hover');

}

function eventTargetIsChildOfActiveDropdown (clickedItem, $activeDropdown) {

	return $activeDropdown?.length === 1 && $(clickedItem).parents(`#${$activeDropdown.attr('id')}`).length == 1;

}

lp.menuDropdown.init = ($menuContainer) => {

	let $activeDropdown;
	$menuContainer.on('touchend keydown', (e) => {

		// Still account for keyboard strokes because even if this script is intended for
		// touch devices only (esp. tablets), a physical keyboard can be used to connect to the device.
		if (e.type === 'keydown' && e.code !== 'Enter') {

			return;

		}

		const { target } = e;
		$activeDropdown = lp.menuDropdown.$activeDropdown;

		if ($activeDropdown && target.closest('.aDropdownClose')) {

			lp.menuDropdown.hideMenu.call($activeDropdown[0]);
			$activeDropdown = null;

		} else if (
			$activeDropdown
			&& eventTargetIsChildOfActiveDropdown(target, $activeDropdown)
			&& !eventTargetIsMenuButtonOfActiveDropdown(target)
		) {

			return true;

		}

		if ($activeDropdown && eventTargetIsMenuButtonOfActiveDropdown(target)) {

			lp.menuDropdown.hideMenu.call(target.parentElement);
			$activeDropdown = null;

		} else if (target.classList.contains('aNavBtn')) {

			// only trigger when clicking the actual a or button element in the menu and not the div space in between

			lp.menuDropdown.$activeDropdown = $(target).parent();

			if ($(target).hasClass('aAttBtn')) {

				window.lp.sort.utils.attDropdownsOver.call(target);

			}
			lp.menuDropdown.showMenu.call(lp.menuDropdown.$activeDropdown);

		}

	});

};

lp.menuDropdown.showMenu = function () {

	$(this).children('.aAttBtn, .aNavBtn').addClass('hover');
	$(this).siblings().children('.aAttBtn, .aNavBtn').removeClass('hover');
	lp.menuDropdown.initDocumentListener();

};

lp.menuDropdown.hideMenu = function () {

	$(this).children('.aAttBtn, .aNavBtn').removeClass('hover');
	lp.menuDropdown.$activeDropdown = null;

};

lp.menuDropdown.initDocumentListener = () => {

	const handleOutsideClick = (e) => {

		if (!eventTargetIsChildOfActiveDropdown(e.target, lp.menuDropdown.$activeDropdown)) {

			$(document.body).off('touchend', handleOutsideClick);
			lp.menuDropdown.hideMenu.call(lp.menuDropdown.$activeDropdown);

		}

	};

	$(document.body).on('touchend', handleOutsideClick);

};
