// replace original email validator with version with trimmmed values

const rule = {
	name: 'email',
	method: (value, element, params) => /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
		value.trim()
	),
	errorMessage: (params, element) => 'Please enter a valid email address.'
};

export default rule;
