import { persistentSearchSessionStorageName, persistentSearchTypes } from './config';

// Used to populate search bar with the last searched string
function setPersistentSearch (query, type, url) {

	let obj;

	if (type === persistentSearchTypes.sort) {

		obj = {
			query,
			resultType: persistentSearchTypes.sort
		};

	} else if (type === persistentSearchTypes.pdp) {

		obj = {
			query,
			resultType: persistentSearchTypes.pdp,
			url
		};

	} else {

		return;

	}

	window.sessionStorage.setItem(persistentSearchSessionStorageName, JSON.stringify(obj));

}

export default setPersistentSearch;
