import queueUtagCall from './queueUtagCall';

function utagLinkGeneric ({
	eventCategory = '',
	eventAction = '',
	eventLabel = '',
	eventValue = '',
	nonInteraction = 0
}) {

	queueUtagCall(() => {

		const linkFunction = window.top.utag.link;
		const thisArg = window.top.utag;

		linkFunction.call(thisArg, {
			event_category: eventCategory,
			event_action: eventAction,
			event_label: eventLabel,
			event_value: eventValue,
			gua_non_interaction: nonInteraction
		});

	});

}

export default utagLinkGeneric;
