function displayStoreDirections () {

	const storeAddress = document.getElementById('hdnStoreAddress');

	let url;

	if (storeAddress) {

		url = `https://maps.google.com/maps?saddr=Current+Location&daddr=${storeAddress.value}`;

	}

	const container = document.getElementById('mapDirections');

	if (container && url) {

		window.open(url, '_blank');

	}

}

export default displayStoreDirections;
