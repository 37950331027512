/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */

function compare (a, b) {

	const kA = Object.keys(a);
	const kB = Object.keys(b);
	const keys = Array.from(new Set([ ...kA, ...kB ]));
	const both = keys.filter((key) => a.hasOwnProperty(key) && b.hasOwnProperty(key));
	return both;

}

function caseComparison (o, expectation) {

	const keys = Object.keys(o);
	return keys.filter((key) => key[0] === expectation(key[0]));

}

function analyzeModelHelpers (modelAttributes, templateHelpers) {

	const modelKeys = Object.keys(modelAttributes);
	const helperKeys = Object.keys(templateHelpers);
	const commonKeys = compare(modelAttributes, templateHelpers);

	const helperValueOverrides = commonKeys.filter((key) => modelAttributes[key] !== templateHelpers[key]);
	const helperValueSame = commonKeys.filter((key) => modelAttributes[key] === templateHelpers[key]);

	const helperKeysNotInModel = helperKeys.filter((key) => modelAttributes.hasOwnProperty(key));
	const helperPropertiesOnly = helperKeysNotInModel.reduce((memo, key) => {

		memo[key] = templateHelpers[key];
		return memo;

	}, {});

	const helperCaseViolations = caseComparison(helperPropertiesOnly, (char) => char.toUpperCase());

	const modelBackendProperties = caseComparison(modelAttributes, (char) => char.toUpperCase());

	const modelClientProperties = caseComparison(modelAttributes, (char) => char.toLowerCase());

	const report = {
		'Model Properties': modelKeys,
		'Helper Properties': helperKeys,
		'Properties in Both Model and Helpers': commonKeys,
		'Helper Value Overrides': helperValueOverrides,
		'Helper Value Same': helperValueSame,
		'Helper Only Properties with Backend Naming': helperCaseViolations,
		'Backend Named Properties': modelBackendProperties,
		'Client Named Properties': modelClientProperties
	};

	return report;

}

export default analyzeModelHelpers;
