import * as focusTrap from 'focus-trap';

function init () {

	// Show drop down on click of toggleDropDown
	$(document).on('click', '.toggleDropDown', function (e) {

		const ui = $(this);
		if (ui.hasClass('disabled')) {

			return;

		}
		if (ui.hasClass('closed')) {

			// close other drop downs
			$('.toggleDropDown').each(function () {

				const dd = $(this);
				if (!dd.is(ui)) {

					dd.trigger('dropDown:close').addClass('closed');

				}

			});
			$('.toggleDropDown').addClass('closed');
			// Show this drop down
			ui.trigger('dropDown:open').removeClass('closed');

		} else if ($(e.target).closest('.dropDown').length < 1) {

			// Close this if not clicking within the dropdown (but within the toggle)
			ui.trigger('dropDown:close').addClass('closed');

		}

	});
	// Close drop downs on click of outside dropdown
	$(document).on('click', (e) => {

		const ui = $(e.target);
		if (ui.closest('.toggleDropDown').length < 1) {

			// close other drop downs
			$('.toggleDropDown').each(function () {

				$(this).trigger('dropDown:close').addClass('closed');

			});

		}

	});

	function initSubmenuHandler () {

		const $submenu = $('.headerDropDowns');
		const $savedButton = $submenu.find('#savedHeaderMenu[aria-expanded]');

		if (window.isIpad()) {

			$submenu.on('click', function (e) {

				// don't mess with classes if we're inside the submenu
				if ($(e.target).closest('.headerDropDowns-menu').length) {

					return;

				}

				const $this = $(this);

				if ($this.hasClass('isActive')) {

					$this.removeClass('hover isActive');
					$savedButton.attr('aria-expanded', 'false');

				} else {

					$('.headerDropDowns').removeClass('hover isActive');
					$this.addClass('hover isActive');
					$savedButton.attr('aria-expanded', 'true');

				}

				$this.removeClass('isActive').trigger('over');

				// handler for closing the dropdown
				$(document.body).on('touchstart.headerDropDowns', (e) => {

					const $el = $(e.target);
					const $submenuWrapper = $el.closest('.headerDropDowns');

					// hide submenu when clicking outside it
					if ($submenuWrapper.length === 0) {

						$('.headerDropDowns').removeClass('hover isActive');
						$(document).off('touchstart.headerDropDowns');
						$this.trigger('out');

					}

				});

			});

			$submenu.addClass('isTouch');

		} else {

			$submenu.on('mouseenter', function () {

				$(this).trigger('over');
				$savedButton.attr('aria-expanded', 'true');

				// if recentsearches or top results overlays or showing, hide them before
				if ($('.recentsearches').length > 0 || $('.searchdropdown-root').length > 0) {

					$('.recentsearches').addClass('hidden');
					$('.searchdropdown-root').hide();

				}

			});

			$submenu.on('mouseleave', function () {

				$(this).trigger('out');
				$savedButton.attr('aria-expanded', 'false');

			});

			$submenu.addClass('notTouch');

			// close submenu when clicking a link in it
			$submenu.not('.doNotCloseOnSubmenuLinkClick').on('click', 'a', () => {

				setTimeout(() => {

					$('.headerDropDowns.hover')
						.removeClass('hover')
						.children('.headerDropDowns-menu')
						.addClass('hidden');

				}, 5);

			});

		}

	}

	/*
		TO DO: current header dropdowns don't lock into place. if we need to lock into place on hover and click out to close,
				we would need to do the following for reusable ui and ux
			body click
				if isInputInFocus and click not in the visible menu, close the menu
			drop down input click
				set isInputInFocus to true
			drop down mouse out
				close menu if not isInputInFocus
			(open other menu, reset the flag)
	*/

	/*
		Event Listeners
		IE11 - flag is to fix a bug. if we are focused on an input in a dropdown, we need to remove focus so IE11 won't allow you to ghost type on DOM
	*/
	let isInputInFocus = false;
	$('.headerDropDowns-menu input').on('focus', () => {

		isInputInFocus = true;

	});

	// hide on mouse out of submenu
	$('.headerDropDowns-menu').on('mouseout', () => {

		if (isInputInFocus) {

			isInputInFocus = false;

		}

	});

	// initilize generic submenu
	initSubmenuHandler();

	// main nav functionality - contains itouch, iphone, ipad specific functionality
	const $mainNavMenus = $('#lpHeader-navWrapper--nav > li');

	if (window.isIpad()) {

		$('#lpHeader-navWrapper--nav a.aNavBtn, #saleMenu a.aNavBtn').removeAttr('href').attr('tabindex', 0);
		lp.menuDropdown.init($('#lpHeader-navWrapper--nav'));

	} else {

		$mainNavMenus.addClass('notTouch');

	}

	$mainNavMenus.on('mouseenter', function () {

		$('.aDropdownBtn').attr('aria-expanded', 'false'); // Set collapsed all dropdowns
		$('.aDropdownBtn', this).attr('aria-expanded', 'true'); // Set expanded its own dropdown

	});

	$mainNavMenus.on('mouseleave', function () {

		if ($('.categoryDropDowns.showOnFocus', this).length === 0) {

			$('.aDropdownBtn', this).attr('aria-expanded', 'false');

		}

	});

	const selectorShowOnFocus = 'showOnFocus';
	const selectoraDropdownBtnFocused = 'aDropdownBtn--focused';
	const mainCategoryLinks = document.querySelectorAll('#lpHeader-navWrapper--nav .aNavBtn');

	mainCategoryLinks.forEach((topCategoryLink) => {

		topCategoryLink.addEventListener('focus', (e) => {

			document.querySelector(`.${selectoraDropdownBtnFocused}`)?.classList.remove(selectoraDropdownBtnFocused);
			document.querySelector(`.${selectorShowOnFocus}`)?.classList.remove(selectorShowOnFocus);

		});

	});

	const saleCategoryLink = document.querySelector('#saleMenu .aNavBtn');
	if (saleCategoryLink) {

		saleCategoryLink.addEventListener('blur', (e) => {

			document.querySelector(`.${selectoraDropdownBtnFocused}`)?.classList.remove(selectoraDropdownBtnFocused);
			document.querySelector(`.${selectorShowOnFocus}`)?.classList.remove(selectorShowOnFocus);

		});

	}

	const mainaDropdownBtns = document.querySelectorAll('#lpHeader-navWrapper--nav .aDropdownBtn');

	mainaDropdownBtns.forEach((eachBtn) => {

		let drawerFocusTrap = null;
		const targetDiv = eachBtn.parentElement.querySelector('.categoryDropDowns');

		const closeBtn = targetDiv?.querySelector('.aDropdownClose');
		if (targetDiv) {

			closeBtn?.addEventListener('click', () => {

				targetDiv.classList.remove(selectorShowOnFocus);

				targetDiv.classList.add('hideForClick');
				setTimeout(() => {

					targetDiv.classList.remove('hideForClick');

				}, 100);

				eachBtn.setAttribute('aria-expanded', false);
				if (eachBtn.classList.contains(selectoraDropdownBtnFocused)) {

					eachBtn.focus();

				}
				drawerFocusTrap?.deactivate();

			});

		}

		eachBtn.addEventListener('click', (e) => {

			if (targetDiv?.classList.contains(selectorShowOnFocus)) {

				targetDiv?.classList.remove(selectorShowOnFocus);
				e.target.setAttribute('aria-expanded', false);
				e.target.focus();
				drawerFocusTrap?.deactivate();

			} else {

				targetDiv?.classList.add(selectorShowOnFocus);
				e.target.setAttribute('aria-expanded', true);

				const focusTrapOptions = {
					clickOutsideDeactivates: true,
					escapeDeactivates: false,
					initialFocus: targetDiv,
					fallbackFocus: targetDiv
				};

				drawerFocusTrap = focusTrap.createFocusTrap(targetDiv, focusTrapOptions);
				drawerFocusTrap.activate();
				closeBtn?.focus();

			}

		});
		eachBtn.addEventListener('focus', (e) => {

			document.querySelector(`.${selectorShowOnFocus}`)?.classList.remove(selectorShowOnFocus);

			document.querySelectorAll(`.${selectoraDropdownBtnFocused}`)?.forEach((btn) => {

				btn.classList.remove(selectoraDropdownBtnFocused);

			});

			e.target.classList.add(selectoraDropdownBtnFocused);

		});
		eachBtn.addEventListener('blur', (e) => {

			if (!targetDiv?.classList.contains(selectorShowOnFocus)) {

				e.target.classList.remove(selectoraDropdownBtnFocused);
				targetDiv?.classList.remove(selectorShowOnFocus);

			}

		});

	});

	document.addEventListener('click', (e) => {

		if (!e.target.closest('#lpHeader-navWrapper') && !e.target.closest('#saleMenu')) {

			document.querySelector(`.${selectorShowOnFocus}`)?.classList.remove(selectorShowOnFocus);
			document.querySelector(`.${selectoraDropdownBtnFocused}`)?.classList.remove(selectoraDropdownBtnFocused);

		}

	});

	function initAccesibilityStatus (unseal) {

		const styleEle = document.createElement('style');
		styleEle.innerHTML = `#un-s-open {	
				background-color: #000;
				border: none;
				border-radius: 3px;
				box-shadow: none;
				color: #fff;
				display: inline-block;
				font-family: Arial, Helvetica, sans-serif;
				font-weight: normal;
				height: auto;
				line-height: 41px;
				left: auto;
				padding: 0px 24px;
				position: absolute;
				text-decoration: underline;
				transition: none;
				width: auto;
				}
				#un-s-open:focus, #un-s-open[aria-expanded=true] {
					top: 0px;
					opacity: 1;
				}
				#un-s-open:focus, #un-s-open[aria-expanded=true] {
					outline: none;
				}
				#un-s-open.un-s-out {
					transition: none;
				}`;
		if (unseal) {

			unseal.shadowRoot.appendChild(styleEle);

		}

	}
	// look for chat availability
	const chatButtonContainer = document.querySelector('.hdrPortal-portalLinks .chatButtonContainer button');

	if (chatButtonContainer) {

		const config = { childList: true, subtree: true }; // Which mutations to observe
		const observer = new MutationObserver((mutations) => {

			const isCartPage = $('.cartContent').length > 0;

			mutations.forEach((mutation) => {

				if (mutation.type === 'childList' && mutation.addedNodes.length > 0 && !isCartPage) {

					const liveChatLink = document.getElementById('liveChatLink');
					liveChatLink.classList.add('canChat');
					observer.disconnect();

				}

			});

		});

		observer.observe(chatButtonContainer, config);

	}

	const showDropdownMenus = function (ele) {

		if (!ele.classList.contains('showMenu')) {

			return;

		}
		const titleSelector = ele.querySelector('.headerDropDowns-title');
		const menuSelector = ele.querySelector('.headerDropDowns-menu');
		const titleSelectorHeaderButton = ele.querySelector('.headerButton');

		if (ele.id === 'saleMenu') {

			titleSelector?.classList.add('saleheaderDropDowns-title-visible');
			menuSelector?.classList.add('saleheaderDropDowns-menu-visible');

		} else {

			titleSelector?.classList.add('headerDropDowns-title-visible');

			if (titleSelector == titleSelectorHeaderButton) {

				titleSelector?.classList.add('isActive');

			}

			menuSelector?.classList.add('headerDropDowns-menu-visible');

		}

	};

	const hideDropdownMenus = function (ele) {

		const titleSelector = ele.querySelector('.headerDropDowns-title');
		const menuSelector = ele.querySelector('.headerDropDowns-menu');

		if (menuSelector) {

			if (menuSelector.querySelector('.cacSessionsList .renaming')) {

				return; // Do not hide in case of renaming session name

			}

			menuSelector.classList.remove('headerDropDowns-menu-visible');
			menuSelector.classList.remove('isOpened');

		}

		if (titleSelector) {

			titleSelector.classList.remove('headerDropDowns-title-visible');
			titleSelector.classList.remove('isActive');

		}

	};

	const headerLinks = document.querySelectorAll('.headerDropDowns:not(.isTouch)');

	if (headerLinks) {

		headerLinks.forEach((headerLink) => {

			headerLink.addEventListener('keydown', function (e) {

				if (e.key === 'Enter' || e.code === 'Space' || e.type === 'click') {

					headerLinks.forEach((ele) => {

						ele.classList.remove('showMenu');

					});
					headerLink.classList.add('showMenu');
					showDropdownMenus(this);
					headerLink.addEventListener(
						'focusin',
						function () {

							showDropdownMenus(this);

						},
						true
					);

				}

			});

			headerLink.addEventListener('focusout', function () {

				hideDropdownMenus(this);

			});

			headerLink.addEventListener('focusin', function () {

				const isOpenDiv = this.classList.contains('showMenu');
				headerLinks.forEach((link) => link.classList.remove('showMenu'));
				if (isOpenDiv) {

					this.classList.add('showMenu');

				}

			});

		});

		const otherElements = document.querySelectorAll(
			'.hdrPortal-portalLink:not(.headerDropDowns), .headerCart, .photoSearchSubmit'
		);
		otherElements.forEach((element) => {

			element.addEventListener('focusin', () => {

				headerLinks.forEach((otherEle) => otherEle.classList.remove('showMenu'));

			});

		});

	}

	const skipLinks = document.querySelector('#bdHomePage, #bdContent, #bdProdSort, #bdProdDetail');
	if (skipLinks) {

		const config = { childList: true, subtree: true };
		const observer = new MutationObserver((mutations) => {

			const unseal = document.querySelector('#un-seal');

			mutations.forEach((mutation) => {

				if (mutation.type === 'childList' && unseal) {

					initAccesibilityStatus(unseal);
					observer.disconnect();

				}

			});

		});

		observer.observe(skipLinks, config);

	}

	const pros = document.querySelector('.headerDropDowns-title.headerPros.headerButton');

	if (pros) {

		pros.addEventListener('click', () => {

			location.href = '/pros/';

		});

	}

}

export default init;
