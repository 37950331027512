import utagLinkGeneric from './utagLinkGeneric';

function utagLinkOrderHistory () {

	return ({
		eventCategory = 'Order History', eventAction, eventLabel = '', nonInteraction = 0
	}) => {

		utagLinkGeneric({
			eventCategory,
			eventAction,
			eventLabel,
			nonInteraction
		});

	};

}

export default utagLinkOrderHistory;
