function getCookie (cookieName) {

	const result = new RegExp(`(?:^|; )${encodeURIComponent(cookieName)}=([^;]*)`).exec(document.cookie);
	return result ? result[1] : null;

}

function setCookie (cookieName, cookieValue, expiresDays, domain) {

	const path = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '/';
	const isSecure = arguments[5] || true;
	const isSessionCookie = arguments[6];
	const { cookieDomain } = window.lp.globals;

	let expires = '';
	if (expiresDays) {

		const now = new Date();
		now.setTime(now.getTime() + expiresDays * 86400000);
		expires = `; expires=${now.toUTCString()}`;

	}
	if (isSessionCookie) {

		expires = '';

	}

	document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)};${expires}; domain=${
		domain || cookieDomain
	}; path=${path}${isSecure ? '; secure' : ''}`;

	return getCookie(cookieName);

}

function deleteCookie (cookieName, domain) {

	const cookieDomain = domain || '.lampsplus.com' || window.lp.globals.cookieDomain;
	setCookie(cookieName, '', -10, cookieDomain);

}

/**
 * Get the hashed email from a cookie value (removes extra params)
 * @param {String} cookieValue cookie value returned by getCookie()
 */
function getEmailFromCookie (cookieValue) {

	// ex cookies:
	//    "em=20824kjsldhfjk987234?url=/account/create/1234?param=2o3k4j/23k3l"
	//    "20824kjsldhfjk987234"
	if (new RegExp('\\?|=').exec(cookieValue)) {

		return cookieValue.split('?')[0].split('=')[1];

	}

	return cookieValue;

}

/**
 * Send tracking data via utag.view()
 * @param {String} email hashed email value to send via tracking
 */

function trackMdemCookie () {

	// Regardless of how/when the cookie was created, it will be available
	// on the next page load.  We only need to check 1 time per page.
	const cookieName = 'mdem';
	const cookie = getCookie(cookieName);
	if (cookie) {

		deleteCookie(cookieName);

	}

}

export default trackMdemCookie;
