function formatCurrency (price: string | number, currencySign?: string, isSuperscript: boolean = true) {

	if (!price && price !== 0) {

		return '';

	}

	const priceSplit = typeof price === 'number' ? price.toString().split('.') : price.split('.');
	// add commas for whole numbers > 999 EX: 1234567890 => 1,234,567,890
	const dollarAmount = priceSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	let centAmount = priceSplit[1];

	if (!centAmount) {

		centAmount = '00';

	} else {

		// always use 2 digits for cents.
		centAmount =			centAmount.substring(0, 2).length === 1 ? `${centAmount.substring(0, 2)}0` : centAmount.substring(0, 2);

	}

	if (!isSuperscript) {

		return `$${dollarAmount}.${centAmount}`;

	}

	return `
		<span class="currencySymbol">$</span>${dollarAmount}<span class="currencyCents">.${centAmount}</span>
			${currencySign ? `<span class="currencySign">${currencySign}</span>` : ''}
	`;

}

export default formatCurrency;
