/**
 * Purpose: For automated testing to understand ready state for elements dependent on JavaScript related initialization
 * Implementation Summary: Adds a CSS class to <html>
 *
 * @param {String} areaName
 */
function declareJavaScriptInitialized (areaName: string) {

	const classToAdd = `js-initialized-${areaName}`;
	const { classList } = document.documentElement;

	if (classList.contains(classToAdd)) {

		console.warn(`JavaScript initialization already declared for area: ${areaName}`);

	}

	classList.add(classToAdd);

}

export default declareJavaScriptInitialized;
